import React from "react";
import { useState,useEffect } from "react";
import { useSelector,useDispatch } from "react-redux";
import { profileAll } from "../apis/api/DashboardApi";

const ChatSidebar=({new_message,message,onSelectMember})=>{
const dispatch=useDispatch();
const [data, setData] = useState([]); // To store API data
const [filteredData, setFilteredData] = useState([]); // To store filtered data
const [searchQuery, setSearchQuery] = useState(""); 
 

useEffect (()=>{
    (async()=>{
        await dispatch(profileAll()).catch((error) => {
            console.error("Failed to fetch profiles.");
        });
    })()
  
},[])
   
const {profile}=useSelector((state)=>state.dashboardSlice);

useEffect(()=>{
    if(profile){
        setData(profile.data);
        setFilteredData(profile.data); 
    }
},[profile])


const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);

    // Filter data based on search query
    const filtered = data.filter(
      (item) =>
        item.member_name?.toLowerCase().includes(query) ||
        item.company_name?.toLowerCase().includes(query)
    );

    setFilteredData(filtered);
  };

const handleSelect = (item) => {
    // Callback to parent with selected item
    onSelectMember(item);
  };
    
return(
<div className="discussions-section border border-grey">
          <div className=" search mt-2">
            <div className="search-bar">
              <i className="fa fa-search ms-2 me-1" aria-hidden="true"></i>
              <input
            type="text"
            placeholder="Search by name or company..."
            value={searchQuery}
            onChange={handleSearch}
          />
            </div>
            <ul className="list-group mt-3" id="searchResults">
          {filteredData.map((item, index) => (
            <li
              key={index}
              className="list-group-item"
              style={{ cursor: "pointer" }}
              onClick={() => handleSelect(item)}
            >
              <div className="desc-contact">
                <p className="name mb-0">{item.member_name}</p>
                <p className="message">{item.company_name}</p>
              </div>
            </li>
          ))}
        </ul>
          </div>

          {Array.from({ length: 15 }).map((_, index) => (
            <div key={index} className="discussion message-active mt-2">
              <div
                className="photo"
                style={{
                  backgroundImage:
                    "url(https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80)",
                }}
              >
                <div className="online"></div>
              </div>
              <div className="desc-contact">
                <p className="name mb-0">User {index + 1}</p>
                <p className="message">Message content here...</p>
              </div>
              <div className="timer">12 sec</div>
            </div>
          ))}
        </div>
    );
}
export default ChatSidebar