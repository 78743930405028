import React, { useState, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { Profileget, bannerUpdate } from "../apis/api/ProfileApi";
import Loading from "../hooks/Loading";
import { toast } from "react-toastify";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

const UploadBanner = (props) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [fileName, setFileName] = useState("");
  const [fileSize, setFileSize] = useState("");
  const [file, setFile] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
  const [cannotUpload, setCannotUpload] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [croppedImageUrl, setCroppedImageUrl] = useState(null);

  const fileInputRef = useRef(null);
  const userId = Cookies.get("userId");
  const cropperRef = useRef(null); 

  
  const handleFileChange = (file) => {
    if (file) {
      console.log("File selected:", file); 
      setFile(file);
      setFileName(file.name);
      setFileSize((file.size / 1024).toFixed(1) + " KB");

      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

 
  const handleCropComplete = () => {
    if (cropperRef.current) {
      const cropper = cropperRef.current.cropper; // Access the underlying cropperjs instance
      
      const croppedCanvas = cropper.getCroppedCanvas();
      
      // Ensure that we convert the canvas to a blob
      croppedCanvas.toBlob((blob) => {
        if (blob) {
          // Create an object URL for the blob
          const croppedImgUrl = URL.createObjectURL(blob);
          setCroppedImageUrl(croppedImgUrl); // Set the cropped image URL for preview
  
          // Create a File from the blob to be uploaded
          const croppedFile = new File([blob], fileName, { type: "image/jpeg" });
          setFile(croppedFile); // Set the cropped file for uploading
  
          console.log("Cropped file created:", croppedFile); // Debugging log
        } else {
          console.error("Failed to convert the cropped image to a blob.");
        }
      }, "image/jpeg");
    }
  };
  

  // Handle the upload button click
  const handleUploadClick = async () => {
    console.log("Upload button clicked!"); 
    if (file && croppedImageUrl) {
      setLoading(true);

      const formData = new FormData();
      const company_id = Cookies.get("company_id");

      formData.append("file", file);
      formData.append("company_id", company_id);

      try {
        const response = await dispatch(bannerUpdate(formData));
        console.log("Response:", response); 

        setLoading(false);

        if (response.payload.status === "success") {
          const data = await dispatch(Profileget({ company_id, member_id: userId }));
          if (data.payload.status === "success") {
            props.onHide();
            handleModalClose();
            toast.success("Profile Banner upload successful!");
          }
        } else {
          props.onHide();
          toast.error("Profile upload failed!");
        }
      } catch (error) {
        setLoading(false);
        console.error("Error uploading banner:", error);
        toast.error("Error uploading the banner.");
      }
    } else {
      setCannotUpload(true);
    }
  };

  const handleModalClose = () => {
    setImagePreviewUrl(null);
    setFile(null);
    setCroppedImageUrl(null);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true); 
  };

  const handleDragLeave = () => {
    setIsDragging(false); 
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false); 

    const droppedFile = e.dataTransfer.files[0]; 
    if (droppedFile) {
      handleFileChange(droppedFile); 
    }
  };

  const handleRemoveFile = () => {
    setFile(null); 
    setFileName(""); 
    setFileSize(""); 
    setImagePreviewUrl(null); 
    setCroppedImageUrl(null);
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => {
        handleModalClose();
        props.onHide();
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Upload Banner</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Loading loading={loading} />
        <form
          className="form-container"
          encType="multipart/form-data"
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          onDragLeave={handleDragLeave}
        >
          <div className={`upload-files-container ${isDragging ? "dragging" : ""}`}>
            {!imagePreviewUrl ? (
              <div className="drag-file-area">
                <h5 className="dynamic-message">
                  {isDragging ? "Drop your file here" : "Drag & drop any file here"}
                </h5>
                <label className="label me-5 w-100">
                  or
                  <span className="browse-files">
                    <input
                      type="file"
                      className="default-file-input"
                      ref={fileInputRef}
                      onChange={(e) => handleFileChange(e.target.files[0])}
                    />
                    <span className="browse-files-text">browse file</span>
                    <span>from device</span>
                  </span>
                </label>
              </div>
            ) : (
              <div
                className="cropper-container"
                style={{ height: 500, width: 500, position: "relative" }}
              >
                <Cropper
                  ref={cropperRef}
                  src={imagePreviewUrl}
                  style={{ width: "100%", height: "auto" }}
                  aspectRatio={NaN}
                  guides={false}
                  crop={handleCropComplete} 
                />
              </div>
            )}

            {fileName && (
              <div className="file-info">
                <span className="material-icons-outlined file-icon">description</span>
                <span className="file-name">{fileName}</span> |{" "}
                <span className="file-size">{fileSize}</span>
                <span
                  className="material-icons remove-file-icon ms-4"
                  onClick={handleRemoveFile}
                  style={{ cursor: "pointer" }}
                >
                  delete
                </span>
              </div>
            )}

            <button type="button" className="upload-button" onClick={handleUploadClick}>
              Upload
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default UploadBanner;
