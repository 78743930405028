import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FILE_URL } from "../apis/api/Config";
import { NewsAll } from "../apis/api/DashboardApi";
import { useNavigate } from "react-router-dom";

const RightSidebar = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch( NewsAll({
      filter: "6",
    }));
  }, [dispatch]);
;
  const navigate = useNavigate();
  let { news } = useSelector((state) => state.dashboardSlice);
  let newsData = news?.data || [];
 

  const handleRedirect = (news) => {
   
    navigate("/news", { state: { newsData: news } });
  };
  

  return (
    <>
        <section>
        <div className="card NewsFeed mt-4 cpt fixed card12 " >
          <div className="card-body ">
          <i class="fa-regular fa-newspaper"></i>
            <h5 className="card-title custom-underline ms-3 ">News Feeds</h5>
          </div>
        </div>

          <div className=" cnt1 py-5 " id="Right"  >
             
                {newsData.map((news, index) => (
                  <div
                    className="card d-flex position-relative flex-column"
                    key={`${news.id}-${index}`}
                  >
                    <div className="imgContainer ">
                      <img className="" src={`${news.image}`.replace('/thumb/', '/')} />
                    </div>

                    <div className="content">
                      <h2 className="news-tittle fw-bold">{news.title}</h2>
                      <p className="news-description">
                        {news.description}
                      </p>
                      <button type="button" className="button1 btn btn-Light "  onClick={()=>handleRedirect(news)}>
                        ReadMore!
                      </button>
                    </div>
                 
                  </div>
                ))}
          </div>
        </section>

    </>
  );
};

export default RightSidebar;
