import { createSlice } from "@reduxjs/toolkit";
import {
  ProfileInsert,
  Profileget,
  Profilelist,
  SecondaryInsert,
  memberlistForNavbar,
  getMemberDetailsByCompanyId,
  updateMemberDetails,
  fetchUsers,
  profileUpdate,
  MemberInsert,
  updateCompanyDetails,
  BranchAdd,
  ServiceUpdate,
  bannerUpdate,
  BranchUpdate,
  BranchDelete,
  otherProfileget,
  updateSocialLinks,
  MemberDelete,
} from "../api/ProfileApi";

const profileSlice = createSlice({
  name: "profileSlice",
  initialState: {
    loading: false,
    insert: null,
    profile: null,

    profileList: [],
    MemberList: [],
    MemberDetails: [],
    Testing: [],
    updateSocialLinks:[]
  },
  reducers: {
    // signup_details :(state, action)=>{
    // state.signup_detail="";
    // },
    // Login_detail :(state, action)=>{
    //   state.Login_detail="";
    //   }
  },

  extraReducers: (builder) => {
    builder.addCase(ProfileInsert.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(ProfileInsert.fulfilled, (state, action) => {
      state.insert = action.payload;
      state.loading = false;
    });
    builder.addCase(ProfileInsert.rejected, (state, action) => {
      state.loading = false;
    });

    // profile get api
    builder.addCase(Profileget.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(Profileget.fulfilled, (state, action) => {
      state.profile = action.payload;
      state.loading = false;
    });
    builder.addCase(Profileget.rejected, (state, action) => {
      state.loading = false;
    });

    //  updateSocialLinks
    builder.addCase(updateSocialLinks.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateSocialLinks.fulfilled, (state, action) => {
      state.updateSocialLinks = action.payload;
      state.loading = false;
    });
    builder.addCase(updateSocialLinks.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(otherProfileget.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(otherProfileget.fulfilled, (state, action) => {
      state.otherProfile = action.payload;
      state.loading = false;
    });
    builder.addCase(otherProfileget.rejected, (state, action) => {
      state.loading = false;
    });

    // profile list api
    builder.addCase(Profilelist.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(Profilelist.fulfilled, (state, action) => {
      state.profileList = action.payload.data;
      state.loading = false;
    });
    builder.addCase(Profilelist.rejected, (state, action) => {
      state.loading = false;
    });

    // Secondary Insert api
    builder.addCase(SecondaryInsert.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(SecondaryInsert.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(SecondaryInsert.rejected, (state, action) => {
      state.loading = false;
    });

    // updateMemberDetails api
    builder.addCase(updateMemberDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateMemberDetails.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(updateMemberDetails.rejected, (state, action) => {
      state.loading = false;
    });

    // getMemberDetailsByCompanyId list api
    builder.addCase(getMemberDetailsByCompanyId.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getMemberDetailsByCompanyId.fulfilled, (state, action) => {
      state.MemberDetails = action.payload.data;

      state.loading = false;
    });
    builder.addCase(getMemberDetailsByCompanyId.rejected, (state, action) => {
      state.loading = false;
    });

    // Navbar list api
    builder.addCase(memberlistForNavbar.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(memberlistForNavbar.fulfilled, (state, action) => {
      state.MemberList = action.payload.data;

      state.loading = false;
    });
    builder.addCase(memberlistForNavbar.rejected, (state, action) => {
      state.loading = false;
    });

    // node js api uses slice
    builder.addCase(fetchUsers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchUsers.fulfilled, (state, action) => {
      state.Testing = action.payload.data;

      state.loading = false;
    });
    builder.addCase(fetchUsers.rejected, (state, action) => {
      state.loading = false;
    });

    // node api for member profile update--
    builder.addCase(profileUpdate.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(profileUpdate.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(profileUpdate.rejected, (state, action) => {
      state.loading = false;
    });

    // node api for member profile Insert--
    builder.addCase(MemberInsert.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(MemberInsert.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(MemberInsert.rejected, (state, action) => {
      state.loading = false;
    });

    // node api for company profile update--
    builder.addCase(updateCompanyDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateCompanyDetails.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(updateCompanyDetails.rejected, (state, action) => {
      state.loading = false;
    });

    // node api for company branch update--
    builder.addCase(BranchUpdate.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(BranchUpdate.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(BranchUpdate.rejected, (state, action) => {
      state.loading = false;
    });

    // node api for company service update--
    builder.addCase(ServiceUpdate.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(ServiceUpdate.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(ServiceUpdate.rejected, (state, action) => {
      state.loading = false;
    });

    // node api for company branch add--
    builder.addCase(BranchAdd.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(BranchAdd.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(BranchAdd.rejected, (state, action) => {
      state.loading = false;
    });

    // node api for bannerUpdate--
    builder.addCase(bannerUpdate.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(bannerUpdate.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(bannerUpdate.rejected, (state, action) => {
      state.loading = false;
    });

    // node api for BranchDelete--
    builder.addCase(BranchDelete.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(BranchDelete.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(BranchDelete.rejected, (state, action) => {
      state.loading = false;
    });

    // node apui for Memberdelete
    builder.addCase(MemberDelete.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(MemberDelete.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(MemberDelete.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export default profileSlice.reducer;
