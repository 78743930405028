import React from 'react';

const ConfirmDialog = ({ show, message, onConfirm, onCancel }) => {
  if (!show) return null; 

  return (
    <div className="dialog-overlay">
<div className="dialog-content d-flex flex-column align-items-center">
  <p className="mb-3 ">{message}</p>
  <div className="d-flex justify-content-between w-100">
    <button className="btn btn-primary me-2" onClick={onConfirm}>Confirm</button>
    <button className="btn btn-secondary" onClick={onCancel}>Cancel</button>
  </div>
</div>

    </div>
  );
};

export default ConfirmDialog;
