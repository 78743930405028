// showConfirmation.js
import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import ConfirmDialog from './ConfirmDialog';

export default function showConfirmation(message) {
  return new Promise((resolve) => {
    const div = document.createElement('div');
    document.body.appendChild(div);

    const handleConfirm = () => {
      cleanup();
      resolve(true); // Resolve the promise with `true` when confirmed
    };

    const handleCancel = () => {
      cleanup();
      resolve(false); // Resolve the promise with `false` when canceled
    };

    const cleanup = () => {
      ReactDOM.unmountComponentAtNode(div); // Clean up the DOM element
      document.body.removeChild(div); // Remove the dialog element from DOM
    };

    const DialogComponent = () => (
      <ConfirmDialog
        show={true}
        message={message}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
      />
    );

    ReactDOM.render(<DialogComponent />, div);
  });
}
