import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import AddPhoto from "./Addphotos";
import Cookies from "js-cookie";
import { MediaGet, PhotoDelete } from "../../apis/api/GalleryApi";
import { toast } from "react-toastify";
import Loading from "../../hooks/Loading";

function StaticPhotoGallery() {
  const [selectedImage, setSelectedImage] = useState(null);
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const [addphoto, setaddPhoto] = useState(false);
  const handleClosePhoto = () => setaddPhoto(false);
  const user_id = Cookies.get("userId");
  const company_id = Cookies.get("company_id");

  let { media, loading } = useSelector((state) => state.gallerySlice);
  const galleryData = media?.data?.images || [];

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedImage(null);
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleDelete = async (id) => {
    const response = await dispatch(PhotoDelete(id));
    if (response.payload.status == "success") {
      const response = await dispatch(MediaGet({ company_id: company_id, user_id: user_id }));
      if (response.payload.status == "success") {
        toast.success("Photo deleted successfully!");
      }
    } else {
      toast.error(response.payload.msz || "Error deleting photo");
    }
  };

  return (
    <>
      <AddPhoto AddPhoto={addphoto} onClose={handleClosePhoto} />
      <Loading loading={loading} />
      <div className="container-fluid image-gallery">
        <div className="d-flex justify-content-between align-items-center mt-3">
          <button
            className="btn btn-secondary bg-theme btn-sm mb-3 me-4"
            onClick={() => setaddPhoto(true)}
          >
            <i className="fa fa-plus me-2 ms-1"></i>Add Photo
          </button>

          <button
            className="btn btn-secondary bg-theme btn-sm mb-3 ms-auto"
            onClick={handleBack}
          >
            <i className="fa fa-arrow-left me-2 ms-1"></i>Back
          </button>
        </div>

        <div className="container-fluid image-gallery">
          <div className="row gallery-grid mt-2">
            {galleryData.length > 0 ? (
              galleryData.map((item) => (
                <div
                  key={item.id}
                  className="col-md-6 col-xl-3 col-xxl-3 col-sm-12 gallery-item"
                  onClick={() => handleImageClick(item.file_url)}
                >
                  {/* Image Card */}
                  <div className="card mb-3 p-2" style={{ border: "2px solid #ddd", borderRadius: "8px" }}>
                    <div className="position-relative">
                      {/* Image */}
                      <img
                        src={item.file_url}
                        alt={`Gallery Image ${item.id}`}
                        className="card-img-top"
                        onError={(e) => {
                          e.target.src = "/no-attrony.png";
                        }}
                        style={{
                          height: "200px",
                          objectFit: "cover",
                          borderTopLeftRadius: "8px",
                          borderTopRightRadius: "8px",
                        }}
                      />
                      {/* Delete Button */}
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDelete(item.id);
                        }}
                        className="btn btn-danger btn-sm position-absolute top-0 end-0 m-2"
                      >
                        <i className="fas fa-trash"></i>
                      </button>
                    </div>
                    <div className="card-body text-center">
                      <h6 className="card-title">{item.tittle}</h6>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p>No images available.</p>
            )}
          </div>
        </div>

        {/* Modal for image preview */}
        <Modal show={showModal} onHide={closeModal} centered>
          <Modal.Header closeButton>
            <Modal.Title>Image Preview</Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-center">
            {selectedImage && (
              <img
                src={selectedImage.replace("/thumb/", "/")}
                alt="Selected"
                className="img-fluid modal-image w-100"
                onError={(e) => {
                  e.target.src = "/no-attrony.png";
                }}
              />
            )}
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}

export default StaticPhotoGallery;
