import React, { useEffect, useState } from 'react';
import io from 'socket.io-client';

const socket = io('https://clubnet.app', { path: '/dev_node/connection' });

const Chatguru = () => {
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [receiverUserId, setReceiverUserId] = useState(2); // Receiver's user ID
  const senderUserId = 1; 

  // Register the user with their socket ID on connection
  useEffect(() => {
    socket.emit('registerUser', senderUserId);
    
    // Listen for incoming private messages
    socket.on('receivePrivateMessage', (data) => {
      setMessages((prevMessages) => [
        ...prevMessages,
        { senderUserId: data.senderUserId, message: data.message },
      ]);
    });

    return () => {
      socket.off('receivePrivateMessage');
    };
  }, []);

  // Function to send a private message
  const sendPrivateMessage = () => {
    if (message.trim()) {
      const data = {
        senderUserId,
        receiverUserId,
        message,
      };
      
      // Emit the private message to the server
      socket.emit('sendPrivateMessage', data);

      // Append the sent message locally for immediate feedback
      setMessages((prevMessages) => [
        ...prevMessages,
        { senderUserId, message },
      ]);

      // Clear the message input field
      setMessage('');
    }
  };

  return (
    <div style={{ maxWidth: '600px', margin: '0 auto', padding: '20px' }}>
      <h2>Private Chat with User {receiverUserId}</h2>

      <div
        style={{
          border: '1px solid #ccc',
          padding: '10px',
          height: '300px',
          overflowY: 'scroll',
          marginBottom: '20px',
        }}
      >
        {messages.map((msg, index) => (
          <div
            key={index}
            style={{
              display: 'flex',
              flexDirection: msg.senderUserId === senderUserId ? 'row-reverse' : 'row',
              marginBottom: '10px',
              justifyContent: msg.senderUserId === senderUserId ? 'flex-end' : 'flex-start',
            }}
          >
            <div
              style={{
                maxWidth: '70%',
                backgroundColor: msg.senderUserId === senderUserId ? '#007bff' : '#f1f1f1',
                color: msg.senderUserId === senderUserId ? '#fff' : '#000',
                padding: '10px',
                borderRadius: '10px',
              }}
            >
              <strong>{msg.senderUserId === senderUserId ? 'You' : `User ${msg.senderUserId}`}:</strong> {msg.message}
            </div>
          </div>
        ))}
      </div>

      <div style={{ display: 'flex', gap: '10px' }}>
        <input
          type="text"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Type a message..."
          style={{
            flex: 1,
            padding: '10px',
            borderRadius: '20px',
            border: '1px solid #ccc',
            fontSize: '1em',
          }}
        />
        <button
          onClick={sendPrivateMessage}
          style={{
            padding: '10px 20px',
            backgroundColor: '#007bff',
            color: '#fff',
            border: 'none',
            borderRadius: '20px',
            cursor: 'pointer',
            fontSize: '1em',
          }}
        >
          Send
        </button>
      </div>
    </div>
  );
};

export default Chatguru;
