import Modal from "react-bootstrap/Modal";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import { toast } from "react-toastify";
import Loading from "../hooks/Loading";
import { FILE_URL } from "../apis/api/Config";
import { Profilelist } from "../apis/api/ProfileApi";
import { postUpdate, CompanyFeedsGet } from "../apis/api/FeedsApi";
import Cookies from "js-cookie";

const EditFeeds = ({ showModal, closeModal, selectedPost, updateFeed }) => {
  const dispatch = useDispatch();
  const [list, setList] = useState([]);
 
  const loading = useSelector((state) => state.profileSlice.loading);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileType, setFileType] = useState("");
  const [description, setDescription] = useState("");
  const [defaultTags, setDefaultTags] = useState([]);
  const memberId = Cookies.get("userId");
  const CompanyId = Cookies.get("company_id");
  const fetchProfileList = async () => {
    try {
      const profileListResponse = await dispatch(Profilelist({ filter: 1 }));
      if (profileListResponse.payload.status === "success") {
        const data = profileListResponse.payload.data;
        setList(data);
      }
    } catch (error) {
      console.error("Failed to fetch profile list:", error);
    }
  };

  useEffect(() => {
    fetchProfileList();
  }, [dispatch]);

  useEffect(() => {
    setDefaultTags([]);

    if (selectedPost) {
      if (selectedPost.company_post_description) {
        setDescription(selectedPost.company_post_description);
      }

      if (selectedPost.company_post_tags) {
        console.log("company_post_tags", selectedPost.company_post_tags);

        const tagIds = selectedPost.company_post_tags
          .split(",")
          .map((tag) => tag.trim());
        console.log("tagIds after split and trim", tagIds);

        if (tagIds.length === 0) {
          console.error("No valid tags found in company_post_tags.");
          return;
        }

        const preSelectedTags = list.filter((tag) =>
          tagIds.some((id) => {
            const normalizedId = id.trim().replace(/\s*\|\|\s*/g, " || ");
            const normalizedLabel = tag.label
              .trim()
              .replace(/\s*\|\|\s*/g, " || ");

            return normalizedId === normalizedLabel;
          })
        );

        console.log("preSelectedTags", preSelectedTags);
        setDefaultTags(preSelectedTags);
      }
    }
  }, [selectedPost, list]);

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      setFileType(file.type.split("/")[0]);
    }
  };
  useEffect(() => {
    if (selectedPost) {
      reset({ description: selectedPost.company_post_description || "" });
    } else {
      reset({ description: "" });
    }
  }, [selectedPost, reset]);

  const onSubmitHandler = async (formData) => {
    const data = new FormData();

    if (formData.description) {
      data.append("description", formData.description);
    }

    data.append("Post_id", selectedPost.company_post_id);
    data.append("member_id", selectedPost.company_post_member_id);

    // Append selected tags to FormData
    if (formData.tags && Array.isArray(formData.tags)) {
      formData.tags.forEach((item, index) => {
        if (item.value) {
          data.append(`tags[${index}]`, item.value);
        }
      });
    }

    try {
      const response = await dispatch(postUpdate(data));
      if (response.payload.status === "success") {
        updateFeed(true);
        dispatch(
          CompanyFeedsGet({
            member: memberId,
            company: CompanyId,
            lastid: 0,
            pagination: 0,
          })
        );
        toast.success("Post updated successfully!");
        handleClose(); // Close modal on success
      } else {
        toast.error("Failed to update the post.");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("An error occurred. Please try again.");
    }
  };

  const handleClose = () => {
    reset();
    setSelectedFile(null);
    setFileType("");
    closeModal();
  };

  return (
    <Modal
      show={showModal}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="text-center">
          <span className="text-bold text-center">
            {selectedPost ? (
              <span className="text-bold text-center">
                {selectedPost.member_name} ||{" "}
                {selectedPost.company_company_name}
              </span>
            ) : (
              <span className="text-bold text-center">Loading...</span>
            )}
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Loading loading={loading} />
        <div className="row mx-1">
          <form
            method="post"
            onSubmit={handleSubmit(onSubmitHandler)}
            encType="multipart/form-data"
          >
            <div className="form-container">
              <div className="d-block border rounded my-2 px-2 py-0">
                <div className="position-relative text-left">
                  <div className="row mt-2">
                    {/* Media Upload */}
                    <div className="col-md-12">
                      <div className="form-group">
                        <div className="mt-2 ">
                          {selectedPost ? (
                            <>
                              {selectedPost.company_post_file_extension ===
                              "video" ? (
                                <video
                                  className="videoContainer w-100"
                                  controls
                                  src={selectedPost.company_post_file_url.replace(
                                    "/thumb/",
                                    "/"
                                  )}
                                />
                              ) : selectedPost.company_post_file_extension ===
                                "audio" ? (
                                <audio
                                  className="audioContainer w-100"
                                  controls
                                  src={selectedPost.company_post_file_url.replace(
                                    "/thumb/",
                                    "/"
                                  )}
                                />
                              ) : selectedPost.company_post_file_extension ==
                                ".mp3" ? (
                                <div>
                                  <audio
                                    className="audioContainer w-100"
                                    controls
                                    src={selectedPost.company_post_file_url.replace(
                                      "/thumb/",
                                      "/"
                                    )}
                                  >
                                    Your browser does not support the audio
                                    element.
                                  </audio>
                                  <p>mp3</p>
                                </div>
                              ) : selectedPost.company_post_file_extension ===
                                "image" ? (
                                <img
                                  className="imgContainer w-100"
                                  src={selectedPost.company_post_file_url.replace(
                                    "/thumb/",
                                    "/"
                                  )}
                                  alt="Post image"
                                />
                              ) : (
                                <p></p>
                              )}
                            </>
                          ) : (
                            <p>No media available for this post.</p>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="form-group">
                        <label className="form-label">Post Description </label>
                        <textarea
                          className="form-control border border-grey border-1 rounded-1 custom-textarea-height"
                          placeholder="Enter post description"
                          style={{
                            height: "150px",
                            minHeight: "150px",
                            maxHeight: "150px",
                          }}
                          {...register("description")}
                        />

                        {errors.description && (
                          <span className="text-danger">
                            {errors.description.message}
                          </span>
                        )}
                      </div>
                    </div>

                    {/* Tags Dropdown */}
                    <div className="col-md-12">
                      <div className="form-group">
                        <label className="form-label">Tags</label>
                        <Controller
                          name="tags"
                          control={control}
                          render={({ field }) => (
                            <Select
                              {...field}
                              options={list}
                              isMulti
                              placeholder="Select tags"
                              value={defaultTags} 
                              onChange={(selectedOptions) => {
                                field.onChange(selectedOptions); 
                                setDefaultTags(selectedOptions); 
                              }}
                            />
                          )}
                        />

                        {errors.tags && (
                          <span className="text-danger">
                            {errors.tags.message}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  type="submit"
                  className="btn btn-secondary bg-theme-color my-2 px-4"
                  disabled={loading}
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default EditFeeds;
