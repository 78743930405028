import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import Loading from "../hooks/Loading"; 
import Cookies from "js-cookie";
import { updateSocialLinks } from "../apis/api/ProfileApi";

const SocialLinksForm = ({ showModal, handleClose, data }) => {
 
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const company_id = Cookies.get("company_id");

  const [loading, setLoading] = useState(false);

 
  useEffect(() => {
    if (data ) {

      const companyData = data.data.company[0]; 
      setValue("facebook", companyData.facebook_links || "");
      setValue("linkedin", companyData.linkedin_links || "");
      setValue("instagram", companyData.instagram_links || "");
      setValue("twitter", companyData.twitter_links || "");
    }
  }, [data, setValue]);
  

  const onSubmitHandler = async (formData) => {
    const payload = {
      facebook: formData.facebook,
      linkedin: formData.linkedin,
      instagram: formData.instagram,
      twitter: formData.twitter,
      company_id: company_id,
    };

    try {
      const response = await dispatch(updateSocialLinks(payload));
      setLoading(true);

      setTimeout(() => {
        setLoading(false);
        toast.success("Social Media Links Updated Successfully!");
        handleClose();
        reset();
      }, 1000);
    } catch (error) {
      console.error("Error updating social links:", error);
      toast.error("Error updating social media links.");
    }
  };

  const CloseModel = () => {
    reset();
    handleClose();
  };

  return (
    <Modal
      show={showModal}
      onHide={CloseModel}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <span className="text-bold">Social Media Links</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Loading loading={loading} />
        <form onSubmit={handleSubmit(onSubmitHandler)}>
          <div className="form-container">
            <div className="d-block border rounded my-2 px-2 py-0">
              <div className="position-relative text-left">
                <div className="row mt-2">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label className="form-label">
                        Facebook URL <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="facebook"
                        control={control}
                        rules={{ required: "Facebook URL is required" }}
                        render={({ field }) => (
                          <input
                            {...field}
                            className={`form-control ${errors.facebook ? "is-invalid" : ""}`}
                            placeholder="Enter Facebook URL"
                          />
                        )}
                      />
                      {errors.facebook && (
                        <span className="text-danger">{errors.facebook.message}</span>
                      )}
                    </div>
                  </div>

                  <div className="col-md-12 mt-3">
                    <div className="form-group">
                      <label className="form-label">
                        LinkedIn URL <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="linkedin"
                        control={control}
                        rules={{ required: "LinkedIn URL is required" }}
                        render={({ field }) => (
                          <input
                            {...field}
                            className={`form-control ${errors.linkedin ? "is-invalid" : ""}`}
                            placeholder="Enter LinkedIn URL"
                          />
                        )}
                      />
                      {errors.linkedin && (
                        <span className="text-danger">{errors.linkedin.message}</span>
                      )}
                    </div>
                  </div>

                  <div className="col-md-12 mt-3">
                    <div className="form-group">
                      <label className="form-label">
                        Instagram URL <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="instagram"
                        control={control}
                        rules={{ required: "Instagram URL is required" }}
                        render={({ field }) => (
                          <input
                            {...field}
                            className={`form-control ${errors.instagram ? "is-invalid" : ""}`}
                            placeholder="Enter Instagram URL"
                          />
                        )}
                      />
                      {errors.instagram && (
                        <span className="text-danger">{errors.instagram.message}</span>
                      )}
                    </div>
                  </div>

                  <div className="col-md-12 mt-3">
                    <div className="form-group">
                      <label className="form-label">
                        Twitter URL <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="twitter"
                        control={control}
                        rules={{ required: "Twitter URL is required" }}
                        render={({ field }) => (
                          <input
                            {...field}
                            className={`form-control ${errors.twitter ? "is-invalid" : ""}`}
                            placeholder="Enter Twitter URL"
                          />
                        )}
                      />
                      {errors.twitter && (
                        <span className="text-danger">{errors.twitter.message}</span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 mt-4 text-center">
            <button type="submit" className="btn bg-theme-color">
              Submit
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default SocialLinksForm;
