
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { galleryGet } from "../apis/api/GalleryApi";
import { useNavigate, Link } from "react-router-dom";
import Cookies from "js-cookie";
import AlbumCard from "./albumcard";
import AddAlbum from "./AddAlbum";

function AlbumsList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let galleryData = useSelector((state) => state.gallerySlice.gallery);
  galleryData = galleryData?.data || [];
 

  useEffect(() => {
   
    const user_id = Cookies.get("userId");
    const company_id = Cookies.get("company_id");
    dispatch(galleryGet({ user_id, company_id }));
  
  }, []);
 

  const handleAlbumClick = (albumId) => {
    navigate(`/album/${albumId}`);
  };
  const NavigateBack=()=>{
    navigate(-1);
  }

  const [showModal, setShowModal] = useState(false);
 
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
 
  const access_data=JSON.parse(Cookies.get("userJSON"))
 
  return (
    <>
      <AddAlbum ShowModal={showModal} onClose={handleCloseModal} />
     
      <div className="col d-flex justify-content-between mt-3">
        <button
          className="btn btn-secondary bg-theme btn-sm mb-3"
          onClick={handleShowModal}
        >
          <i className="fas fa-plus me-2"></i>Add Album
        </button>
        <button
          className="btn btn-secondary bg-theme btn-sm mb-3"
          onClick={NavigateBack}
        >
          <i className="fas fa-arrow-left me-2"></i>Back
        </button>

      </div>


      <div className="row">
        <div className="container mt-3">
          <div className="row">
            {galleryData && galleryData.length > 0 ? (
              galleryData.map((album) => (
                <AlbumCard
                  key={album.id}
                  album={album}
                  onClick={() => handleAlbumClick(album.id)}
                />
              ))
            ) : (
              <div className="d-flex flex-row justify-content-center mt-5"><h5>No Gallery available..</h5></div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default AlbumsList;
