import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { useLocation, useNavigate } from "react-router-dom";
import { Column } from "primereact/column";
import { useSelector, useDispatch } from "react-redux";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { FILE_URL } from "../apis/api/Config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEdit,
  faTrash,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";

const ALLNewsTable = () => {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredProfiles, setFilteredProfiles] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [selectedProfiles, setSelectedProfiles] = useState([]);

  const location = useLocation();
  const { newsData } = location.state || {};

  const handleRedirect = (news) => {
    navigate("/news", { state: { newsData: news } });
  };

  useEffect(() => {
    if (newsData) {
      const filteredData = newsData.data.filter((customer) =>
        customer.title.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredProfiles(filteredData);
    }
  }, [searchQuery, newsData]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const onSelectionChange = (e) => {
    setSelectedProfiles(e.value);
  };

  const nameBodyTemplate = (rowData) => (
    <div className="d-flex align-items-center">
      <img
        src={`${rowData?.image || "default-logo.png"}`}
        alt={rowData.title}
        style={{
          width: "5vw",
          height: "5vh",
          marginRight: "10px",
          borderRadius: "5%",
        }}
      />
    </div>
  );
  const handleBack = () => {
    navigate(-1);
  };
  const activityBodyTemplate = (rowData) => (
    <div className="me-2">
      <button
        className="btn btn-primary me-1 p-0"
        onClick={() => handleRedirect(rowData)}
      >
        <FontAwesomeIcon icon={faEye} className="text-white p-1" />
      </button>
    </div>
  );

  const handleRowsChange = (e) => {
    setRowsPerPage(Number(e.target.value));
  };

  const handleRowClick = (rowData) => {
    navigate("/news", { state: { newsData: rowData } });
  };

  return (
    <div className="datatable-crud-demo container-fluid ">
      <div className="d-flex justify-content-between align-items-center mb-2 mt-3">
        <h5 className="mb-2 mt-3 text-color  d-flex align-items-center">
          <i className="fas fa-users me-1"></i>
          News Details
        </h5>
        <div className="ms-auto mb-2">
          <button
            className="btn btn-secondary bg-theme-color btn-sm "
            onClick={handleBack}
          >
            <i className="fas fa-arrow-left me-2"></i>Back
          </button>
        </div>
      </div>
      <div className="d-flex justify-content-end align-items-center mb-3">
        <div className="input-group" style={{ maxWidth: "300px" }}>
          <input
            type="text"
            className="form-control"
            placeholder="Search by..."
            value={searchQuery}
            onChange={handleSearchChange}
          />
          <button className="btn btn-primary bg-theme mt-0">
            <FontAwesomeIcon icon={faSearch} />
          </button>
        </div>
        <select
          className="form-select ms-2"
          value={rowsPerPage}
          onChange={handleRowsChange}
          style={{ width: "100px" }}
        >
          <option value={5}>5</option>
          <option value={10}>10</option>
          <option value={20}>20</option>
          <option value={30}>30</option>
          <option value={50}>50</option>
        </select>
      </div>

      <DataTable
        value={filteredProfiles}
        paginator
        rows={rowsPerPage}
        emptyMessage="No members found."
        rowClassName={() => "custom-row"}
        selection={selectedProfiles}
        onSelectionChange={onSelectionChange}
        selectionMode="checkbox"
        onRowClick={(e) => handleRowClick(e.data)}
      >
        <Column
          body={nameBodyTemplate}
          header="News"
          headerClassName="bg-theme-color"
          headerStyle={{ paddingLeft: "25px", paddingRight: "35px" }}
          bodyStyle={{ paddingLeft: "15px", paddingRight: "35px" }}
          sortable
        />

        <Column
          field="title"
          header="Title"
          headerStyle={{ paddingLeft: "25px", paddingRight: "35px" }}
          bodyStyle={{ paddingLeft: "25px", paddingRight: "35px" }}
          headerClassName="bg-theme-color"
          sortable
        />

        <Column
          field="date"
          header=" Date"
          headerStyle={{ paddingLeft: "15px", paddingRight: "15px" }}
          bodyStyle={{ paddingLeft: "15px", paddingRight: "15px" }}
          headerClassName="bg-theme-color"
          sortable
          body={(rowData) => {
            const timestamp = parseInt(rowData.date) * 1000;
            const date = new Date(timestamp);

            const day = String(date.getDate()).padStart(2, "0");
            const month = String(date.getMonth() + 1).padStart(2, "0");
            const year = date.getFullYear();

            const formattedDate = `${day}-${month}-${year}`;

            return <span>{formattedDate}</span>;
          }}
        />

        <Column
          field="activity"
          header="Activity"
          body={activityBodyTemplate}
          headerStyle={{ paddingLeft: "15px", paddingRight: "15px" }}
          bodyStyle={{ paddingLeft: "15px", paddingRight: "15px" }}
          headerClassName="bg-theme-color"
        />
      </DataTable>
    </div>
  );
};

export default ALLNewsTable;
