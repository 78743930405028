import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Profileget } from "../apis/api/ProfileApi";
import { useNavigate, Link } from "react-router-dom";
import AboutDetails from "../forms/AboutDetails";
import EditMemberDetails from "../forms/EditDetailsofMember";
import Albums from "../album/Album";
import AddAlbum from "../album/AddAlbum";
import Timelines from "../timeline/TimeLineForPofile";
import ServicesForm from "../forms/Editservices";
import ImportExport from "../forms/Import_ExportEdit";
import UploadProfile from "../forms/UploadProfile";
import UploadBanner from "../forms/UploadBanner";
import IntroEdit from "../forms/CompanyIntroEdit";
import AddPhoto from "../album/photos/Addphotos";
import AddVideo from "../album/videos/AddVideo";
import Cookies from "js-cookie";
import SocialLinksForm from "../forms/SocialLinksForm";

function MemberDetails() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [member, setMember] = useState();
  const [Services, setServices] = useState([]);
  const [branch, setbranch] = useState([]);
  const [activeTab, setActiveTab] = useState("about");
  const [selectedLocation, setSelectedLocation] = useState(0);
  const [Location, setLocation] = useState([]);

  const id = Cookies.get("company_id");
  const userId = Cookies.get("userId");
  let memberData = useSelector((state) => state.profileSlice.profile);

  const access_data = JSON.parse(Cookies.get("userJSON"));
  const access = JSON.parse(Cookies.get("access"));

  const branch_access = JSON.parse(Cookies.get("userJSON"));
  const member_type = Cookies.get("member_type");

  let galleryData = useSelector((state) => state.gallerySlice.gallery);
  const albumsData = galleryData?.data || [];
  let videoData = useSelector((state) => state.gallerySlice.media);
  const videoGalleryData = videoData?.data?.videos || [];
  let photoData = useSelector((state) => state.gallerySlice.media);
  const photossData = photoData?.data?.images || [];

  const [visibleMember, setVisibleMember] = useState(null);

  const handleToggleDetails = (id) => {
    setVisibleMember(visibleMember === id ? null : id);
  };

  const isDetailsVisible = (id) => visibleMember === id;
  useEffect(() => {
    if (memberData) {
      let Service_data = memberData?.data.services;
      if (Service_data.length > 0) {
        setServices(Service_data[0]);
      }

      setMember(memberData?.data);
      setbranch(memberData?.data.company_branch);
      setLocation(memberData?.data.company_branch);
    }
  }, [memberData]);
  useEffect(() => {
    fetchData();
  }, [id]);

  const fetchData = async () => {
    dispatch(Profileget({ company_id: id, member_id: userId }));
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleNavigate = () => {
    navigate("/Team");
  };

  const handleButtonClick = (index) => {
    setSelectedLocation(index);
  };

  const [modalShow, seteditShow] = React.useState(false);
  const [uploadshow, setuploadshow] = React.useState(false);
  const [uploadBanner, setuploadBanner] = React.useState(false);
  const [aboutshow, setaboutshow] = React.useState(false);
  const [uploadAlbum, setuploadAlbum] = React.useState(false);
  const [companyIntro, setcompanyIntro] = React.useState(false);
  const [showModal, setShowModal] = useState(false);
  const [importExport, setimportExport] = useState(false);
  const [addAlbum, setaddAlbum] = useState(false);
  const [addphoto, setaddPhoto] = useState(false);
  const [addvideo, setaddvideo] = useState(false);
  const [socialLinks, setsocialLinks] = useState(false);

  const handleCloseModal = () => setaddAlbum(false);
  const handleClosePhoto = () => setaddPhoto(false);
  const handleCloseVideo = () => setaddvideo(false);
  const handlesocialLinks = () => setsocialLinks(false);
  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  if (member?.company?.[0]?.membership_start) {
    const date = new Date(member?.company?.[0]?.membership_start * 1000);

    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const day = String(date.getUTCDate()).padStart(2, "0");
    const formattedDate = `${day}-${month}-${year}`;
  }
  // const albumsData = [
  //   {
  //     id: 1,
  //     thumbnail:
  //       "https://cdn.pixabay.com/photo/2024/05/26/10/15/bird-8788491_1280.jpg",
  //     images: [
  //       "https://cdn.pixabay.com/photo/2024/06/20/17/03/fishing-8842590_640.jpg",
  //       "https://cdn.pixabay.com/photo/2024/05/26/10/15/bird-8788491_1280.jpg",
  //       "https://cdn.pixabay.com/photo/2024/05/26/10/15/bird-8788491_1280.jpg",
  //       "https://cdn.pixabay.com/photo/2024/05/26/10/15/bird-8788491_1280.jpg",
  //     ],
  //   },
  // ];

  const handleAlbumClick = () => {
    navigate("/album");
  };
  const handlePhotoCardClick = () => {
    navigate(`/photo`);
  };
  const handleVideoCardClick = () => {
    navigate(`/video`);
  };

  return (
    <>
      <EditMemberDetails show={modalShow} onHide={() => seteditShow(false)} />
      <UploadProfile show={uploadshow} onHide={() => setuploadshow(false)} />
      <UploadBanner show={uploadBanner} onHide={() => setuploadBanner(false)} />
      <AboutDetails show={aboutshow} onHide={() => setaboutshow(false)} />
      <AddAlbum ShowModal={addAlbum} onClose={handleCloseModal} />
      <AddPhoto AddPhoto={addphoto} onClose={handleClosePhoto} />
      <AddVideo AddVideo={addvideo} onClose={handleCloseVideo} />
      <ImportExport
        showModal={importExport}
        handleClose={() => setimportExport(false)}
      />
      <SocialLinksForm
        data={memberData}
        showModal={socialLinks}
        handleClose={() => setsocialLinks(false)}
      />

      <IntroEdit
        show={companyIntro}
        onHide={() => setcompanyIntro(false)}
        member={member?.company}
      />
      <ServicesForm
        data={Services}
        showModal={showModal}
        handleClose={handleClose}
      />
      {memberData && (
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-md-12 col-lg-12 parent-container ">
              <div className="container-fluid mt-4 bg-light parent">
                <div className="row">
                  <div className="col-12 col-md-12 col-lg-12 col-sm-12 col-xl-12">
                    <div className="banner-container position-relative mb-4">
                      <div className="banner-frame">
                        <img
                          src={`${member?.company?.[0]?.company_banner}`.replace(
                            "/thumb/",
                            "/"
                          )}
                          className="img-fluid banner-logo w-100"
                          onError={(e) => {
                            e.target.src = "/FL-cover.png";
                          }}
                        />
                      </div>

                      {access.access == 1 && (
                        <button
                          className="btn btn-secondary btn-sm camera-btn"
                          onClick={() => setuploadBanner(true)}
                        >
                          <i className="fas fa-camera"></i>
                        </button>
                      )}

                      <div className="ms-4 profile Profile">
                        <img
                          src={
                            member?.company?.[0]?.company_logo ||
                            "default-logo.png"
                          }
                          alt="Company Logo"
                          onError={(e) => {
                            e.target.src = "/logo.png";
                          }}
                        />
                        {access.access == 1 && (
                          <button
                            className="btn btn-secondary btn-sm  mtN-53 "
                            onClick={() => setuploadshow(true)}
                          >
                            <i className="fas fa-camera"></i>
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="mt-5">
                    <div className="d-flex justify-content-between align-items-center">
                      <h3 className="profile-name text-theme ms-4 mt-5">
                        {member?.company?.[0]?.company_name}
                      </h3>

                      <div
                        className="position-relative d-flex justify-content-evenly align-items-center bg-light rounded-3 p-2 border border-secondary mt-5"
                        style={{ width: "300px", height: "50px" }}
                      >
                        <Link to={member?.company?.[0]?.facebook_links || "https://www.facebook.com/login"}   target="_blank">
                          <i className="fab fa-facebook fa-lg fs-5 color-black"></i>
                        </Link>
                        

                      <Link
                          to= {member?.company?.[0]?.linkedin_links || "https://www.linkedin.com/login"}
                            target="_blank"
                        className="text-decoration-none me-2 fs-5 color-black"
                      >
                        <i className="fab fa-linkedin fa-lg"></i>
                      </Link>

                      <Link
                         to= {member?.company?.[0]?.instagram_links || "https://www.instagram.com/login"}
                           target="_blank"
                        className="text-decoration-none me-2 fs-5 color-black "
                      >
                        <i className="fab fa-instagram fa-lg"></i>
                      </Link>

                      <Link
                        to= {member?.company?.[0]?.twitter_links || "https://www.twitter.com/login"}
                          target="_blank"
                        className="text-decoration-none me-5 fs-5 color-black "
                      >
                        <i className="fab fa-twitter fa-lg"></i>
                      </Link>


                      <button
                        className="position-absolute top-0 end-0 btn btn-outline-secondary bg-theme-color btn-sm border-0  "
                        onClick={() => setsocialLinks(true)}
                      >
                        <i className="fas fa-pen fa-sm"></i>
                      </button>
                    </div>
                  </div>

                  <p className="profile-title text-muted ms-4">
                    <i className="fas fa-globe me-2"></i>
                    <a
                      href={`${member?.company?.[0]?.website?.startsWith("http")
                          ? ""
                          : "https://"
                        }${member?.company?.[0]?.website}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-decoration-none me-2"
                    >
                      {member?.company?.[0]?.website}
                    </a>
                  </p>
                </div>
              </div>
              {member?.company?.[0]?.status == 0 && (
                <div className="">
                  {/* <div className="d-flex justify-content align-items-center">
                      <h6 className="mb-0 text-danger">
                        {" "}
                        use the profile wizards to improve your profile
                        completeness.
                      </h6>
                      <Link
                        to="/profile wizard"
                        className="btn btn-primary btn-sm ms-3"
                      >
                        Wizard
                      </Link>
                    </div> */}
                </div>
              )}

              <div className="form-row mt-5">
                <div className="form-group col-md-12">
                  <ul className="nav nav-tabs d-flex flex-row flex-wrap justify-content-start">
                    <li
                      className="i.border-member{
tem"
                    >
                      <button
                        className={`nav-link button-collapse custom-button ${activeTab === "about" ? "active" : ""
                          }`}
                        onClick={() => handleTabClick("about")}
                      >
                        About
                      </button>
                    </li>
                  </ul>
                </div>
              </div>

              {/* Photo Tab */}
              {activeTab === "photo" && (
                <div className="container mt-3 mb-5">
                  <div class="d-flex flex-row justify-content-between"></div>

                  <Albums />
                </div>
              )}
              {activeTab === "timeline" && (
                <div className="container mt-4 mb-5 ">
                  <Timelines />
                </div>
              )}

              {/* About Tab */}
              {activeTab === "about" && (
                <div className=" row d-flex justify-content-between">
                  <div className="form-row col-12  col-md-12 col-lg-6 col-xl-6 col-xxl-6 z-14 child">
                    <div className="intro w-100">
                      <section class="container container1 my-5">
                        <div class="row align-items-center">
                          <div class="container my-2 ">
                            <div class="col-md-12 d-flex align-items-center justify-content-between mb-4">
                              <div class="d-flex align-items-center">
                                <i class="fas fa-globe me-1 "></i>
                                <h4 class="fw-bold text-theme mb-0">Intro</h4>
                              </div>
                              {access.access == 1 && (
                                <button
                                  class="btn btn-outline-secondary bg-theme-color btn-sm"
                                  onClick={() => setcompanyIntro(true)}
                                >
                                  <i class="fas fa-edit"></i> Edit
                                </button>
                              )}
                            </div>

                            <div class="row justify-content-center text-center">
                              <div>
                                <p class="lead text-muted fs-6 mb-4">
                                  {member?.company?.[0]?.bio}
                                </p>
                                <hr />
                                <div class="d-flex justify-content-start">
                                  <ul class="list-unstyled text-start f">
                                    {/* <li class="mb-2 fs-6">
                                        <i class="fas fa-map-marker-alt me-2 text-theme"></i>
                                        {member?.company?.[0]?.address}
                                      </li> */}

                                    <li className="mb-2 fs-6">
                                      Membership Start Date:{" "}
                                      {member?.company?.[0]
                                        ?.membership_start &&
                                        (() => {
                                          const date = new Date(
                                            member.company[0]
                                              .membership_start * 1000
                                          );
                                          const year = date.getUTCFullYear();
                                          const month = String(
                                            date.getUTCMonth() + 1
                                          ).padStart(2, "0");
                                          const day = String(
                                            date.getUTCDate()
                                          ).padStart(2, "0");
                                          return `${day}-${month}-${year}`;
                                        })()}
                                    </li>

                                    <li className="mb-2 fs-6">
                                      Membership End Date:{" "}
                                      {member?.company?.[0]
                                        ?.membership_expire &&
                                        (() => {
                                          const date = new Date(
                                            member.company[0]
                                              .membership_expire * 1000
                                          );
                                          const year = date.getUTCFullYear();
                                          const month = String(
                                            date.getUTCMonth() + 1
                                          ).padStart(2, "0");
                                          const day = String(
                                            date.getUTCDate()
                                          ).padStart(2, "0");
                                          return `${day}-${month}-${year}`;
                                        })()}
                                    </li>

                                    {member?.members.map((member) => {
                                      if (member.id == userId) {
                                        return (
                                          <li
                                            className="mb-2 fs-6"
                                            key={member.id}
                                          >
                                            Membership Since:{" "}
                                            {member?.membership_since &&
                                              (() => {
                                                const date = new Date(
                                                  member.membership_since *
                                                  1000
                                                );
                                                const year =
                                                  date.getUTCFullYear();
                                                const month = String(
                                                  date.getUTCMonth() + 1
                                                ).padStart(2, "0");
                                                const day = String(
                                                  date.getUTCDate()
                                                ).padStart(2, "0");
                                                return `${day}-${month}-${year}`;
                                              })()}
                                          </li>
                                        );
                                      }
                                      return null;
                                    })}

                                    <li className="mb-2 fs-6">
                                      Foundation Date:{" "}
                                      {member?.company?.[0]
                                        ?.foundation_date &&
                                        (() => {
                                          const date = new Date(
                                            member.company[0]
                                              .foundation_date * 1000
                                          );
                                          const year = date.getUTCFullYear();
                                          const month = String(
                                            date.getUTCMonth() + 1
                                          ).padStart(2, "0");
                                          const day = String(
                                            date.getUTCDate()
                                          ).padStart(2, "0");
                                          return `${day}-${month}-${year}`;
                                        })()}
                                    </li>

                                    <li className="mb-2 fs-6">
                                      Chamber of Commerce Number:{" "}
                                      {member?.company?.[0]?.company_chamber}
                                    </li>
                                    <li className="mb-2 fs-6">
                                      Tax Registration Number:{" "}
                                      {member?.company?.[0]?.tax_registration}
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                    {access_data.gallery_access == 1 && (
                      <div className="container mb-3 container1 w-100">
                        <div className="mt-3">
                          <h4 className="text-theme mt-3 mb-4 ms-1">Media</h4>
                        </div>

                        <div>
                          <div
                            className="d-flex justify-content-evenly
                             flex-wrap gap-2"
                          >
                            <button
                              onClick={() => setaddAlbum(true)}
                              className="btn btn-secondary bg-theme btn-sm mb-3 me-5"
                            >
                              <i className="fa fa-plus me-2 ms-1"></i>Add
                              Album
                            </button>

                            <button
                              onClick={() => setaddPhoto(true)}
                              className="btn btn-secondary bg-theme btn-sm mb-3 me-4"
                            >
                              <i className="fa fa-plus me-2 ms-1"></i>Add
                              Photo
                            </button>

                            <button
                              onClick={() => setaddvideo(true)}
                              className="btn btn-secondary bg-theme btn-sm mb-3 ms-3"
                            >
                              <i className="fa fa-plus me-3 ms-1"></i>Add
                              Video
                            </button>
                          </div>

                          <div className="row mb-4 mt-3">
                            {albumsData.length > 0 && (
                              <div
                                key={albumsData[0].id}
                                className="col-lg-4 col-md-6 col-sm-12 col-12 mb-3"
                                onClick={() =>
                                  handleAlbumClick(albumsData[0].id)
                                }
                              >
                                <div
                                  className="card h-100 album-card"
                                  style={{
                                    cursor: "pointer",
                                    border: "1px solid #ddd",
                                    borderRadius: "8px",
                                    overflow: "hidden",
                                    position: "relative",
                                  }}
                                >
                                  <div className="card-body p-0 position-relative">
                                    <div className="row g-0">
                                      {albumsData[0].file_url
                                        .slice(0, 4)
                                        .map((media, index) => (
                                          <div
                                            className={`col-${albumsData[0].file_url
                                                .length === 1
                                                ? "12"
                                                : "6"
                                              }`}
                                            key={index}
                                          >
                                            {media?.endsWith(".webm") ||
                                              media?.endsWith(".mp4") ||
                                              media?.endsWith(".mov") ? (
                                              <video
                                                src={media || "/no-image.png"}
                                                alt={`Album video ${index + 1
                                                  }`}
                                                className="img-fluid album-image"
                                                style={{
                                                  height: "100px",
                                                  objectFit: "cover",
                                                  width: "100%",
                                                }}
                                                controls
                                              />
                                            ) : (
                                              <img
                                                src={media || "/no-image.png"}
                                                alt={`Album image ${index + 1
                                                  }`}
                                                className="img-fluid album-image ms-1"
                                                style={{
                                                  height: "100px",
                                                  objectFit: "cover",
                                                  width: "100%",
                                                }}
                                              />
                                            )}
                                          </div>
                                        ))}
                                    </div>
                                  </div>

                                  <div className="overlay-text">
                                    <h6 className="text-white mb-0">
                                      view albums
                                    </h6>
                                  </div>

                                  <div className="card-footer text-center bg-light">
                                    <h6 className="mb-0">Albums</h6>
                                  </div>
                                </div>
                              </div>
                            )}

                            {photossData?.length > 0 && (
                              <div
                                key={photossData[0].id}
                                className="col-lg-4 col-md-6 col-sm-12 col-12 mb-3"
                                onClick={() =>
                                  handlePhotoCardClick(photossData[0].id)
                                }
                              >
                                <div
                                  className="card h-100 media-card"
                                  style={{
                                    cursor: "pointer",
                                    border: "1px solid #ddd",
                                    borderRadius: "8px",
                                    overflow: "hidden",
                                    position: "relative",
                                  }}
                                >
                                  <img
                                    src={
                                      photossData[0].file_url ||
                                      "/no-image.png"
                                    }
                                    alt={photossData[0].id}
                                    className="card-img-top media-image"
                                    style={{
                                      height: "100%",
                                      objectFit: "cover",
                                    }}
                                  />

                                  <div className="overlay-text">
                                    <h6 className="text-white mb-0">
                                      View Photo
                                    </h6>
                                  </div>
                                  <div className="card-footer text-center bg-light">
                                    <h6 className="mb-0">Photos</h6>
                                  </div>
                                </div>
                              </div>
                            )}

                            {/* Videos Section */}
                            {videoGalleryData?.length > 0 && (
                              <div
                                key={videoGalleryData[0].id}
                                className="col-lg-4 col-md-6 col-sm-12 col-12 mb-3"
                                onClick={() =>
                                  handleVideoCardClick(videoGalleryData[0].id)
                                }
                              >
                                <div
                                  className="card h-100 media-card"
                                  style={{
                                    cursor: "pointer",
                                    border: "1px solid #ddd",
                                    borderRadius: "8px",
                                    overflow: "hidden",
                                    position: "relative",
                                  }}
                                >
                                  <video
                                    src={videoGalleryData[0].file_url}
                                    className="card-img-top media-image"
                                    style={{
                                      height: "100%",
                                      width: "100%",
                                      objectFit: "cover",
                                    }}
                                    controls
                                  />
                                  {/* Overlay Text */}
                                  <div className="overlay-text">
                                    <h6 className="text-white mb-0">
                                      View Video
                                    </h6>
                                  </div>
                                  <div className="card-footer text-center bg-light">
                                    <h6 className="mb-0">Videos</h6>{" "}
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="profile-tab-item-section profile-tab-item-section-about bg-white w-100">
                      {/* {branch_access.branch_office_access == 1 && ( */}
                      <div className="container1  custom-border-radius mb-3">
                        <h4 className="text-theme mt-4 ms-3 ">
                          Branches & offices
                        </h4>
                        <div className="main-blue f16 weight600 vmiddle text-md black ml-3 d-flex  justify-content-evenly mt-4">
                          <h5 className="mt-2">
                            {member?.company_country} {Location.length}{" "}
                            offices
                          </h5>
                        </div>
                        <div className="d-flex justify-content-end">
                          {/* {member_type == 1 && ( */}

                          <Link
                            to="/about"
                            className="btn-primary btn-sm  bg-theme pe-2 ps-2 mb-3  me-2 text-decoration-none "
                            style={{ borderRadius: "3px" }}
                          >
                            <i class="fas fa-edit"></i> Edit
                          </Link>
                          {/* )} */}
                        </div>

                        <div className="d-flex flex-wrap mb-5 ms-4">
                          {branch.map((location, index) => (
                            <button
                              key={index}
                              className={`btn btn-primary btn-sm bg-theme me-3 mt-3 ${selectedLocation === index ? "active" : ""
                                }`}
                              onClick={() => handleButtonClick(index)}
                            >
                              {location.city}
                            </button>
                          ))}
                        </div>
                        {Location.length > 0 && (
                          <div className="container  mt-5 mb-3">
                            <div className="row">
                              <div className="col-12">
                                <div className="contact-info bg-white p-3">
                                  <p>
                                    <strong className="text-theme">
                                      Address:
                                    </strong>{" "}
                                    {Location[selectedLocation].address}
                                  </p>
                                  <p>
                                    <strong className="text-theme">
                                      House Number:
                                    </strong>{" "}
                                    {Location[selectedLocation]?.house_number}
                                  </p>
                                  <p>
                                    <strong className="text-theme">
                                      Zip code:
                                    </strong>{" "}
                                    {Location[selectedLocation]?.pin_code}
                                  </p>
                                  <p>
                                    <strong className="text-theme">
                                      City:
                                    </strong>{" "}
                                    {Location[selectedLocation]?.city}
                                  </p>
                                  <p>
                                    <strong className="text-theme">
                                      Phone Number:
                                    </strong>{" "}
                                    {Location[selectedLocation]?.country_code
                                      ?.replace(/^\d+:/, "")
                                      .replace(
                                        /([\uD83C][\uDDE6-\uDDFF][\uD83C][\uDDE6-\uDDFF])/,
                                        "$1+"
                                      )}{" "}
                                    {Location[selectedLocation].contact}
                                  </p>

                                  <p>
                                    <strong className="text-theme">
                                      Email:
                                    </strong>{" "}
                                    {Location[selectedLocation]?.email}
                                  </p>
                                </div>
                              </div>

                              {/* <div className="col-12">
                                 <div className="map mt-3">
                                   <iframe
                                     src={locations[selectedLocation].mapSrc}
                                     width="100%"
                                     height="250"
                                     style={{ border: 0 }}
                                     allowFullScreen
                                     loading="lazy"
                                   ></iframe>
                                 </div>
                               </div> */}
                            </div>
                          </div>
                        )}
                      </div>
                      {/* )} */}
                      <div className="container1 custom-border-radius mb-3">
                        <div className="col-12 ">
                          <h4 className="text-theme mt-2 ms-2 mt-5">
                            Contacts
                          </h4>
                          {/* {company_id == userid && ( */}
                          <div className="d-flex justify-content-end">
                            <button
                              className="btn btn-primary bg-theme btn-sm mt-3 ms-2"
                              onClick={() => handleNavigate()}
                            >
                              <i className="fas fa-edit"></i> Edit
                            </button>
                          </div>

                          {/* )} */}

                          <div className="container-fluid">
                            <div className="row">
                              {member?.members.map((member) => (
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6">
                                  <div className="our-team mt-5">
                                    <div className="position-relative">
                                      <div className="team-card">
                                        <div className="picture">
                                          <img
                                            className="img-fluid"
                                            src={`${member.member_logo}`}
                                            alt="Profile"
                                            onError={(e) => {
                                              e.target.src = "/dumy.jpeg";
                                            }}
                                          />
                                        </div>

                                        <div className="team-content d-flex flex-column align-items-center text-center">
                                          <h6 className="name mb-3">
                                            {member.salutation &&
                                              `${member.salutation} `}
                                            {member.first_name}{" "}
                                            {member.middle_name}{" "}
                                            {member.last_name}
                                          </h6>

                                          <p className="mb-3">
                                            {member.job_title}
                                          </p>

                                          {/* Toggle button for expanding/collapsing */}
                                          <button
                                            className="btn btn-primary btn-sm bg-theme mb-4"
                                            onClick={() =>
                                              handleToggleDetails(member.id)
                                            }
                                          >
                                            {isDetailsVisible(member.id)
                                              ? "Less"
                                              : "More"}
                                          </button>

                                          {/* Collapsible content */}
                                          <div
                                            className={`collapsible-content ${isDetailsVisible(member.id)
                                                ? "show"
                                                : ""
                                              }`}
                                          >
                                            <p>
                                              <strong>Department:</strong>{" "}
                                              {member.department}
                                            </p>

                                            {member.location && (
                                              <p>
                                                <strong>location:</strong>{" "}
                                                {member.location}
                                              </p>
                                            )}

                                            {member.email && (
                                              <p>
                                                <i className="fas fa-envelope text-theme"></i>{" "}
                                                {member.email}
                                              </p>
                                            )}

                                            {member.contact_direct && (
                                              <p>
                                                {/* <strong>
                                                    Direct Phone Number:
                                                  </strong>{" "} */}
                                                <i className="fas fa-phone text-theme"></i>{" "}
                                                {member.country_code_direct
                                                  ?.replace(/^\d+:/, "")
                                                  .replace(
                                                    /([\uD83C][\uDDE6-\uDDFF][\uD83C][\uDDE6-\uDDFF])/,
                                                    "$1+"
                                                  )}
                                                {member.pin_code_direct &&
                                                  ` (${member.pin_code_direct})`}{" "}
                                                {member.contact_direct}
                                              </p>
                                            )}
                                            {member.contact && (
                                              <p>
                                                {/* <strong>Phone Number:</strong>{" "} */}
                                                <i class="fas fa-mobile-alt"></i>{" "}
                                                {member.country_code
                                                  ?.replace(/^\d+:/, "")
                                                  .replace(
                                                    /([\uD83C][\uDDE6-\uDDFF][\uD83C][\uDDE6-\uDDFF])/,
                                                    "$1+"
                                                  ) +
                                                  " " +
                                                  member.contact}
                                              </p>
                                            )}
                                          </div>
                                        </div>

                                        <ul className="social">
                                          <li>
                                            <a
                                              href={"mailto:" + member.email}
                                              className="fas fa-envelope"
                                              aria-hidden="true"
                                            ></a>
                                          </li>
                                          {/* <li>
                                              <a
                                                href={
                                                  "tel:" +
                                                  member.country_code +
                                                  member.contact
                                                }
                                                className="fas fa-address-book"
                                                aria-hidden="true"
                                              ></a>
                                            </li> */}
                                          <li>
                                            <a
                                              href={
                                                "https://wa.me/" +
                                                member.contact
                                              }
                                              className="fab fa-whatsapp"
                                              aria-hidden="true"
                                              target="_blank"
                                              rel="noopener noreferrer"
                                            ></a>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mt-5 mb-3 position-relative">
                        {access.access === 1 && (
                          <button
                            className="btn btn-secondary bg-theme btn-sm position-absolute top-0 end-0 m-2 edit-btn"
                            onClick={() => setimportExport(true)}
                          >
                            <i className="fas fa-edit"></i> Edit
                          </button>
                        )}

                        <h4 className="mb-4 ms-3">Code ACTIVE IN</h4>

                        <div className="container mt-5">
                          <div className="row align-items-center">
                            <div className="col-12 ">
                              <div className="d-flex flex-column align-items-center">
                                <div className="row">
                                  {/*heading */}

                                  <div className="col-12 mb-3">
                                    <div className="d-flex justify-content-evenly align-items-center">
                                      <div className="service-box-import-export bg-theme-color">
                                        <h6>Import</h6>
                                      </div>

                                      <div className="service-box-import-export bg-theme-color">
                                        <h6>Export</h6>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-12 mb-3 mt-5">
                                    <div className="d-flex justify-content-evenly align-items-center">
                                      <div className="service-box-import-export">
                                        <p>Import Service 1</p>
                                      </div>

                                      <div className="service-box-import-export">
                                        <p>Export Service 1</p>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-12 mb-3 ">
                                    <div className="d-flex justify-content-evenly align-items-center">
                                      <div className="service-box-import-export">
                                        <p>Import Service 2</p>
                                      </div>
                                      <div className="service-box-import-export">
                                        <p>Export Service 2</p>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-12 mb-3">
                                    <div className="d-flex justify-content-evenly align-items-center">
                                      <div className="service-box-import-export">
                                        <p>Import Service 3</p>
                                      </div>

                                      <div className="service-box-import-export">
                                        <p>Export Service 3</p>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-12 mb-3">
                                    <div className="d-flex justify-content-evenly align-items-center">
                                      <div className="service-box-import-export">
                                        <p>Import Service 4</p>
                                      </div>

                                      <div className="service-box-import-export">
                                        <p>Export Service 4</p>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-12 mb-3">
                                    <div className="d-flex justify-content-evenly align-items-center">
                                      <div className="service-box-import-export">
                                        <p>Import Service 5</p>
                                      </div>

                                      <div className="service-box-import-export">
                                        <p>Export Service 5</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <hr />
                      <div className="mt-5 mb-3 position-relative">
                        {access.access == 1 && (
                          <button
                            className="btn btn-secondary bg-theme btn-sm position-absolute top-0 end-0 m-2 edit-btn"
                            onClick={handleShow}
                          >
                            <i className="fas fa-edit"></i> Edit
                          </button>
                        )}
                        <h4 className="mb-4 ms-3">Services</h4>
                        <div className="container">
                          <div className="row">
                            {Array.isArray(Services.basic_services) &&
                              Services.basic_services.map((service) => (
                                <div
                                  className="col-md-6 col-xl-3 col-xxl-3 col-sm-12 mb-3 ms-4"
                                  key={service.id}
                                >
                                  <div className="service-box d-flex align-items-center">
                                    {service.icon ? (
                                      <img
                                        src={service.icon}
                                        alt="Service Logo"
                                        className="service-logo me-2"
                                      />
                                    ) : (
                                      <span className="dot bg-primary me-2"></span>
                                    )}
                                    <p className="mb-0">{service.name}</p>
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>

                        <h4 className="mb-4 mt-5 ms-3">Special Services</h4>
                        <div className="container">
                          <div className="row">
                            {Array.isArray(Services.special_services) &&
                              Services.special_services.map((service) => (
                                <div
                                  className="col-md-6 col-xl-3 col-xxl-3 col-sm-12 mb-3 ms-4"
                                  key={service.id}
                                >
                                  <div className="service-box d-flex align-items-center">
                                    {service.icon_url ? (
                                      <img
                                        src={service.icon_url}
                                        alt="Service Logo"
                                        className="service-logo me-2"
                                      />
                                    ) : (
                                      <span className="dot bg-primary me-2"></span>
                                    )}
                                    <p className="mb-0">{service.name}</p>
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {access_data.post_access ? (
                    <div className="form-row col-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 child">
                      <Timelines />
                    </div>
                  ) : (
                    <div className="upgrade-section text-center col-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 mt-5">
                      <p>Upgrade your plan to post and get feeds.</p>
                      <Link to="/membership">
                        <button className="btn btn-secondary bg-theme-color btn-sm mt-2">
                          Upgrade Now
                        </button>
                      </Link>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>

          {/* Video Tab */}
      {activeTab === "video" && <p>video</p>}
    </div >
      )
}
    </>
  );
}

export default MemberDetails;
