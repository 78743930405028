import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { CommunityAll } from "../apis/api/CommunityApi";
import { useDispatch, useSelector } from "react-redux";
import { FILE_URL } from "../apis/api/Config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Cookies from "js-cookie";
import {
  faEye,
  faEdit,
  faTrash,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";

const MyDataTable = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredProfiles, setFilteredProfiles] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [selectedProfiles, setSelectedProfiles] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Initialize navigate hook
  const sourceType = Cookies.get("sorce_type");

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(CommunityAll({ source_type: sourceType }));
    };
    fetchData();
  }, [dispatch, sourceType]);

  const { community } = useSelector((state) => state.communitySlice) || {};

  useEffect(() => {
    if (community && community.data) {
      const query = searchQuery.toLowerCase();
      const filteredData = community.data.filter((customer) => {
        const fieldsToSearch = [
          customer.company_name || "",
          customer.member_plan || "",
          customer.country || "",
          customer.city || "",
          customer.website || ""
        ];
        return fieldsToSearch.some((field) =>
          field.toLowerCase().includes(query)
        ) ||
          customer.membership_start === searchQuery ||
          customer.membership_end === searchQuery ||
          customer.membership_activation === searchQuery ||
          customer.membership_since === searchQuery;
      });
      setFilteredProfiles(filteredData);
    }
  }, [searchQuery, community]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const onSelectionChange = (e) => {
    setSelectedProfiles(e.value);
  };

  const handleRowClick = async(rowData) => {
   navigate(`/member/${rowData.company_id}/${rowData.member_id}`) ;
  };

  const nameBodyTemplate = (rowData) => (
    <div className="d-flex align-items-center">
      <img
        src={
          rowData?.company_logo && rowData.company_logo !== ""
            ? `${FILE_URL}/${rowData.company_logo}`
            : `/dumy.jpeg`
        }
        alt={rowData.company_name || "Default Logo"}
        style={{
          width: "30px",
          height: "30px",
          marginRight: "10px",
          borderRadius: "50%",
        }}
        onError={(e) => {
          e.target.src = "/dumy.jpeg"; // Use default image on error
        }}
      />
      {rowData.company_name}
    </div>
  );

  const activityBodyTemplate = (rowData) => (
    <div className="d-flex justify-content-around me-2">
       <Link to={`/member/${rowData.company_id}/${rowData.id}`} >
        <button className="btn btn-primary me-1 p-0">
          <FontAwesomeIcon icon={faEye} className="text-white p-1" />
        </button>
      </Link>
    </div>
  );

  const handleRowsChange = (e) => {
    setRowsPerPage(Number(e.target.value));
  };
  const handleBack = () => {
    navigate(-1);
  };
  return (
    <div className="datatable-crud-demo container-fluid ">
    <div className="d-flex justify-content-between align-items-center mb-2 mt-3">
  <h5 className="text-color member-list-heading mb-0">
    <i className="fas fa-users me-1"></i>Member List
  </h5>
  <div className="ms-auto">
    <button className="btn btn-secondary bg-theme-color btn-sm"   onClick={handleBack}>
      <i className="fas fa-arrow-left me-2"></i>Back
    </button>
  </div>
</div>

      <div className="d-flex justify-content-end align-items-center mb-3">
        <div className="input-group" style={{ maxWidth: "300px" }}>
          <input
            type="text"
            className="form-control"
            placeholder="Search by..."
            value={searchQuery}
            onChange={handleSearchChange}
          />
          <button className="btn btn-primary bg-theme mt-0">
            <FontAwesomeIcon icon={faSearch} />
          </button>
        </div>
        <select
          className="form-select ms-2"
          value={rowsPerPage}
          onChange={handleRowsChange}
          style={{ width: "100px" }}
        >
          <option value={5}>5</option>
          <option value={10}>10</option>
          <option value={20}>20</option>
          <option value={30}>30</option>
          <option value={50}>50</option>
        </select>
      </div>

      <DataTable
        value={filteredProfiles}
        paginator
        rows={rowsPerPage}
        emptyMessage="No members found."
        rowClassName={() => "custom-row"}
        selection={selectedProfiles}
        onSelectionChange={onSelectionChange}
        scrollable 
        scrollDirection="vertical"
        selectionMode="checkbox"
        onRowClick={(e) => handleRowClick(e.data)} 
      >
        <Column
        field="company_name"
          body={nameBodyTemplate}
          header="Company Name"
          headerClassName="bg-theme-color ms-2"
          headerStyle={{ paddingLeft: "25px", paddingRight: "30px" }}
          bodyStyle={{ paddingLeft: "25px", paddingRight: "30px" }}
          sortable
        />

        <Column
          field="website"
          header="Website"
          headerStyle={{ paddingLeft: "15px", paddingRight: "15px" }}
          bodyStyle={{ paddingLeft: "15px", paddingRight: "15px" }}
          headerClassName="bg-theme-color"
          sortable
        />

        <Column
          field="country"
          header="Country"
          body={(rowData) => rowData.country.split(":")[1]}
          headerStyle={{ paddingLeft: "15px", paddingRight: "15px" }}
          bodyStyle={{ paddingLeft: "15px", paddingRight: "15px" }}
          headerClassName="bg-theme-color"
          sortable
        />

        <Column
          field="member_plan"
          header="Membership Plan"
          headerStyle={{ paddingLeft: "15px", paddingRight: "15px" }}
          bodyStyle={{ paddingLeft: "15px", paddingRight: "15px" }}
          headerClassName="bg-theme-color"
          sortable
          body={(rowData) => {
            const planName = rowData.member_plan.split(":")[1];
            return <span>{planName}</span>;
          }}
        />

        <Column
          field="membership_start"
          header="Membership Start Date"
          headerStyle={{ paddingLeft: "15px", paddingRight: "15px" }}
          bodyStyle={{ paddingLeft: "15px", paddingRight: "15px" }}
          headerClassName="bg-theme-color"
          sortable
          body={(rowData) => {
            if (!rowData.membership_start || rowData.membership_start == 0) {
              return <span>&nbsp;</span>; 
            }
            const timestamp = parseInt(rowData.membership_start) * 1000;
            const date = new Date(timestamp);
            const day = String(date.getDate()).padStart(2, "0");
            const month = String(date.getMonth() + 1).padStart(2, "0");
            const year = date.getFullYear();
            const formattedDate = `${day}-${month}-${year}`;
            return <span>{formattedDate}</span>;
          }}
        />

        <Column
          field="membership_expire"
          header="Membership end Date"
          headerStyle={{ paddingLeft: "15px", paddingRight: "15px" }}
          bodyStyle={{ paddingLeft: "15px", paddingRight: "15px" }}
          headerClassName="bg-theme-color"
          sortable
          body={(rowData) => {
            if (!rowData.membership_expire || rowData.membership_expire == 0) {
              return <span>&nbsp;</span>; 
            }
            const timestamp = parseInt(rowData.membership_expire) * 1000;
            const date = new Date(timestamp);
            const day = String(date.getDate()).padStart(2, "0");
            const month = String(date.getMonth() + 1).padStart(2, "0");
            const year = date.getFullYear();
            const formattedDate = `${day}-${month}-${year}`;
            return <span>{formattedDate}</span>;
          }}
        />

       <Column
  field="membership_since"
  header="Membership Since Date"
  headerStyle={{ paddingLeft: "15px", paddingRight: "15px" }}
  bodyStyle={{ paddingLeft: "15px", paddingRight: "15px" }}
  headerClassName="bg-theme-color"
  sortable
  body={(rowData) => {
    if (!rowData.membership_since  || rowData.membership_since == 0) {
      return <span>&nbsp;</span>; 
    }
    const timestamp = parseInt(rowData.membership_since) * 1000;
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const formattedDate = `${day}-${month}-${year}`;
    return <span>{formattedDate}</span>;
  }}
/>


        <Column
          body={activityBodyTemplate}
          header="Action"
          headerStyle={{ paddingLeft: "15px", paddingRight: "15px" }}
          bodyStyle={{ paddingLeft: "15px", paddingRight: "15px" }}
          headerClassName="bg-theme-color"
        />
      </DataTable>
    </div>
  );
};

export default MyDataTable;
