import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Timelines from "./timeline/TimeLineForPofile";
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  useLocation,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import ProtectedRoute from "./hooks/ProtectedRoute";
import ProtectedOtp from "./hooks/ProtectedOtp";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "js-cookie";
import Signup from "./Authentication/Signup";
import Signin from "./Authentication/Signin";
import Thankuou from "./Authentication/Thankyou";
import Main from "./feeds/Main2";
import MainNavFooter from "./layout/MainNavFooter";
import Registration from "./forms/Registration";
import OTP from "./Authentication/OTP";
import News from "./news/News";
import MemberRegistration from "./forms/MemberRegistration";
import MembershipPlan from "./membership/Membership";
import "./App.css";
import Loading from "./hooks/Loading";
import MemberDetails from "./Profile/MemberDetail";
import Dashboard from "./dashboard/dashboard";
import Team from "./Managemember/ManageTeams";
import EditDetails from "./forms/EditDetailsofMember";
import Sidebar from "./layout/SidebarWithMainLayout";
import AboutDetails from "./Aboutdetail/AboutDetail";
import Community from "./Communitiy/Community";
import ActiveMemberTable from "./Tables/ActiveMemberTable";
import ALLNewsTable from "./Tables/ALLNewsTable";
import Album from "./album/Album";
import OtherMember from "./Profile/OtherMembers";
import AlbumDetails from "./album/Albumdetails";
import BranchDetailsAndServices from "./forms/BranchDetailsAndServices";
import Gallary from "./album/Gallary";
import ResetPassword from "./password/resetPassword";
import FirstResetPassword from "./password/First_reset";
import MultiStepForm from "./Wizard/multi-stepwe";
import Forget from "./Authentication/Forget/ForgatPassword";
import ForgetOTP from "./Authentication/Forget/ForgetOtp";
import ForgetPassword from "./Authentication/Forget/Password";
import ProtectedMemberRoute from "./hooks/ProtectedMemberRoute";
import Photo from "./album/photos/photo";
import Videos from "./album/videos/videoGallary";
import EditAlbum from "./album/editAlbum";
import WebSocketTest from "./chat/WebSocketTest";
import ChatApp from "./chat/Chat";
// import chatguru from "./chat/chatguru";
import Chatguru from "./chat/chatguru";
// import 'mdb-react-ui-kit/dist/css/mdb.min.css';
// import "@fortawesome/fontawesome-free/css/all.min.css";



function AppContent() {
  const navigate = useNavigate();
  const userId = Cookies.get("userId");
  const jsonData = Cookies.get("userJSON");
  const status = Cookies.get("status");
  const memberId = sessionStorage.getItem("userid");

  const clearAllCookies = () => {
    const allCookies = Cookies.get();
    Object.keys(allCookies).forEach((cookie) => {
      Cookies.remove(cookie);
    });
  };

  if (!memberId && userId){
    clearAllCookies();
    navigate("/");
   
}

  let parsedData = {};
  if (jsonData) {
    parsedData = JSON.parse(jsonData);
  }

  const [loading, setLoading] = useState(false);
  const location = useLocation();
 
  
  let member_status;                                                   

  if (status) {
    member_status = status;
  }

  
  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500);
    return () => clearTimeout(timer);
  }, [location]);

  return (
    <>
      <Loading loading={loading} />
      <Routes>
        <Route path="/" element={userId ? <Navigate to="/home" /> : <Signin />}/>
        <Route path="/signup" element={<Signup />} />
        <Route path="/thankyou" element={<Thankuou />} />
        <Route path="/services" element={<BranchDetailsAndServices />} />
        <Route path="/WebSocketTest" element={<WebSocketTest />} />
        <Route path="/chatguru" element={<Chatguru/>}/>
      
        {/* <Route path="/registration/:plan/:id" element={<MemberRegistration />} /> */}
        <Route path="/registration/ivory/2" element={<MemberRegistration />} />
        <Route path="/registration/gold/2" element={<MemberRegistration />} />
        <Route path="/registration/platinum/2" element={<MemberRegistration />}/>
        <Route path="/registration/silver/2" element={<MemberRegistration />} />
        <Route path="/registration/1" element={<MemberRegistration />} />
        <Route path="/forget" element={<Forget />} />
        <Route path="/Forget_Password" element={<ForgetPassword />} />
        {/* Protected routes */}
        <Route element={<ProtectedRoute />}>
          {parsedData?.post_access == 1 && (
            <Route path="/feeds" element={<Sidebar> <Main /></Sidebar>}/>
          )}

          

          {/* Routes protected by member_status */}
          <Route element={<ProtectedMemberRoute member_status={member_status} />}>
            <Route path="/profile-wizard" element={<Sidebar><MultiStepForm /></Sidebar>} />
            <Route path="/feeds" element={<Sidebar><Main /></Sidebar>} />
            {/* <Route path="/editalbum" element={<Sidebar><EditAlbum /></Sidebar>} /> */}
            <Route path="/member" element={<MainNavFooter><Registration /></MainNavFooter>} />
            <Route path="/news" element={<Sidebar><News /></Sidebar>} />
            <Route path="/home" element={<Sidebar><Dashboard /></Sidebar>} />
            <Route path="/membership" element={<Sidebar><MembershipPlan /></Sidebar>} />
            <Route path="/member/:id/:memberid" element={<Sidebar><OtherMember /></Sidebar>} />
            <Route path="/Team" element={<Sidebar><Team /></Sidebar>} />
            <Route path="/profile" element={<Sidebar><MemberDetails /></Sidebar>} />
            <Route path="/edit" element={<Sidebar><EditDetails /></Sidebar>} />
            <Route path="/about" element={<Sidebar><AboutDetails /></Sidebar>} />
            <Route path="/community" element={<Sidebar><Community /></Sidebar>} />
            <Route path="/activeMember" element={<Sidebar><ActiveMemberTable /></Sidebar>} />
            <Route path="/allnews" element={<Sidebar><ALLNewsTable /></Sidebar>} />
            <Route path="/album" element={<Sidebar><Album /></Sidebar>} />
            <Route path="/photo" element={<Sidebar><Photo /></Sidebar>} />
            <Route path="/video" element={<Sidebar><Videos /></Sidebar>} />
            <Route path="/gallary" element={<Sidebar><Gallary /></Sidebar>} />
            <Route path="/timeline" element={<Sidebar><Timelines /></Sidebar>} />
            <Route path="/album/:albumId" element={<Sidebar><AlbumDetails /></Sidebar>} />
            <Route path="/reset_password" element={<Sidebar><ResetPassword /></Sidebar>} />
            <Route path="/chat" element={<Sidebar> <ChatApp/></Sidebar>}/>
         
          </Route>
          {member_status == 0 && (
            <Route path="/first_reset_password" element={<FirstResetPassword />} />
          )}
         
        </Route>

        {/* Protected OTP route */}
        <Route element={<ProtectedOtp />}>
          <Route path="/otp" element={<OTP />} />
          <Route path="/Forgetotp" element={<ForgetOTP />} />
        </Route>
      </Routes>
    </>
  );
}

function App() {
  return (
    <BrowserRouter>
      <AppContent />
      <ToastContainer />
    </BrowserRouter>
  );
}

export default App;
