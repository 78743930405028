import { createSlice } from "@reduxjs/toolkit";
import { FeedsInsert, FeedsGet ,CompanyFeedsGet,FeedsLikes, insertcomment ,fetchcomments,fetchSearchDetails,Memberpost,PostDelete,postUpdate} from "../api/FeedsApi";

const feedsSlice = createSlice({
    name: "feedsSlice",
    initialState: {
      loading: false,
      feeds_insert: null,
      feeds_get:null,
      feeds_like:null,
      comment_insert:null,
      comment_get:'',
      member_feeds:'',
      Company_Feed:'',
      fetchSearchDetails:null

    },
    reducers:{
    },

    extraReducers: (builder) => {
      builder.addCase(FeedsInsert.pending, state => {
        state.loading = true
      })
      builder.addCase(FeedsInsert.fulfilled,  (state, action) => {
       state.feeds_insert=action.payload;
        state.loading = false
       
      })
      builder.addCase(FeedsInsert.rejected, (state,action) => {
      
        state.loading = false
      })


      builder.addCase(FeedsGet.pending, state => {
        state.loading = true
      })

      builder.addCase(FeedsGet.fulfilled,  (state, action) => {
       state.feeds_get=action.payload;
        state.loading = false
       
      })
      
      builder.addCase(FeedsGet.rejected, (state,action) => {
     
        state.loading = false
      })


      builder.addCase(Memberpost.pending, state => {
        state.loading = true
      })

      builder.addCase(Memberpost.fulfilled,  (state, action) => {
       state.member_feeds=action.payload;
     
        state.loading = false
       
      })
      
      builder.addCase(Memberpost.rejected, (state,action) => {
     
        state.loading = false
      })

     //MemberFeedsGet   

      // builder.addCase(MemberFeedsGet.pending, state => {
      //   state.loading = true
      // })

      // builder.addCase(MemberFeedsGet.fulfilled,  (state, action) => {
      //  state.member_feeds=action.payload;
     
      //   state.loading = false
       
      // })
      
      // builder.addCase(MemberFeedsGet.rejected, (state,action) => {
    
      //   state.loading = false
      // })
 
//CompanyFeedsGet

builder.addCase(CompanyFeedsGet.pending, state => {
  state.loading = true
})

builder.addCase(CompanyFeedsGet.fulfilled,  (state, action) => {
 state.Company_Feed=action.payload;

  state.loading = false
 
})

builder.addCase(CompanyFeedsGet.rejected, (state,action) => {
 
  state.loading = false
})








      builder.addCase(FeedsLikes.fulfilled,  (state, action) => {
       state.feeds_like=action.payload;

        
      })
      
      builder.addCase(FeedsLikes.rejected, (state,action) => {
      
       
      })

// insert-comment-slice---
      builder.addCase(insertcomment.pending, state => {
        // state.loading = true
      })
      builder.addCase(insertcomment.fulfilled,  (state, action) => {
       state.comment_insert=action.payload;
        // state.loading = false
       
      })
      builder.addCase(insertcomment.rejected, (state,action) => {
    
        // state.loading = false
      })


// get-comment-slice---
builder.addCase(fetchcomments.pending, state => {
  // state.loading = true
})
builder.addCase(fetchcomments.fulfilled,  (state, action) => {
 state.comment_get=action.payload;
  state.loading = false
 
})
builder.addCase(fetchcomments.rejected, (state,action) => {
 
  // state.loading = false
})


builder.addCase(fetchSearchDetails.pending, state => {
  // state.loading = true
})
builder.addCase(fetchSearchDetails.fulfilled,  (state, action) => {
 state.comment_get=action.payload;
  state.loading = false
 
})
builder.addCase(fetchSearchDetails.rejected, (state,action) => {
 
  // state.loading = false
})
   
builder.addCase(PostDelete.pending, state => {
  state.loading = true
})
builder.addCase(PostDelete.fulfilled,  (state, action) => {
  state.loading = false
 
})
builder.addCase(PostDelete.rejected, (state,action) => {
  state.loading = false
})

builder.addCase(postUpdate.pending, state => {
  state.loading = true
})
builder.addCase(postUpdate.fulfilled,  (state, action) => {
  state.loading = false
 
})
builder.addCase(postUpdate.rejected, (state,action) => {
 
  state.loading = false
})
    
    }
  })
  export default feedsSlice.reducer;


  


