import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import "./AlbumDetails.css";
import Modal from "react-bootstrap/Modal";
import Loading from "../hooks/Loading";

// Helper function to check if the media is a video
const isVideoFile = (fileUrl) => {
  return fileUrl?.endsWith(".webm") || fileUrl?.endsWith(".mp4") || fileUrl?.endsWith(".mov");
};

function AlbumDetails() {
  const { albumId } = useParams();
  const navigate = useNavigate();

  const galleryData =
    useSelector((state) => state.gallerySlice.gallery)?.data || [];

  const [selectedAlbum, setSelectedAlbum] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [imageLoading, setImageLoading] = useState({});
  const [displayCount, setDisplayCount] = useState(30);
  const [loadingMore, setLoadingMore] = useState(false);

  useEffect(() => {
    const album = galleryData.find((album) => album.id === parseInt(albumId));
    setSelectedAlbum(album);
  }, [albumId, galleryData]);

  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const scrollHeight = document.documentElement.scrollHeight;
      const clientHeight = window.innerHeight;

      if (scrollTop + clientHeight >= scrollHeight - 100 && !loadingMore) {
        loadMoreImages();
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [displayCount, loadingMore, selectedAlbum]);

  const loadMoreImages = () => {
    if (selectedAlbum?.file_url.length > displayCount) {
      setLoadingMore(true);
      setTimeout(() => {
        setDisplayCount((prevCount) => prevCount + 30);
        setLoadingMore(false);
      }, 1000);
    }
  };

  if (!selectedAlbum) {
    return <div>Loading...</div>;
  }

  const handleImageClick = (file_url) => {
    const fullImageUrl = file_url.replace("/thumb", "");
    setSelectedImage(fullImageUrl);
    setShowModal(true);
  };

  const handleImageLoad = (index) => {
    setImageLoading((prevState) => ({ ...prevState, [index]: false }));
  };

  const handleImageError = (index) => {
    setImageLoading((prevState) => ({ ...prevState, [index]: false }));
  };

  return (
    <>
      <div className="d-flex flex-row justify-content-end">
        <button
          className="btn new-teammates-btn btn-sm bg-theme follow mt-3"
          onClick={handleBack}
        >
          <div className="d-flex align-items-center">
            <i className="fa-solid fa-arrow-left"></i>
            <p className="ps-1 pe-1 mb-0">Back</p>
          </div>
        </button>
      </div>

      <div className="container-fluid album-details">
        <h2 className="album-title">{selectedAlbum.title}</h2>

        <div className="row gallery-grid">
          {selectedAlbum.file_url
            .slice(0, displayCount)
            .map((file_url, index) => (
              <div
                key={index}
                className="col-xl-2 col-xxl-2  col-sm-12 col-md-6  gallery-item"
                onClick={() => handleImageClick(file_url)}
              >
                {imageLoading[index] !== false && (
                  <div className="loading-spinner">
                    <Loading />
                  </div>
                )}

               
                {isVideoFile(file_url) ? (
                  <video
                    src={file_url}
                    alt={`Video ${index + 1}`}
                    className="img-fluid gallery-image modal-image"
                  
                    controls
                    onError={(e) => {
                      e.target.src = "/no-attrony.png"; 
                      handleImageError(index);
                    }}
                  />
                ) : (
                  <img
                    src={file_url}
                    alt={`Photo ${index + 1}`}
                    className="img-fluid gallery-image modal-image"
                    style={{
                      display: imageLoading[index] === false ? "block" : "none",
                    }}
                    onLoad={() => handleImageLoad(index)}
                    onError={(e) => {
                      e.target.src = "/no-attrony.png"; 
                      handleImageError(index);
                    }}
                  />
                )}
              </div>
            ))}
        </div>

        {loadingMore && (
          <div className="loading-more">Loading more images...</div>
        )}
      </div>

    
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Media Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          {selectedImage && (
            <div>
              {isVideoFile(selectedImage) ? (
                <video
                  src={selectedImage}
                  alt="Selected video"
                  className="img-fluid modal-image"
                  controls
                  onError={(e) => {
                    e.target.src = "/no-attrony.png"; 
                  }}
                />
              ) : (
                <img
                  src={selectedImage}
                  alt="Selected"
                  className="img-fluid modal-image"
                  onError={(e) => {
                    e.target.src = "/no-attrony.png"; 
                  }}
                />
              )}
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AlbumDetails;
