import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FILE_URL } from "../apis/api/Config";
import { otherProfileget } from "../apis/api/ProfileApi";
import { useNavigate, Link } from "react-router-dom";
import AboutDetails from "../forms/AboutDetails";
import EditMemberDetails from "../forms/EditDetailsofMember";
import Gallary from "../album/Gallary";
import Timelines from "../timeline/TimelineforMembers";
import ServicesForm from "../forms/Editservices";
import UploadProfile from "../forms/UploadProfile";
import UploadBanner from "../forms/UploadBanner";
import IntroEdit from "../forms/CompanyIntroEdit";
import { useParams } from "react-router-dom";

function MemberDetails() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [member, setMember] = useState();
  const [Services, setServices] = useState([]);
  const [branch, setbranch] = useState([]);
  const [activeTab, setActiveTab] = useState("about");
  const [selectedLocation, setSelectedLocation] = useState(0);
  const [Location, setLocation] = useState([]);
  const { id, memberid } = useParams();
  let memberData = useSelector((state) => state.profileSlice.otherProfile);
  const [visibleMember, setVisibleMember] = useState(null);

  // Function to toggle visibility of details
  const handleToggleDetails = (id) => {
    setVisibleMember(visibleMember === id ? null : id);
  };

  const isDetailsVisible = (id) => visibleMember === id;
  useEffect(() => {
    if (memberData) {
      let Service_data = memberData?.data.services;
      if (Service_data.length > 0) {
        setServices(Service_data[0]);
      }
      setMember(memberData?.data);
      setbranch(memberData?.data.company_branch);
      setLocation(memberData?.data.company_branch);
    }
  }, [memberData]);
  useEffect(() => {
    fetchData();
  }, [id]);
  let userId = memberid;
  const fetchData = async () => {
    dispatch(otherProfileget({ company_id: id, member_id: userId }));
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleNavigate = () => {
    navigate("/Team");
  };

  const handleButtonClick = (index) => {
    setSelectedLocation(index);
  };

  const [modalShow, seteditShow] = React.useState(false);
  const [uploadshow, setuploadshow] = React.useState(false);
  const [uploadBanner, setuploadBanner] = React.useState(false);
  const [aboutshow, setaboutshow] = React.useState(false);
  const [uploadAlbum, setuploadAlbum] = React.useState(false);
  const [companyIntro, setcompanyIntro] = React.useState(false);
  const [showModal, setShowModal] = useState(false);
  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  const handleBack = () => {
    navigate(-1);
  };
  return (
    <>
      <EditMemberDetails show={modalShow} onHide={() => seteditShow(false)} />
      <UploadProfile show={uploadshow} onHide={() => setuploadshow(false)} />
      <UploadBanner show={uploadBanner} onHide={() => setuploadBanner(false)} />
      <AboutDetails show={aboutshow} onHide={() => setaboutshow(false)} />
      <IntroEdit
        show={companyIntro}
        onHide={() => setcompanyIntro(false)}
        member={member?.company}
      />
      <ServicesForm
        data={Services}
        showModal={showModal}
        handleClose={handleClose}
      />
      <div className="d-flex justify-content-end">
        <button
          className="btn btn-secondary bg-theme-color btn-sm me-4 mt-2"
          onClick={handleBack}
        >
          <i className="fas fa-arrow-left me-2"></i>Back
        </button>
      </div>

      {memberData && (
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-md-12 col-lg-12 parent-container ">
              <div className="container-fluid mt-4 bg-light parent">
                <div className="row">
                  <div className="col-12 col-12 col-md-12 col-lg-12 col-sm-12 col-xl-12">
                    <div className="banner-container position-relative mb-4">
                      <div className="banner-frame">
                        <img
                          src={`${member?.company?.[0]?.company_banner}`.replace(
                            "/thumb/",
                            "/"
                          )}
                          className="img-fluid banner-logo w-100"
                          onError={(e) => {
                            e.target.src = "/FL-cover.png";
                          }}
                        />
                      </div>

                      <div className="ms-4 profile Profile">
                        <img
                          src={`${member?.company?.[0]?.company_logo}`.replace(
                            "/thumb/",
                            "/"
                          )}
                          onError={(e) => {
                            e.target.src = "/logo.png";
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mt-5">
                    <div className="d-flex justify-content-between align-items-center">
                      <h3 className="profile-name text-theme ms-4 mt-5">
                        {member?.company?.[0]?.company_name}
                      </h3>

                      <div
                        className="position-relative d-flex justify-content-evenly align-items-center bg-light rounded-3 p-2 border border-secondary mt-5"
                        style={{ width: "300px", height: "50px" }}
                      >
                        <Link to={member?.company?.[0]?.facebook_links || "https://www.facebook.com/login"}   target="_blank">
                        
                          <i className="fab fa-facebook fa-lg fs-5 color-black"></i>
                        </Link>
                        

                      <Link
                          to= {member?.company?.[0]?.linkedin_links || "https://www.linkedin.com/login"}
                            target="_blank"
                        className="text-decoration-none me-2 fs-5 color-black"
                      >
                        <i className="fab fa-linkedin fa-lg"></i>
                      </Link>

                      <Link
                         to= {member?.company?.[0]?.instagram_links || "https://www.instagram.com/login"}
                           target="_blank"
                        className="text-decoration-none me-2 fs-5 color-black "
                      >
                        <i className="fab fa-instagram fa-lg"></i>
                      </Link>

                      <Link
                        to= {member?.company?.[0]?.twitter_links || "https://www.twitter.com/login"}
                        target="_blank"
                        className="text-decoration-none me-5 fs-5 color-black "
                      >
                        <i className="fab fa-twitter fa-lg"></i>
                      </Link>


                     
                    </div>
                  </div>

                  <p className="profile-title text-muted ms-4">
                    <i className="fas fa-globe me-2"></i>
                    <a
                      href={`${member?.company?.[0]?.website?.startsWith("http")
                          ? ""
                          : "https://"
                        }${member?.company?.[0]?.website}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-decoration-none me-2"
                    >
                      {member?.company?.[0]?.website}
                    </a>
                  </p>
                </div>
                </div>

                <div className="form-row mt-5">
                  <div className="form-group col-md-12">
                    <ul className="nav nav-tabs d-flex flex-row flex-wrap justify-content-start">
                      <li
                        className="i.border-member{
tem"
                      >
                        <button
                          className={`nav-link button-collapse custom-button ${
                            activeTab === "about" ? "active" : ""
                          }`}
                          onClick={() => handleTabClick("about")}
                        >
                          About
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>

                {/* Photo Tab */}
                {activeTab === "photo" && (
                  <div className="container mt-3 mb-5">
                    <div class="d-flex flex-row justify-content-between"></div>

                    <Gallary />
                  </div>
                )}
                {activeTab === "timeline" && (
                  <div className="container mt-3 mb-5 ">
                    <Timelines />
                  </div>
                )}

                {/* About Tab */}
                {activeTab === "about" && (
                  <div className=" row d-flex justify-content-between">
                    <div className="form-row col-12  col-md-12 col-lg-6 col-xl-6 col-xxl-6 z-14 child">
                      <div className="intro w-100">
                        <section class="container container1 my-5">
                          <div class="row align-items-center">
                            <div class="container my-2 ">
                              <div class="col-md-12 d-flex align-items-center justify-content-between mb-4">
                                <div class="d-flex align-items-center">
                                  <i class="fas fa-globe me-1 ms-2"></i>
                                  <h5 class="fw-bold text-theme mb-0">Intro</h5>
                                </div>
                                {/* <button
                                  class="btn btn-outline-secondary bg-theme-color btn-sm"
                                  onClick={() => setcompanyIntro(true)}
                                >
                                  <i class="fas fa-edit"></i> Edit
                                </button> */}
                              </div>
                              <div class="row justify-content-center text-center">
                                <div>
                                  <p class="lead text-muted fs-6 mb-4">
                                    {member?.company?.[0]?.bio}
                                  </p>
                                  <hr />
                                  <div class="d-flex justify-content-start">
                                    <ul class="list-unstyled text-start f">
                                      {/* <li class="mb-2 fs-6">
                                        <i class="fas fa-map-marker-alt me-2 text-theme"></i>
                                        {member?.company?.[0]?.address}
                                      </li> */}

                                      <li className="mb-2 fs-6">
                                        Membership Start Date:{" "}
                                        {member?.company?.[0]
                                          ?.membership_start &&
                                          (() => {
                                            const date = new Date(
                                              member.company[0]
                                                .membership_start * 1000
                                            );
                                            const year = date.getUTCFullYear();
                                            const month = String(
                                              date.getUTCMonth() + 1
                                            ).padStart(2, "0");
                                            const day = String(
                                              date.getUTCDate()
                                            ).padStart(2, "0");
                                            return `${day}-${month}-${year}`;
                                          })()}
                                      </li>

                                      <li className="mb-2 fs-6">
                                        Membership End Date:{" "}
                                        {member?.company?.[0]
                                          ?.membership_expire &&
                                          (() => {
                                            const date = new Date(
                                              member.company[0]
                                                .membership_expire * 1000
                                            );
                                            const year = date.getUTCFullYear();
                                            const month = String(
                                              date.getUTCMonth() + 1
                                            ).padStart(2, "0");
                                            const day = String(
                                              date.getUTCDate()
                                            ).padStart(2, "0");
                                            return `${day}-${month}-${year}`;
                                          })()}
                                      </li>

                                      {member?.members.map((member) => {
                                        if (member.id == userId) {
                                          return (
                                            <li
                                              className="mb-2 fs-6"
                                              key={member.id}
                                            >
                                              Membership Since:{" "}
                                              {member?.membership_since &&
                                                (() => {
                                                  const date = new Date(
                                                    member.membership_since *
                                                      1000
                                                  );
                                                  const year =
                                                    date.getUTCFullYear();
                                                  const month = String(
                                                    date.getUTCMonth() + 1
                                                  ).padStart(2, "0");
                                                  const day = String(
                                                    date.getUTCDate()
                                                  ).padStart(2, "0");
                                                  return `${day}-${month}-${year}`;
                                                })()}
                                            </li>
                                          );
                                        }
                                        return null;
                                      })}

                                      <li className="mb-2 fs-6">
                                        Foundation Date:{" "}
                                        {member?.company?.[0]
                                          ?.foundation_date &&
                                          (() => {
                                            const date = new Date(
                                              member.company[0]
                                                .foundation_date * 1000
                                            );
                                            const year = date.getUTCFullYear();
                                            const month = String(
                                              date.getUTCMonth() + 1
                                            ).padStart(2, "0");
                                            const day = String(
                                              date.getUTCDate()
                                            ).padStart(2, "0");
                                            return `${day}-${month}-${year}`;
                                          })()}
                                      </li>

                                      <li className="mb-2 fs-6">
                                        Chamber of Commerce Number:{" "}
                                        {member?.company?.[0]?.company_chamber}
                                      </li>
                                      <li className="mb-2 fs-6">
                                        Tax Registration Number:{" "}
                                        {member?.company?.[0]?.tax_registration}
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                      <div className="container  mb-3 container1 w-100">
                        <div classname="mt-3">
                          <h4 classname="text-theme mt-5">Media</h4>
                        </div>
                        <div className="row mb-4 mt-3">
                          <Gallary
                            company_id={id}
                            user_id={member?.members?.[0].id}
                          />
                        </div>
                      </div>

                      <div className="profile-tab-item-section profile-tab-item-section-about bg-white w-100">
                        <div className="container1  custom-border-radius mb-3">
                          <h5 className="text-theme mt-2 ms-2 mt-5">
                            Branches & offices
                          </h5>
                          <div className="main-blue f16 weight600 vmiddle text-md black ml-3 d-flex  justify-content-evenly mt-4">
                            <h5 className="mt-2">
                              {member?.company_country} {Location.length}{" "}
                              offices
                            </h5>
                          </div>

                          <div className="d-flex flex-wrap mb-5 ms-2">
                            {branch.map((location, index) => (
                              <button
                                key={index}
                                className={`btn btn-primary btn-sm bg-theme me-3 mt-3 ${
                                  selectedLocation === index ? "active" : ""
                                }`}
                                onClick={() => handleButtonClick(index)}
                              >
                                {location.city}
                              </button>
                            ))}
                          </div>
                          {Location.length > 0 && (
                            <div className="container  mt-5 mb-3">
                              <div className="row">
                                <div className="col-12">
                                  <div className="contact-info bg-white p-3">
                                    <p>
                                      <strong className="text-theme">
                                        Address:
                                      </strong>{" "}
                                      {Location[selectedLocation].address}
                                    </p>
                                    <p>
                                      <strong className="text-theme">
                                        House Number:
                                      </strong>{" "}
                                      {Location[selectedLocation]?.house_number}
                                    </p>
                                    <p>
                                      <strong className="text-theme">
                                        Zip code:
                                      </strong>{" "}
                                      {Location[selectedLocation]?.pin_code}
                                    </p>
                                    <p>
                                      <strong className="text-theme">
                                        City:
                                      </strong>{" "}
                                      {Location[selectedLocation]?.city}
                                    </p>
                                    <p>
                                      <strong className="text-theme">
                                        Phone Number:
                                      </strong>{" "}
                                      {Location[selectedLocation]?.country_code
                                        ?.replace(/^\d+:/, "")
                                        .replace(
                                          /([\uD83C][\uDDE6-\uDDFF][\uD83C][\uDDE6-\uDDFF])/,
                                          "$1+"
                                        )}{" "}
                                      {Location[selectedLocation].contact}
                                    </p>

                                    <p>
                                      <strong className="text-theme">
                                        Email:
                                      </strong>{" "}
                                      {Location[selectedLocation]?.email}
                                    </p>
                                  </div>
                                </div>

                                {/* <div className="col-12">
                                 <div className="map mt-3">
                                   <iframe
                                     src={locations[selectedLocation].mapSrc}
                                     width="100%"
                                     height="250"
                                     style={{ border: 0 }}
                                     allowFullScreen
                                     loading="lazy"
                                   ></iframe>
                                 </div>
                               </div> */}
                              </div>
                            </div>
                          )}
                        </div>

                        <div className="container1 custom-border-radius mb-3">
                          <div className="col-12 ">
                            <h5 className="text-theme mt-2 ms-2 mt-5">
                              Contacts
                            </h5>

                            <div className="container-fluid">
                              <div className="row">
                                {member?.members.map((member) => (
                                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6">
                                    <div className="our-team mt-5">
                                      <div className="position-relative">
                                        <div className="team-card">
                                          <div className="picture">
                                            <img
                                              className="img-fluid"
                                              src={`${member.member_logo}`}
                                              alt="Profile"
                                              onError={(e) => {
                                                e.target.src = "/dumy.jpeg";
                                              }}
                                            />
                                          </div>

                                          <div className="team-content d-flex flex-column align-items-center text-center">
                                            <h6 className="name mb-3">
                                              {member.salutation &&
                                                `${member.salutation} `}
                                              {member.first_name}{" "}
                                              {member.middle_name}{" "}
                                              {member.last_name}
                                            </h6>

                                            <p className="mb-3">
                                              {member.job_title}
                                            </p>

                                            {/* Toggle button for expanding/collapsing */}
                                            <button
                                              className="btn btn-primary btn-sm bg-theme mb-4"
                                              onClick={() =>
                                                handleToggleDetails(member.id)
                                              }
                                            >
                                              {isDetailsVisible(member.id)
                                                ? "Less"
                                                : "More"}
                                            </button>

                                            {/* Collapsible content */}
                                            <div
                                              className={`collapsible-content ${
                                                isDetailsVisible(member.id)
                                                  ? "show"
                                                  : ""
                                              }`}
                                            >
                                              <p>
                                                <strong>Department:</strong>{" "}
                                                {member.department}
                                              </p>
                                              {member.location && (
                                                <p>
                                                  <strong>location:</strong>{" "}
                                                  {member.location}
                                                </p>
                                              )}
                                              {member.email && (
                                                <p>
                                                  <i className="fas fa-envelope text-theme"></i>{" "}
                                                  {member.email}
                                                </p>
                                              )}

                                              {member.contact_direct && (
                                                <p>
                                                  {/* <strong>Direct Phone Number:</strong>{" "} */}
                                                  <i className="fas fa-phone text-theme"></i>{" "}
                                                  {member.country_code_direct
                                                    ?.replace(/^\d+:/, "")
                                                    .replace(
                                                      /([\uD83C][\uDDE6-\uDDFF][\uD83C][\uDDE6-\uDDFF])/,
                                                      "$1+"
                                                    )}
                                                  {member.pin_code_direct &&
                                                    ` (${member.pin_code_direct})`}{" "}
                                                  {member.contact_direct}
                                                </p>
                                              )}
                                              {member.contact && (
                                                <p>
                                                  {/* <strong>Phone Number:</strong>{" "} */}
                                                  <i class="fas fa-mobile-alt"></i>{" "}
                                                  {member.country_code
                                                    ?.replace(/^\d+:/, "")
                                                    .replace(
                                                      /([\uD83C][\uDDE6-\uDDFF][\uD83C][\uDDE6-\uDDFF])/,
                                                      "$1+"
                                                    ) +
                                                    " " +
                                                    member.contact}
                                                </p>
                                              )}
                                            </div>
                                          </div>

                                          <ul className="social">
                                            <li>
                                              <a
                                                href={"mailto:" + member.email}
                                                className="fas fa-envelope"
                                                aria-hidden="true"
                                              ></a>
                                            </li>
                                            {/* <li>
                                              <a
                                                href={
                                                  "tel:" +
                                                  member.country_code +
                                                  member.contact
                                                }
                                                className="fas fa-address-book"
                                                aria-hidden="true"
                                              ></a>
                                            </li> */}
                                            <li>
                                              <a
                                                href={
                                                  "https://wa.me/" +
                                                  member.contact
                                                }
                                                className="fab fa-whatsapp"
                                                aria-hidden="true"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                              ></a>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* <div className="mt-5 mb-3 position-relative">
                          <h4 className="mb-4">Services</h4>
                          <div className="container">
                            <div className="row">
                              {Array.isArray(Services.basic_services) &&
                                Services.basic_services.length > 0 ? (
                                Services.basic_services.map((service) => (
                                  <div
                                    className="col-md-6  col-md-6 col-xl-3 col-xxl-3 col-sm-12 col-sm-12 mb-3 ms-4"
                                    key={service.id}
                                  >
                                    <div className="service-box d-flex align-items-center">
                                      <span className="dot bg-primary me-2"></span>
                                      <p className="mb-0">{service.name}</p>
                                    </div>
                                  </div>
                                ))
                              ) : (
                                <div className="col-12 text-center">
                                  <p>Services not available!!!</p>
                                </div>
                              )}
                            </div>
                          </div>

                          <h4 className="mb-4 mt-5">Special Services</h4>
                          <div className="container">
                            <div className="row">
                              {Array.isArray(Services.special_services) &&
                                Services.special_services.length > 0 ? (
                                Services.special_services.map((service) => (
                                  <div
                                    className="col-md-6  col-md-6 col-xl-3 col-xxl-3 col-sm-12 mb-3 ms-4"
                                    key={service.id}
                                  >
                                    <div className="service-box d-flex align-items-center">
                                      <span className="dot bg-primary me-2"></span>
                                      <p className="mb-0">{service.name}</p>
                                    </div>
                                  </div>
                                ))
                              ) : (
                                <div className="col-12 text-center">
                                  <p>Special services not available!!!</p>
                                </div>
                              )}
                            </div>
                          </div>
                        </div> */}
                        <div className="mt-5 mb-3 position-relative">
                          <h4 className="mb-4">Services</h4>
                          <div className="container">
                            <div className="row">
                              {Array.isArray(Services.basic_services) &&
                                Services.basic_services.map((service) => (
                                  <div
                                    className="col-md-6 col-xl-3 col-xxl-3 col-sm-12 mb-3 ms-4"
                                    key={service.id}
                                  >
                                    <div className="service-box d-flex align-items-center">
                                      {service.icon ? (
                                        <img
                                          src={service.icon}
                                          alt="Service Logo"
                                          className="service-logo me-2"
                                        />
                                      ) : (
                                        <span className="dot bg-primary me-2"></span>
                                      )}
                                      <p className="mb-0">{service.name}</p>
                                    </div>
                                  </div>
                                ))}
                            </div>
                          </div>

                          <h4 className="mb-4 mt-5">Special Services</h4>
                          <div className="container">
                            <div className="row">
                              {Array.isArray(Services.special_services) &&
                                Services.special_services.map((service) => (
                                  <div
                                    className="col-md-6 col-xl-3 col-xxl-3 col-sm-12 mb-3 ms-4"
                                    key={service.id}
                                  >
                                    <div className="service-box d-flex align-items-center">
                                      {service.icon_url ? (
                                        <img
                                          src={service.icon_url}
                                          alt="Service Logo"
                                          className="service-logo me-2"
                                        />
                                      ) : (
                                        <span className="dot bg-primary me-2"></span>
                                      )}
                                      <p className="mb-0">{service.name}</p>
                                    </div>
                                  </div>
                                ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-row  col-12  col-md-12 col-lg-6 col-xl-6 col-xxl-6 child ">
                      <Timelines userid={id} />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* Video Tab */}
          {activeTab === "video" && <p>video</p>}
        </div>
      )}
    </>
  );
}

export default MemberDetails;
