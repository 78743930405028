import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { countrycode, CityBranch } from "../apis/api/DataApi";
import { Profileget, updateMemberDetails } from "../apis/api/ProfileApi";
import FileInput from "../hooks/FileInput";
import Cookies from "js-cookie";
import Select from "react-select";
import Modal from "react-bootstrap/Modal";
import Loading from "../hooks/Loading";
import { toast } from "react-toastify";
import { Controller } from "react-hook-form";
import useFormValidation from "../hooks/useFormValidation";

const EditDetailsofMember = (props) => {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    watch,
    formState: { errors },
  } = useForm();

  const [imageUrl, setImageUrl] = useState(null);
  const [userId, setUserId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [file, setFile] = useState(null);
  const [member_type, setMember_type] = useState(null);
  const [memberid, setMemberid] = useState(null);
  const LoginuserID = Cookies.get("userId");
 
  useEffect(() => {
    dispatch(countrycode());
  }, []);

  useEffect(() => {
    fetchMemberProfile();
    // dispatch(CityBranch({member_id:userId}));
  }, [props]);

  const fetchMemberProfile = async () => {
    const { member } = props;

    if (member) {
     
      setMember_type(member.member_type);
      setMemberid(member.id);
      setValue("FirstName", member.first_name);
      setValue("MiddleName", member.middle_name);
      setValue("LastName", member.last_name);
      setValue("Location", member.location);
      setValue("Title", { value: member.salutation, label: member.salutation });
      setValue("phone_no_direct", member.contact_direct);
      setValue("areacode", member.pin_code_direct);
      setValue("JobTitle", member.job_title);
      setValue("Extension", member.extension);
      setValue("Extension", member.extension);
      setValue("Department", {
        value: member.department,
        label: member.department,
      });
      setValue("Mobile", member.contact);
      setValue("email", member.email);

      setValue("country_code_direct", {
        value: member.country_code_direct,
        label: member.country_code_direct?.split(":")[1]?.trim(),
      });

      setValue("country_code", {
        value: member.country_code,
        label: member.country_code?.split(":")[1]?.trim(),
      });
      setValue("email", member.email);
      setImageUrl(member.member_logo);
      setUserId(member.id);
      setLoading(false);
    }
  };

  const { country_code, citybranch } = useSelector((state) => state.dataSlice);
  const countryCodeOptions = country_code.data;
  const city_branch = citybranch?.data || [];

  const titleOptions = [
    { value: "Mr", label: "Mr" },
    { value: "Mrs", label: "Mrs" },
    { value: "Ms", label: "Ms" },
  ];

  const departmentOptions = [
    { value: "Management", label: "Management" },
    { value: "Sales", label: "Sales" },
    { value: "Operations", label: "Operations" },
    { value: "Financial", label: "Financial" },
  ];

  const handleFileChange = (event) => {
    const selectedFile = event.target.value;
    setFile(selectedFile);
  };

  const onSubmitHandler = async (formData) => {
    setLoading(true);
    const data = new FormData();
    const company_id = Cookies.get("company_id");

    // Append form data to FormData object
    // Object.keys(formData).forEach((key) => {
    //   if (typeof formData[key] === "object" && formData[key] !== null) {
    //     Object.keys(formData[key]).forEach((subKey) => {
    //       data.append(`${key}[${subKey}]`, formData[key][subKey]);
    //     });
    //   } else {
    //     data.append(key, formData[key]);
    //   }
    // });

    Object.keys(formData).forEach((key) => {
      if (
        typeof formData[key] === "object" &&
        formData[key] !== null &&
        "value" in formData[key]
      ) {
        // If it's an object with a "value" property, append only that value
        data.append(key, formData[key].value);
      } else {
        // Otherwise, append it as-is
        data.append(key, formData[key]);
      }
    });

    // if (formData["key[pincode]"]) {
    //   data.append("[pincode]", formData["key[pincode]"]);
    // }
    // const locationValue = getValues("Location"); // Get the actual value of Location
    // data.append("Location", locationValue);
    data.append("userId", userId);
    data.append("company_id", company_id);
    data.append("file", file);

    const response = await dispatch(updateMemberDetails(data));

    // Handle response
    if (response.payload.status === "success") {
      await dispatch(Profileget({ company_id: company_id, member_id: userId }));
      setLoading(false);
      props.onHide();
      toast.success("Profile upload successful!");
    } else {
      setLoading(false);
      props.onHide();
      toast.error("Profile upload failed, please try again!");
    }
  };

  const selectedTitle = watch("Title");
  const selectedDepartment = watch("Department");
  const selectedCountryCode = watch("country_code");
  const selectedCountryCodeDirect = watch("country_code_direct");
  const { handleChange } = useFormValidation({});
  const handleLocationChange = (selectedOption, label) => {
    handleChange({
      target: { name: label, value: selectedOption.value },
    });
  };
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={props.onHide}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Contact</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Loading loading={loading} />
          <form
            onSubmit={handleSubmit(onSubmitHandler)}
            className="form-container"
            encType="multipart/form-data"
          >
            <div className="row">
              <div className="col-md-12">
                <FileInput
                  name="file"
                  label="Profile Picture"
                  handleChange={handleFileChange}
                  defaultUrl={imageUrl}
                />
              </div>
              <div className="col-md-12">
                <label>Title</label>
                <Select
                  options={titleOptions}
                  value={selectedTitle}
                  onChange={(selectedOption) =>
                    setValue("Title", selectedOption)
                  }
                />
                {errors.Title && (
                  <span className="text-danger">{errors.Title.message}</span>
                )}
              </div>

              <div className="col-md-4">
                <label>First Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="First Name"
                  {...register("FirstName", { required: "Name is required" })}
                />
                {errors.Name && (
                  <span className="text-danger">{errors.Name.message}</span>
                )}
              </div>
              <div className="col-md-4">
                <label>Middle Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Middle Name"
                  {...register("MiddleName")}
                />
              </div>
              <div className="col-md-4">
                <label>LastName</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Last Name"
                  {...register("LastName", { required: "Name is required" })}
                />
                {errors.Name && (
                  <span className="text-danger">{errors.Name.message}</span>
                )}
              </div>
              <div>
                {LoginuserID!= memberid && member_type == 0 && (
                  <div className="col-md-12">
                    <div className="form-group">
                      <label className="form-label">
                        Location <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="Location"
                        control={control}
                        rules={{ required: "Location is required" }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={city_branch}
                            value={
                              city_branch.find(
                                (option) => option.value === field.value
                              ) || null
                            }
                            onChange={(selectedOption) => {
                              if (selectedOption) {
                                field.onChange(selectedOption.value);
                                handleLocationChange(selectedOption);
                              } else {
                                field.onChange(null);
                              }
                            }}
                          />
                        )}
                      />
                      {errors.Location && (
                        <span className="text-danger">
                          {errors.Location.message}
                        </span>
                      )}
                    </div>
                  </div>
                )}
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label className="form-label">
                    Job Title <span class="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control border border-grey border-1 rounded-1  Removefocus"
                    placeholder="Job Title"
                    {...register("JobTitle", {
                      required: "Job Title is required",
                    })}
                  />
                  {errors.JobTitle && (
                    <span className="text-danger">
                      {errors.JobTitle.message}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <label>Department</label>
                <Select
                  options={departmentOptions}
                  value={selectedDepartment}
                  onChange={(selectedOption) =>
                    setValue("Department", selectedOption)
                  }
                />

                {errors.Department && (
                  <span className="text-danger">
                    {errors.Department.message}
                  </span>
                )}
              </div>

              <div className="col-md-12">
                <label>Phone Number Direct</label>
                <div className="d-flex">
                  <Select
                    className="w-100"
                    options={countryCodeOptions}
                    value={selectedCountryCodeDirect}
                    onChange={(selectedOption) =>
                      setValue("country_code_direct", selectedOption)
                    }
                  />
                  <input
                    type="number"
                    className="form-control border border-grey border-1 rounded-1 me-2"
                    placeholder="Area code"
                    name="areacode"
                    {...register("areacode")}
                  />

                  <input
                    type="text"
                    className="form-control"
                    placeholder="Number"
                    {...register("phone_no_direct")}
                  />
                </div>
                {errors.phone_no_direct && (
                  <span className="text-danger">
                    {errors.phone_no_direct.message}
                  </span>
                )}
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label className="form-label">Extension</label>
                  <input
                    type="text"
                    className="form-control border border-grey border-1 rounded-1  Removefocus"
                    placeholder="Extension"
                    {...register("Extension")}
                  />
                  {errors.Extension && (
                    <div className="text-danger">
                      {errors.Extension.message}
                    </div>
                  )}
                </div>
              </div>

              <div className="col-md-12">
                <label>Phone Number Mobile</label>
                <div className="d-flex">
                  <Select
                    className="w-100"
                    options={countryCodeOptions}
                    value={selectedCountryCode}
                    onChange={(selectedOption) =>
                      setValue("country_code", selectedOption)
                    }
                  />

                  <input
                    type="text"
                    className="form-control"
                    placeholder="Number"
                    {...register("Mobile", { required: "Contact is required" })}
                  />
                </div>
                {errors.Mobile && (
                  <span className="text-danger">{errors.Mobile.message}</span>
                )}
              </div>

              <div className="col-md-12">
                <label>Email</label>
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email"
                  {...register("email", { required: "Email is required" })}
                />
                {errors.email && (
                  <span className="text-danger">{errors.email.message}</span>
                )}
              </div>

              <div className="col-md-12">
                <button type="submit" className="btn btn-primary mt-3">
                  Submit
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditDetailsofMember;
