import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';

const ProtectedOtp = () => {
  // const userId = localStorage.getItem('userId');
  const otpId = Cookies.get('otpId');
  
  const location = useLocation();

  if(otpId && location.pathname === '/'){
    return <Navigate to="/otp" />;
  }

  return otpId ? <Outlet /> : <Navigate to="/" />;
};

export default ProtectedOtp;