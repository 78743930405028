import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode'; 
import { toast } from "react-toastify";

const isTokenExpired = (token) => {
  if (!token) return true; 
  try {
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000; 
    return decodedToken.exp < currentTime; 
  } catch (error) {
    return true; 
  }
};

const clearAllCookies = () => {
  const allCookies = Cookies.get(); 
  Object.keys(allCookies).forEach(cookie => {
    Cookies.remove(cookie);
  });
  
};

const ProtectedRoute = () => {
  const token = Cookies.get('token'); 
  const userId = Cookies.get('userId'); 
  const location = useLocation();

  

 
  if (!token || isTokenExpired(token)) {
    Cookies.remove('token'); 
    Cookies.remove('userId'); 
    clearAllCookies();
    toast.error("Session Expired!");
    return <Navigate to="/" />; 
  }

 
  if (userId && location.pathname === '/') {
    return <Navigate to="/Home" />;
  }

  return userId ? <Outlet /> : <Navigate to="/" />;
};

export default ProtectedRoute;
