import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { reset } from '../apis/api/WizardApi';
import Cookies from "js-cookie";
import { toast } from "react-toastify";

const FirstResetPassword = () => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordMatchError, setPasswordMatchError] = useState("");
  const [passwordStrength, setPasswordStrength] = useState(""); // Password strength state
  const dispatch = useDispatch();
  const navigate = useNavigate();



  const clearAllCookies = () => {
    const allCookies = Cookies.get();
    Object.keys(allCookies).forEach((cookie) => {
      Cookies.remove(cookie);
    });
  };
  const checkPasswordStrength = (password) => {
    if (password.length < 6 ) {
      setPasswordStrength("Invalid - Password must be greater than 6 characters.");
    } else if (!/[A-Z]/.test(password)) {
      setPasswordStrength("Weak - Add an uppercase letter.");
    } else if (!/[a-z]/.test(password)) {
      setPasswordStrength("Weak - Add a lowercase letter.");
    } else if (!/[0-9]/.test(password)) {
      setPasswordStrength("Weak - Add a number.");
    } else if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
      setPasswordStrength("Weak - Add a special character.");
    } else {
      setPasswordStrength("Strong - Your password meets all requirements.");
    }
  };
  

  const handleNewPasswordChange = (e) => {
    const value = e.target.value;
    setNewPassword(value);
    checkPasswordStrength(value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const company_id = Cookies.get("company_id");
    const userId = Cookies.get("userId");

    // Password match validation
    if (newPassword !== confirmPassword) {
      setPasswordMatchError("New password and confirm password are not same .");
      return;
    }

    // Reset any previous error
    setPasswordMatchError("");

    const formData = new FormData();
    formData.append("currentPassword", currentPassword);
    formData.append("newPassword", newPassword);
    formData.append("confirmPassword", confirmPassword);
    formData.append("company_id", company_id);
    formData.append("userId", userId);

    try {
    const response = await dispatch(reset(formData));
  
    if (response.payload.status === "success"){
        clearAllCookies();
        navigate("/");
        toast.success("password Reset Successfull!");
    }
    else if(response.payload.status === "error"){
      navigate("/first_reset_password");
      toast.error(response.payload.msg);
    }
  } catch (error) {
    toast.error("Invalid credentials!");
    navigate("/first_reset_password");
    console.error(error);
  }
  
  };

  return (
    <div className="mainDiv" style={{ backgroundColor: "#FAFBFC" }}>
      <div className="cardStyle">
      <div className="logo-container" style={{ textAlign: "center", marginBottom: "20px" }}>
          <img src="/FLlogo.png" alt="Logo" style={{ width: "200px", height: "auto" }} />
        </div>

        <form id="signupForm" onSubmit={handleSubmit}>
          <h5 className="formTitle">Reset your account password</h5>

         
          <div className="inputDiv">
            <label className="inputLabel" htmlFor="currentPassword">
              Current Password
            </label>
            <div className="inputWithIcon">
              <input
                type={showCurrentPassword ? "text" : "password"}
                id="currentPassword"
                name="currentPassword"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                required
                placeholder="Enter current password"
              />
              <button
                type="button"
                onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                className="eyeButton"
              >
                {showCurrentPassword ? "👁️" : "👁️‍🗨️"}
              </button>
            </div>
          </div>

      
          <div className="inputDiv">
            <label className="inputLabel" htmlFor="newPassword">
              New Password
            </label>
            <div className="inputWithIcon">
              <input
                type={showNewPassword ? "text" : "password"}
                id="newPassword"
                name="newPassword"
                required
                placeholder="Enter new password"
                value={newPassword}
                onChange={handleNewPasswordChange}
              />
              <button
                type="button"
                onClick={() => setShowNewPassword(!showNewPassword)}
                className="eyeButton"
              >
                {showNewPassword ? "👁️" : "👁️‍🗨️"}
              </button>
            </div>
            {/* Display password strength */}
            <div className="password-strength" style={{
              color: passwordStrength.includes("Strong") ? "green" : "red"
            }}>
              {passwordStrength}
            </div>
          </div>

          {/* Confirm Password Field */}
          <div className="inputDiv">
            <label className="inputLabel" htmlFor="confirmPassword">
              Confirm Password
            </label>
            <div className="inputWithIcon">
              <input
                type={showConfirmPassword ? "text" : "password"}
                id="confirmPassword"
                name="confirmPassword"
                placeholder="Confirm new password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <button
                type="button"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                className="eyeButton"
              >
                {showConfirmPassword ? "👁️" : "👁️‍🗨️"}
              </button>
            </div>
          </div>

          {/* Password Match Error */}
          {passwordMatchError && (
            <div className="error-message" style={{ color: "red" }}>
              {passwordMatchError}
            </div>
          )}

          {/* Submit Button */}
          <div className="buttonWrapper">
            <button
              type="submit"
              id="submitButton"
              className="submitButton pure-button pure-button-primary"
            >
              <span>Submit</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FirstResetPassword;
