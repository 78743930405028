// ProtectedMemberRoute.js
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { Navigate, Outlet } from "react-router-dom";

const ProtectedMemberRoute = ({ member_status }) => {
  const navigate = useNavigate();
  const clearAllCookies = () => {
    const allCookies = Cookies.get();
    Object.keys(allCookies).forEach((cookie) => {
      Cookies.remove(cookie);
    });
  };

 
  if(member_status == undefined){
    clearAllCookies();
    navigate("/");
  }
  return member_status == 1 ? <Outlet /> : <Navigate to="/" />;
};

export default ProtectedMemberRoute;