import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL, NODE_URL } from "./Config";

export const WizardInsert = createAsyncThunk(
    "post/WizardInsert",
    async (data) => {
        let url = NODE_URL+"WizardInsert";
        try {
            const response = (await axios.post(url, data)).data;
            if (response.status == "success") {
                return Promise.resolve(response);
            }else if(response.status == "error") {
                return Promise.resolve(response);
            } else {
                return Promise.reject(response);
            }
        } catch (error) {
            return Promise.reject(error);
        }
    }
);

export const reset = createAsyncThunk(
    "post/reset",
    async (data) => {
        let url = NODE_URL+"resetPassword";
        try {
            const response = (await axios.post(url, data)).data;
            if (response.status == "success") {
                return Promise.resolve(response);
            }else if(response.status == "error") {
                return Promise.resolve(response);
            } else {
                return Promise.reject(response);
            }
        } catch (error) {
            return Promise.reject(error);
        }
    }
);

export const Forget = createAsyncThunk(
    "post/Forget",
    async (data) => {
        let url = NODE_URL+"forgetPassword";
        try {
            const response = (await axios.post(url, data)).data;
            if (response.status == "success") {
                return Promise.resolve(response);
            }else if(response.status == "error") {
                return Promise.resolve(response);
            } else {
                return Promise.reject(response);
            }
        } catch (error) {
            return Promise.reject(error);
        }
    }
);