import { createSlice } from "@reduxjs/toolkit";
import { galleryInsert ,
  galleryGet,
  PhotoInsert,
  VideoInsert,
  MediaGet,
  PhotoDelete ,
  VideoDelete,
  galleryEdit,
  galleryDelete} from "../api/GalleryApi";

const gallerySlice = createSlice({
    name: "gallerySlice",
    initialState: {
      loading: false,
      gallery: null,
      media: null,
      photo_insert: null,
      video_insert: null,

    },
    reducers:{
        // signup_details :(state, action)=>{
        // state.signup_detail="";
        // },

        // Login_detail :(state, action)=>{
        //   state.Login_detail="";
      
        //   }

    },

    extraReducers: (builder) => {

        // gallery insert slice
      builder.addCase(galleryInsert.pending, state => {
        state.loading = true
      })
      builder.addCase(galleryInsert.fulfilled,  (state, action) => {
        state.loading = false
       
      })
      builder.addCase(galleryInsert.rejected, (state,action) => {
        state.loading = false
      })

           // galleryEdit slice
           builder.addCase(galleryEdit.pending, state => {
            state.loading = true
          })
          builder.addCase(galleryEdit.fulfilled,  (state, action) => {
            state.loading = false
           
          })
          builder.addCase(galleryEdit.rejected, (state,action) => {
            state.loading = false
          })
       // galleryDelete slice
       builder.addCase(galleryDelete.pending, state => {
        state.loading = true
      })
      builder.addCase(galleryDelete.fulfilled,  (state, action) => {
        state.loading = false
       
      })
      builder.addCase(galleryDelete.rejected, (state,action) => {
        state.loading = false
      })    

       // gallery get slice
       builder.addCase(galleryGet.pending, state => {
        state.loading = true
      })
      builder.addCase(galleryGet.fulfilled,  (state, action) => {
        state.loading = false
        state.gallery=action.payload;
      
      })
      builder.addCase(galleryGet.rejected, (state,action) => {
        state.loading = false
      
        
      })

      // media get slice
      builder.addCase(MediaGet.pending, state => {
        state.loading = true
      })
      builder.addCase(MediaGet.fulfilled,  (state, action) => {
        state.loading = false
        state.media=action.payload;
     
      
      })
      builder.addCase(MediaGet.rejected, (state,action) => {
        state.loading = false
      
        
      })

      // photo insert slice
      builder.addCase(PhotoInsert.pending, state => {
        state.loading = true
      })
      builder.addCase(PhotoInsert.fulfilled,  (state, action) => {
        state.loading = false
        state.photo_insert=action.payload;
      
      })
      builder.addCase(PhotoInsert.rejected, (state,action) => {
        state.loading = false
      })

      // photo delete slice
      builder.addCase(PhotoDelete.pending, state => {
        state.loading = true
      })
      builder.addCase(PhotoDelete.fulfilled,  (state, action) => {
        state.loading = false
      
      })
      builder.addCase(PhotoDelete.rejected, (state,action) => {
        state.loading = false
      })

       // video insert slice
       builder.addCase(VideoInsert.pending, state => {
        state.loading = true
      })
      builder.addCase(VideoInsert.fulfilled,  (state, action) => {
        state.loading = false
        state.video_insert=action.payload;
      
      })
      builder.addCase(VideoInsert.rejected, (state,action) => {
        state.loading = false
      })
      
      // video delete slice
      builder.addCase(VideoDelete.pending, state => {
        state.loading = true
      })
      builder.addCase(VideoDelete.fulfilled,  (state, action) => {
        state.loading = false
      
      })
      builder.addCase(VideoDelete.rejected, (state,action) => {
        state.loading = false
      })

   

    
    }
  })


  export default gallerySlice.reducer;