import React, { useState } from "react";
import { Link } from "react-router-dom";
import EditMemberDetails from "../forms/EditDetailsofMember";
import Addmember from "../forms/Addmember";
import { useDispatch, useSelector } from "react-redux";
import showConfirmation from "../hooks/showConfirmation";
import Cookies from "js-cookie";
import { MemberDelete, Profileget } from "../apis/api/ProfileApi";
import { toast } from "react-toastify";

const Team = () => {
  const [editMemberData, setEditMemberData] = useState(null);
  const [visibleMember, setVisibleMember] = useState(null);

  // Function to toggle visibility of details
  const handleToggleDetails = (id) => {
    setVisibleMember(visibleMember === id ? null : id);
  };

  // Check if the details for a specific member are visible
  const isDetailsVisible = (id) => visibleMember === id;
  const [editmember, seteditmember] = useState(false);
  const [addMemberModal, setAddMemberModal] = useState(false);
  const dispatch = useDispatch();
  const member_type = Cookies.get("member_type");
  const userId = Cookies.get("userId");
  const company_id = Cookies.get("company_id");

  const contact = JSON.parse(Cookies.get("userJSON"));
  const access = JSON.parse(Cookies.get("access"));

  let memberData = useSelector((state) => state.profileSlice.profile);
  memberData = memberData?.data?.members || [];


  const handleEditClick = (member) => {
    setEditMemberData(member);
    seteditmember(true);
  };

  const handleMemberDelete = async (memberId) => {
    try {
      const result = await showConfirmation(
        "Are you sure you want to delete this Member?"
      );
      if (result) {
        const response = await dispatch(MemberDelete(memberId));
        if (response.payload.status == "success") {
          const response = await dispatch(
            Profileget({ company_id: company_id, member_id: userId })
          );
          if (response.payload.status == "success") {
            toast.success("Member Delete Successfull!");
          }
        }
      }
    } catch (error) {
      console.error("error", error);
    }
  };

  return (
    <>
      {/* <EditMemberDetails show={editmember} onHide={() => { seteditmember(false); }} member={editMemberData} /> */}
      <EditMemberDetails
        show={editmember}
        onHide={() => {
          seteditmember(false);
        }}
        member={editMemberData}
      />
      <Addmember
        show={addMemberModal}
        onHide={() => {
          setAddMemberModal(false);
        }}
      />
      <div className="container-fluid ">
        <div className="container-fluid mt-4">
          <div className="row">
            <div className="col-12 d-flex justify-content-between align-items-center my-3">
              {contact.extra_contact == 1 && access.access == 1 && (
                <button
                  className="btn btn-primary bg-theme z-14"
                  onClick={() => {
                    setAddMemberModal(true);
                  }}
                >
                  <i className="fas fa-plus"></i> Add Contact
                </button>
              )}

              <Link to="/profile" className="btn btn-primary bg-theme z-14">
                <i className="fas fa-arrow-left"></i> Back
              </Link>
            </div>
          </div>

          <div className="row">
            {memberData.map((member) => (
              <div
                key={member.id}
                className="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-3 "
              >
                <div className="our-team mt-5">
                  <div className="position-relative">
                    <div className="team-card">
                      {(access.access == 1 || userId == member.id) && (
                        <>
                          <button
                            className="btn btn-secondary bg-theme btn-sm position-absolute top-0 end-0 m-0 edit-btn"
                            onClick={() => handleEditClick(member)}
                          >
                            <i className="fas fa-pen"></i>
                          </button>
                          {member.id != userId && (
                            <button
                              className="btn btn-danger btn-sm position-absolute top-0 start-0 m-0 delete-btn z-2"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleMemberDelete(member.id);
                              }}
                            >
                              <i className="fas fa-trash-alt"></i>
                            </button>
                          )}
                        </>
                      )}
                      <div className="picture">
                        <img
                          className="img-fluid"
                          src={`${member.member_logo}`}
                          alt="Profile"
                          onError={(e) => {
                            e.target.src = "/dumy.jpeg";
                          }}
                        />
                      </div>

                      <div className="team-content d-flex flex-column align-items-center text-center">
                        <h6 className="name mb-3">
                          {member.salutation && `${member.salutation} `}
                          {member.first_name} {member.middle_name}{" "}
                          {member.last_name}
                        </h6>

                        <p className="mb-3">{member.job_title}</p>

                        {/* Toggle button for expanding/collapsing */}
                        <button
                          className="btn btn-primary btn-sm bg-theme mb-4"
                          onClick={() => handleToggleDetails(member.id)}
                        >
                          {isDetailsVisible(member.id) ? "Less" : "More"}
                        </button>

                        {/* Collapsible content */}
                        <div
                          className={`collapsible-content ${isDetailsVisible(member.id) ? "show" : ""
                            }`}
                        >
                          <p>
                            <strong>Department:</strong> {member.department}
                          </p>
                          {member.location &&(
                          <p>
                            <strong>location:</strong> {member.location}
                          </p>
                          )}
                          {member.email && (
                            <p>
                              <i className="fas fa-envelope text-theme"></i>{" "}
                              {member.email}
                            </p>
                          )}



                          {member.contact_direct && (
                            <p>
                              {/* <strong>Direct Phone Number:</strong>{" "} */}
                              <i className="fas fa-phone text-theme"></i>{" "}
                              {member.country_code_direct?.replace(/^\d+:/, '')
                                .replace(/([\uD83C][\uDDE6-\uDDFF][\uD83C][\uDDE6-\uDDFF])/, '$1+')}
                              {member.pin_code_direct &&
                                ` (${member.pin_code_direct})`}{" "}
                              {member.contact_direct}
                            </p>
                          )}
                          {member.contact && (
                            <p>
                              {/* <strong>Phone Number:</strong>{" "} */}
                              <i class="fas fa-mobile-alt me-1"></i>
                              {member.country_code?.replace(/^\d+:/, '')
                                .replace(/([\uD83C][\uDDE6-\uDDFF][\uD83C][\uDDE6-\uDDFF])/, '$1+') +
                                " " +
                                member.contact}
                            </p>
                          )}
                        </div>
                      </div>

                      <ul className="social">
                        <li>
                          <a
                            href={"mailto:" + member.email}
                            className="fas fa-envelope"
                            aria-hidden="true"
                          ></a>
                        </li>
                        {/* <li>
                          <a
                            href={"tel:" + member.country_code + member.contact}
                            className="fas fa-address-book"
                            aria-hidden="true"
                          ></a>
                        </li> */}
                        <li>
                          <a
                            href={"https://wa.me/" + member.contact}
                            className="fab fa-whatsapp"
                            aria-hidden="true"
                            target="_blank"
                            rel="noopener noreferrer"
                          ></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Team;
