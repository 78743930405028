import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { toast } from "react-toastify";
import { updateImportExport } from "../apis/api/DashboardApi";
import { ImportExportAll } from "../apis/api/DashboardApi";
import Modal from "react-bootstrap/Modal";
import Loading from "../hooks/Loading";
import Cookies from "js-cookie";
import { Profileget } from "../apis/api/ProfileApi";

const ImportExport = ({ showModal, handleClose, data }) => {
  const dispatch = useDispatch();

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    
    dispatch(ImportExportAll());
  }, [dispatch]);

  const ImportExport_data = useSelector(
    (state) => state.dashboardSlice.ImportExportAll?.data || []
  );

  
  const ImportExportOptions = ImportExport_data.map((item) => ({
    label: item.import_export,
    value: item.import_export,
    id: item.id,
  }));

  const loading = useSelector((state) => state.profileSlice.loading);

  
  useEffect(() => {
    if (data) {
      const importData = (data.import_from || []).map((item) => ({
        label: item.name,
        value: Number(item.id),
      }));

      const exportData = (data.export_to || []).map((item) => ({
        label: item.name,
        value: Number(item.id),
      }));

      setValue("import_from", importData);
      setValue("export_to", exportData);
    } else {
      setValue("import_from", []);
      setValue("export_to", []);
    }
  }, [data, setValue]);

  const onSubmitHandler = async (formData) => {
    const companyId = Cookies.get("company_id");

   
    const importFrom = formData.import_from.map((service) => service.id).join(",");  
    const exportTo = formData.export_to.map((service) => service.id).join(",");  

   
    const formattedData = {
        Import_from: importFrom, 
        Exported_to: exportTo, 
        company_id: companyId
    };

  
   
    const response = await dispatch(updateImportExport(formattedData));

    
    const userId = Cookies.get("userId");

   
    if (response?.payload?.status === "success") {
        const profile = await dispatch(Profileget({ company_id: companyId, member_id: userId }));
        
        if (profile.payload.status === "success") {
            toast.success("Services Updated Successfully!");
            handleClose();
            reset();
        }
    } else {
        toast.error("Failed to Update Services.");
    }
};

  const CloseModel = () => {
    reset();
    handleClose();
  };

  return (
    <Modal
      show={showModal}
      onHide={CloseModel}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <span className="text-bold">Import/Export</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Loading loading={loading} />
        <form onSubmit={handleSubmit(onSubmitHandler)}>
          <div className="form-container">
            <div className="d-block border rounded my-2 px-2 py-0">
              <div className="position-relative text-left">
                <div className="row mt-2">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label className="form-label">
                        Import From <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="import_from"
                        control={control}
                        rules={{ required: "This field is required" }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={ImportExportOptions}
                            isMulti
                            onChange={(selectedOptions) => {
                              field.onChange(selectedOptions);
                              setValue("import_from", selectedOptions);
                            }}
                            placeholder="Import From"
                          />
                        )}
                      />
                      {errors.import_from && (
                        <span className="text-danger">
                          {errors.import_from.message}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="form-group">
                      <label className="form-label">
                        Export To <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="export_to"
                        control={control}
                        rules={{ required: "This field is required" }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={ImportExportOptions}
                            isMulti
                            onChange={(selectedOptions) => {
                              field.onChange(selectedOptions);
                              setValue("export_to", selectedOptions);
                            }}
                            placeholder="Export To"
                          />
                        )}
                      />
                      {errors.export_to && (
                        <span className="text-danger">
                          {errors.export_to.message}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 mt-4 text-center">
            <button type="submit" className="btn bg-theme-color">
              Submit
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default ImportExport;
