import "./Main.css";
import GenericModal from "../hooks/GenericModel";
import React, { useState, useRef, useEffect } from "react";
import RightSidebar from "../layout/RightSidebar";
import CommentArea from "./Comment";
import { FeedsInsert, FeedsGet, FeedsLikes } from "../apis/api/FeedsApi";
import { useSelector, useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { FILE_URL } from "../apis/api/Config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loading from "../hooks/Loading";
import Lodder from "../hooks/Lodder";
import Tags from "./tags";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import Select from "react-select";
import { Profilelist } from "../apis/api/ProfileApi";
import { Link } from "react-router-dom";
import { Memberpost, PostDelete } from "../apis/api/FeedsApi";
import EditFeeds from "../timeline/Editfeeds";

const Main = () => {
  const dispatch = useDispatch();
  const [showFilter, setShowFilter] = useState(false);
  const [company_id, setCompany] = useState("");
  const [filter, setfilter] = useState("");
  const [showTags, setShowTags] = useState(false);
  const [showAllTags, setShowAllTags] = useState(false);
  const [showMoreTags, setShowMoreTags] = useState({});
  const [postImage, setPostImage] = useState("");
  const [postVideo, setPostVideo] = useState("");
  const [postAudio, setPostAudio] = useState("");
  const [updateFeed, setUpdateFeed] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const [selectedPost, setSelectedPost] = useState(null);
  const [CommentShow, setCommentShow] = useState(false);
  const plan_validation = JSON.parse(Cookies.get("userJSON"));
  const userId = Cookies.get("userId");
  const Member_type = Cookies.get("member_type");
  const company_idd = Cookies.get("company_id");
  const [date, setdate] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);
  const handleEditClick = (post) => {
    setSelectedPost(post);
  };

  const handleMoreLessClick = (postId) => {
    setShowMoreTags((prevState) => ({
      ...prevState,
      [postId]: !prevState[postId],
    }));
  };
  const company_filter = (selectedOption) => {
    setCompany(selectedOption.value);
  };

  const handleFilterToggle = () => {
    setShowFilter(!showFilter);
  };
  const toggleTags = () => {
    setShowAllTags(!showAllTags);
  };
  const { loading } = useSelector((state) => state.feedsSlice);

  const [feedsData, setFeedsData] = useState([]);

  const memberNames = ["Rakesh", "Mukesh", "John", "Anand", "Raj", "Suresh"];

  const [page, setPage] = useState(0);
  const [loadingMore, setLoadingMore] = useState(false);
  const [lastId, setLastId] = useState(null);
  const [fullfill, setfullfill] = useState(0);
  const [list, setlist] = useState(null);

  const [previews, setPreviews] = useState({
    musicPreview: null,
    imagePreview: null,
    videoPreview: null,
  });
  const memberId = Cookies.get("userId");
  const sorce_type = Cookies.get("sorce_type");
  useEffect(() => {
    refetch();
  }, [updateFeed]);

  useEffect(() => {
    fetchFeeds(false);
    fetchprofilelist();
  }, []);

  const refetch = async () => {
    const response = await dispatch(
      FeedsGet({
        member: memberId,
        lastid: 0,
        pagination: 0,
        sorce_type: sorce_type,
      })
    );
    setFeedsData(response.payload.data);
    setUpdateFeed(false);
  };

  const fetchprofilelist = async () => {
    const pofilelist = await dispatch(Profilelist({ filter: 1 }));
    if (pofilelist.payload.status == "success") {
      let data = pofilelist.payload.data;
      setlist(data);
    }
  };
  const handleMemberDelete = async (postId) => {
    try {
      const result = await dispatch(PostDelete(postId));

      if (result.payload.status === "success") {
        setFeedsData((prevFeeds) =>
          prevFeeds.filter((post) => post.company_post_id !== postId)
        );

        await fetchFeeds(false);
        await fetchprofilelist();

        toast.success("Post deleted successfully!");
      }
    } catch (error) {
      console.error("Error deleting post:", error);
      toast.error("Failed to delete the post.");
    }
  };
  const fetchFeeds = async (filter, filterpage) => {
    setLoadingMore(true);
    let response;

    if (fullfill == 0) {
      if (page === 0) {
        if (!filter) {
          response = await dispatch(
            FeedsGet({
              member: memberId,
              lastid: 0,
              pagination: 0,
              sorce_type: sorce_type,
            })
          );
        } else {
          var filterdate = [
            {
              Start_Date: date[0].startDate.toDateString(),
              End_Date: date[0].endDate ? date[0].endDate.toDateString() : 0,
            },
          ];

          response = await dispatch(
            FeedsGet({
              member: memberId,
              lastid: 0,
              pagination: 0,
              filter: filter,
              company_id: company_id,
              filterdate: filterdate,
              sorce_type: sorce_type,
            })
          );
        }
      } else {
        if (!filter) {
          response = await dispatch(
            FeedsGet({
              member: memberId,
              lastid: lastId,
              pagination: page,
              sorce_type: sorce_type,
            })
          );
        } else if (filterpage) {
          var filterdate = [
            {
              Start_Date: date[0].startDate.toDateString(),
              End_Date: date[0].endDate ? date[0].endDate.toDateString() : 0,
            },
          ];

          response = await dispatch(
            FeedsGet({
              member: memberId,
              lastid: lastId,
              pagination: 0,
              filter: filter,
              company_id: company_id,
              filterdate: filterdate,
              sorce_type: sorce_type,
            })
          );
        } else {
          var filterdate = [
            {
              Start_Date: date[0].startDate.toDateString(),
              End_Date: date[0].endDate ? date[0].endDate.toDateString() : 0,
            },
          ];

          response = await dispatch(
            FeedsGet({
              member: memberId,
              lastid: lastId,
              pagination: page,
              filter: filter,
              company_id: company_id,
              filterdate: filterdate,
              sorce_type: sorce_type,
            })
          );
        }
      }
      if (response.payload.data == 0) {
        setfullfill(1);
      }
      if (response.payload.data) {
        if (response.payload.data.length > 0) {
          if (page === 0) {
            setFeedsData(response.payload.data);
          } else if (filterpage) {
            setFeedsData(response.payload.data);
          } else {
            setFeedsData((prevFeeds) => [
              ...prevFeeds,
              ...response.payload.data,
            ]);
          }

          if (
            lastId !==
            response.payload.data[response.payload.data.length - 1]
              .company_post_id
          ) {
            setLastId(
              response.payload.data[response.payload.data.length - 1]
                .company_post_id
            );
            if (filterpage) {
              setPage((prevPage) => 0 + 1);
            } else {
              setPage((prevPage) => prevPage + 1);
            }
          }
        }
      }
    }
    setLoadingMore(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop + 1 >=
          document.documentElement.scrollHeight &&
        !loadingMore
      ) {
        fetchFeeds(filter);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [loadingMore]);

  const [showModal, setShowModal] = useState(false);
  const [post_id, setpost_id] = useState(false);
  // const comments = [<CommentArea />];

  const handleShow = (id, imageUrl, Videourl, postAudio) => {
    setPostImage(imageUrl);
    setPostVideo(Videourl);
    setPostAudio(postAudio);
    setShowModal(true);
    setpost_id(id);
  };
  const handleClose = () => setShowModal(false);

  const [formData, setFormData] = useState({
    textarea: "",
    musicFile: null,
    imageFile: null,
    videoFile: null,
    tags: [],
  });

  const [selectedFileType, setSelectedFileType] = useState(null);

  const musicInputRef = useRef(null);
  const imageInputRef = useRef(null);
  const videoInputRef = useRef(null);
  const likeCountsRef = useRef({});

  const handleIconClick = (inputRef, fileType) => {
    setSelectedFileType(fileType);
    inputRef.current.click();
    setShowTags(true);
  };

  const handleFileChange = (event, fileType) => {
    const file = event.target.files[0];
    if (!file) {
      setSelectedFileType(null);
      setShowTags(false);
      return;
    }
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreviews((prevState) => ({
        ...prevState,
        [`${fileType.toLowerCase()}Preview`]: reader.result,
      }));
    };
    reader.readAsDataURL(file);

    const validTypes = {
      Music: ["audio/mpeg", "audio/wav", "audio/mp3"],
      Image: ["image/jpeg", "image/png", "image/gif"],
      Video: ["video/mp4", "video/avi", "video/mov"],
    };

    if (validTypes[fileType].includes(file.type)) {
      const fileURL = URL.createObjectURL(file);
      setFormData((prevData) => ({
        ...prevData,
        [`${fileType.toLowerCase()}File`]: file,
      }));

      setPreviews((prevPreviews) => ({
        ...prevPreviews,
        [`${fileType.toLowerCase()}Preview`]: fileURL,
      }));
    } else {
      toast.error(`Please select a valid ${fileType.toLowerCase()} file.`);
    }
  };

  const handleTextareaChange = (event) => {
    setFormData((prevData) => ({
      ...prevData,
      textarea: event.target.value,
    }));
    setShowTags(event.target.value !== "");
  };

  const handleRemoveFile = (fileType, inputRef) => {
    setFormData((prevData) => ({
      ...prevData,
      [`${fileType.toLowerCase()}File`]: null,
    }));

    setPreviews((prevPreviews) => ({
      ...prevPreviews,
      [`${fileType.toLowerCase()}Preview`]: null,
    }));

    if (inputRef.current) {
      inputRef.current.value = "";
    }
    setSelectedFileType(null);
    setShowTags(false);
  };
  // const [status, setStatus] = useState("idle");

  const handlefilter = async (event) => {
    event.preventDefault();
    // setfullfill(0);
    var filterpage = 1;

    setfilter((prevState) => {
      const newState = 1;
      fetchFeeds(newState, filterpage);
      return newState;
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let response;
    const company_id = Cookies.get("company_id");
    const sorce_type = Cookies.get("sorce_type");
    const member_detail = JSON.parse(Cookies.get("userJSON"));

    const formDataToSend = new FormData();
    formDataToSend.append("textarea", formData.textarea);
    formDataToSend.append("company_id", company_id);
    formDataToSend.append("sorce_type", sorce_type);
    formDataToSend.append("member_id", memberId);
    formDataToSend.append("perweak_post", member_detail.post_per_weak);
    formDataToSend.append("upload_limit", member_detail.feeds_upload);

    if (formData.musicFile)
      formDataToSend.append("musicFile", formData.musicFile);
    if (formData.imageFile)
      formDataToSend.append("imageFile", formData.imageFile);
    if (formData.videoFile)
      formDataToSend.append("videoFile", formData.videoFile);

    if (formData.tags && formData.tags.length > 0) {
      const validTagValues = formData.tags
        .map((tag) => tag.value)
        .filter((value) => value !== null && value !== undefined);

      if (validTagValues.length > 0) {
        formDataToSend.append("tags", JSON.stringify(validTagValues));
      }
    }

    if (
      formData.musicFile ||
      formData.imageFile ||
      formData.videoFile ||
      formData.textarea !== ""
    ) {
      response = await dispatch(FeedsInsert(formDataToSend));

      if (response.payload.status == "success") {
        handleRemoveFile("Music", musicInputRef);
        handleRemoveFile("Image", musicInputRef);
        handleRemoveFile("Video", musicInputRef);
        setFormData({
          textarea: "",
          tags: [],
        });
        toast.success("post successful!");
        response = await dispatch(
          FeedsGet({
            member: memberId,
            lastid: 0,
            pagination: 0,
            sorce_type: sorce_type,
          })
        );
        if (response.payload.data) {
          setFeedsData(response.payload.data);
        }
      }
      if (response.payload.status == "error") {
        setFormData({
          textarea: "",
          tags: [],
        });
        toast.error(response.payload.msz);
      }
    } else {
      toast.error(`Please select a valid  file.`);
    }
  };

  const [like_set, setlike_set] = useState(null);
  const [post_set, setpost_set] = useState(null);

  const lastlikecount = useRef(null);
  const lastlikestatus = useRef(null);
  const currentlikecount = useRef(null);
  const currentlikestatus = useRef(null);

  const handleLikeClick = async (feedId, likesCount, LikedStatus) => {
    const newLikesCount = LikedStatus
      ? parseInt(likesCount) - 1
      : parseInt(likesCount) + 1;

    if (like_set !== likesCount || post_set !== feedId) {
      dispatch(
        FeedsLikes({
          feedId: feedId,
          like: newLikesCount,
          memberId: memberId,
          LikedStatus: LikedStatus,
        })
      );
      const curlikestatus = LikedStatus ? false : true;

      lastlikecount.current = newLikesCount;
      lastlikestatus.current = curlikestatus;
      var insElement = document.getElementById(feedId);

      if (insElement) {
        insElement.innerHTML = newLikesCount;
        const liElement = insElement.closest("li");

        if (liElement) {
          const buttonElement = liElement.querySelector("button");
          if (LikedStatus) {
            buttonElement.classList.remove("liked");
          } else {
            buttonElement.classList.add("liked");
          }
        }
      }
    } else {
      currentlikestatus.current = lastlikestatus.current ? false : true;
      currentlikecount.current = lastlikestatus.current
        ? parseInt(lastlikecount.current) - 1
        : parseInt(lastlikecount.current) + 1;
      dispatch(
        FeedsLikes({
          feedId: feedId,
          like: currentlikecount.current,
          memberId: memberId,
          LikedStatus: currentlikestatus.current,
        })
      );

      var insElement = document.getElementById(feedId);
      if (insElement) {
        insElement.innerHTML = currentlikecount.current;
        const liElement = insElement.closest("li");

        if (liElement) {
          const buttonElement = liElement.querySelector("button");
          if (lastlikestatus.current) {
            buttonElement.classList.remove("liked");
          } else {
            buttonElement.classList.add("liked");
          }
        }
      }
      lastlikecount.current = currentlikecount.current;
      lastlikestatus.current = currentlikestatus.current;
    }

    setlike_set(likesCount);
    setpost_set(feedId);
  };

  const handleReset = (fileType, inputRef) => {
    handleRemoveFile(fileType, inputRef);
    setShowTags(false);
  };

  return (
    <>
      <EditFeeds
        showModal={modalShow}
        closeModal={() => setModalShow(false)}
        selectedPost={selectedPost}
        updateFeed={setUpdateFeed}
      />
      <Loading loading={loading} />
      {/* {loading && <Lodder />} */}
      <div className="d-flex  justify-content-around  ">
        <div class="col-md-8 col-12 col-xl-8">
          <div className="">
            <div class="central-meta">
              <div class="new-postbox">
                {/* <figure key={feed.company_post_id}>
      <img
        className="Image"
        src={feed.company_company_logo}
        onError={(e) => {
          e.target.src = "/images.jpeg";
        }}
        alt="Company Logo"
      />
    </figure> */}

                <div class="newpst-input">
                  <form onSubmit={handleSubmit}>
                    <div
                      className="textarea-container"
                      style={{ position: "relative" }}
                    >
                      <textarea
                        id="myTextarea"
                        rows="2"
                        placeholder="Write something"
                        value={formData.textarea}
                        onChange={handleTextareaChange}
                        maxLength="1000"
                        style={{ paddingBottom: "20px" }} // Add space for the remaining characters
                      ></textarea>
                      <div
                        className="remaining-chars"
                        style={{
                          position: "absolute",
                          bottom: "5px",

                          fontSize: "12px",
                          color: "#888",
                        }}
                      >
                        {1000 - formData.textarea.length} characters remaining
                      </div>
                    </div>
                    <div className="attachments">
                      <ul>
                        {!formData.musicFile &&
                          !formData.imageFile &&
                          !formData.videoFile && (
                            <>
                              <li>
                                <i
                                  className="fa fa-music"
                                  onClick={() =>
                                    handleIconClick(musicInputRef, "Music")
                                  }
                                ></i>
                                <label className="fileContainer">
                                  <input
                                    type="file"
                                    ref={musicInputRef}
                                    onChange={(event) =>
                                      handleFileChange(event, "Music")
                                    }
                                    style={{ display: "none" }}
                                  />
                                </label>
                              </li>
                              <li>
                                <i
                                  className="fa fa-image"
                                  onClick={() =>
                                    handleIconClick(imageInputRef, "Image")
                                  }
                                ></i>
                                <label className="fileContainer">
                                  <input
                                    type="file"
                                    ref={imageInputRef}
                                    onChange={(event) =>
                                      handleFileChange(event, "Image")
                                    }
                                    style={{ display: "none" }}
                                  />
                                </label>
                              </li>
                              <li>
                                <i
                                  className="fa fa-video-camera"
                                  onClick={() =>
                                    handleIconClick(videoInputRef, "Video")
                                  }
                                ></i>
                                <label className="fileContainer">
                                  <input
                                    type="file"
                                    ref={videoInputRef}
                                    onChange={(event) =>
                                      handleFileChange(event, "Video")
                                    }
                                    style={{ display: "none" }}
                                  />
                                </label>
                              </li>
                            </>
                          )}
                        {formData.musicFile && (
                          <li>
                            <div style={{ position: "relative" }}>
                              <audio controls>
                                <source src={previews.musicPreview} />
                                Your browser does not support the audio element.
                              </audio>
                              <button
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  right: 0,
                                  background: "red",
                                  color: "white",
                                  border: "none",
                                  borderRadius: "50%",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  handleRemoveFile("Music", musicInputRef)
                                }
                              >
                                X
                              </button>
                            </div>
                          </li>
                        )}
                        {formData.imageFile && (
                          <li>
                            <div style={{ position: "relative" }}>
                              <img
                                src={previews.imagePreview}
                                alt="Image Preview"
                                style={{ maxWidth: "100%", maxHeight: "100%" }}
                              />
                              <button
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  right: 0,
                                  background: "white",
                                  color: "black",
                                  border: "none",
                                  borderRadius: "50%",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  handleRemoveFile("Image", imageInputRef)
                                }
                              >
                                X
                              </button>
                            </div>
                          </li>
                        )}
                        {formData.videoFile && (
                          <li>
                            <div style={{ position: "relative" }}>
                              <video
                                controls
                                style={{ maxWidth: "100%", maxHeight: "100%" }}
                              >
                                <source src={previews.videoPreview} />
                                Your browser does not support the video element.
                              </video>
                              <button
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  right: 0,
                                  background: "red",
                                  color: "white",
                                  border: "none",
                                  borderRadius: "50%",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  handleRemoveFile("Video", videoInputRef)
                                }
                              >
                                X
                              </button>
                            </div>
                          </li>
                        )}
                        <li>
                          <div className="d-flex align-items-center mt-3">
                            <div className="flex-grow-1">
                              {showTags && (
                                <Tags
                                  onChange={(tags) =>
                                    setFormData({ ...formData, tags })
                                  }
                                />
                              )}
                            </div>
                            <button
                              type="submit"
                              className="btn btn-primary ms-3 btn-sm"
                              onClick={handleSubmit}
                            >
                              Post
                            </button>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div className="container Filtter ">
              <div className="d-flex justify-content-end">
                <button
                  className="btn btn-light btn-sm"
                  onClick={handleFilterToggle}
                >
                  {showFilter ? (
                    <i className="fa-solid fa-xmark text-danger"></i>
                  ) : (
                    <i className="fa-solid fa-filter text-primary"></i>
                  )}{" "}
                  filter
                </button>
              </div>

              {showFilter && (
                <form onSubmit={handleSubmit} className="mt-3">
                  <div className="row ">
                    <div className="col-sm-5 mt-2">
                      <div className="form-group">
                        <label htmlFor="company">Company</label>
                        <Select
                          options={list}
                          onChange={company_filter}
                          placeholder={"Company Name"}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <label htmlFor="company">Select Date</label>
                      <div className="App">
                        <div>
                          <DateRange
                            editableDateInputs={true}
                            onChange={(item) => setdate([item.selection])}
                            moveRangeOnFirstSelection={false}
                            ranges={date}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end">
                    <button
                      type="submit"
                      className="btn btn-primary ms-3 btn-sm"
                      onClick={handlefilter}
                    >
                      {" "}
                      Submit
                    </button>
                  </div>
                </form>
              )}
            </div>

            <div className="feed-container">
              {Array.isArray(feedsData) && feedsData.length > 0 ? (
                feedsData.map((feed) => {
                  likeCountsRef.current[feed.company_post_id] =
                    feed.company_post_like;
                  const noMedia =
                    !feed.company_post_file_url ||
                    !["image", "video", "audio", ".mp3"].includes(
                      feed.company_post_file_extension
                    );

                  return (
                    <div
                      className="central-meta item"
                      key={feed.company_post_id}
                    >
                      <div className="user-post">
                        <div className="friend-info">
                          <figure>
                            <img
                              className="Image mt-0"
                              src={feed.member_member_logo}
                              onError={(e) => {
                                e.target.src = "/dumy.jpeg";
                              }}
                            />
                          </figure>
                          <div className="friend-name">
                            <ins className="ins d-flex justify-content-between align-items-center">
                              <Link
                                to={`/member/${feed.company_id}/${feed.member_id}`}
                              >
                                {feed.member_name} ||{" "}
                                {feed.company_company_name}
                              </Link>
                              {((feed.company_post_company_id == company_idd &&
                                Member_type == 1) ||
                                feed.company_post_member_id == userId) && (
                                <div className="dropdown">
                                  <button
                                    className="btn btn-light dropdown-toggle"
                                    type="button"
                                    id={`dropdownMenuButton-${feed.company_post_id}`}
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <i className="fa fa-ellipsis-h"></i>
                                  </button>
                                  <ul
                                    className="dropdown-menu"
                                    aria-labelledby={`dropdownMenuButton-${feed.company_post_id}`}
                                  >
                                    <li>
                                      <button
                                        className="dropdown-item bg-"
                                        onClick={() => {
                                          setModalShow(true);
                                          handleEditClick(feed);
                                        }}
                                      >
                                        <i className="fa fa-edit me-2"></i>
                                        Edit
                                      </button>
                                    </li>
                                    <li>
                                      <button
                                        className="dropdown-item"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleMemberDelete(
                                            feed.company_post_id
                                          );
                                        }}
                                      >
                                        <i className="fa fa-trash me-2"></i>
                                        Delete
                                      </button>
                                    </li>
                                  </ul>
                                </div>
                              )}
                            </ins>

                            <span>
                              {(() => {
                                const rawDate = feed.company_post_created_at;
                                const cleanDateStr = rawDate
                                  .replace("Published: ", "")
                                  .replace("16:PM", "16:00");
                                const parsedDate = new Date(cleanDateStr);
                                return !isNaN(parsedDate)
                                  ? parsedDate.toLocaleString()
                                  : " ";
                              })()}
                            </span>
                          </div>

                          <div className="post-meta">
                            {noMedia ? (
                              <p>{feed.company_post_description}</p>
                            ) : feed.company_post_file_extension === "video" ? (
                              <video
                                className="videoContainer w-100"
                                controls
                                src={feed.company_post_file_url.replace(
                                  "/thumb/",
                                  "/"
                                )}
                                alt="Post video"
                              />
                            ) : feed.company_post_file_extension === "audio" ? (
                              <audio
                                className="audioContainer w-100"
                                controls
                                src={feed.company_post_file_url.replace(
                                  "/thumb/",
                                  "/"
                                )}
                              >
                                Your browser does not support the audio element.
                              </audio>
                            ) : feed.company_post_file_extension === ".mp3" ? (
                              <div>
                                <audio
                                  className="audioContainer w-100"
                                  controls
                                  src={feed.company_post_file_url.replace(
                                    "/thumb/",
                                    "/"
                                  )}
                                >
                                  Your browser does not support the audio
                                  element.
                                </audio>
                              </div>
                            ) : (
                              <img
                                className="imgContainer w-100"
                                src={feed.company_post_file_url.replace(
                                  "/thumb/",
                                  "/"
                                )}
                                alt="Post image"
                              />
                            )}

                            {/* Post description */}
                            {!noMedia && (
                              <div className="description">
                                <p>{feed.company_post_description}</p>
                              </div>
                            )}

                            <div className="member-names">
                              {feed?.company_post_tags ? (
                                <>
                                  <i className="fa-solid fa-tag me-2"></i>

                                  {feed.company_post_tags
                                    .split(",")
                                    .slice(
                                      0,
                                      showMoreTags[feed.company_post_id]
                                        ? feed.company_post_tags.length
                                        : 3
                                    )
                                    .map((name, index) => (
                                      <span key={index} className="member-name">
                                        {name.trim()}
                                        {index <
                                          feed.company_post_tags.split(",")
                                            .length -
                                            1 && ", "}
                                      </span>
                                    ))}
                                </>
                              ) : null}
                            </div>

                            {feed?.company_post_tags &&
                              feed.company_post_tags.split(" ||").length >
                                3 && (
                                <button
                                  className="btn btn-link"
                                  onClick={() =>
                                    handleMoreLessClick(feed.company_post_id)
                                  }
                                >
                                  {showMoreTags[feed.company_post_id]
                                    ? "See Less"
                                    : "See More"}
                                </button>
                              )}

                            {/* Like/Comment button */}
                            <div className="we-video-info">
                              <ul>
                                <li>
                                  <button
                                    type="button"
                                    className={`btn btn-Light rounded-circle fa-regular fa-thumbs-up ${
                                      feed.liked ? "liked" : ""
                                    }`}
                                    onClick={() =>
                                      handleLikeClick(
                                        feed.company_post_id,
                                        feed.company_post_like,
                                        feed.liked
                                      )
                                    }
                                    aria-label={`Like this post by ${feed.member_name}`}
                                  ></button>
                                  <ins id={feed.company_post_id}>
                                    {
                                      likeCountsRef.current[
                                        feed.company_post_id
                                      ]
                                    }
                                  </ins>
                                </li>
                                <li>
                                  <button
                                    type="button"
                                    className="btn btn-Light rounded-circle fa-regular fa-comment"
                                    aria-label={`Comment on post by ${feed.member_name}`}
                                    onClick={() => {
                                      const id = feed.company_post_id;
                                      const imageUrl =
                                        feed.company_post_file_extension ===
                                        "image"
                                          ? feed.company_post_file_url.replace(
                                              "/thumb/",
                                              "/"
                                            )
                                          : null;

                                      const videoUrl =
                                        feed.company_post_file_extension ===
                                        "video"
                                          ? feed.company_post_file_url.replace(
                                              "/thumb/",
                                              "/"
                                            )
                                          : null;

                                      const audioUrl =
                                        feed.company_post_file_extension ===
                                        "audio"
                                          ? feed.company_post_file_url.replace(
                                              "/thumb/",
                                              "/"
                                            )
                                          : null;

                                      handleShow(
                                        id,
                                        imageUrl,
                                        videoUrl,
                                        audioUrl
                                      );
                                    }}
                                  ></button>
                                  <ins></ins>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <p>No posts available</p>
              )}

              {loadingMore && <Lodder />}
            </div>
          </div>

          <GenericModal
            show={showModal}
            handleClose={handleClose}
            title="Comments"
            postImage={postImage}
            PostVideo={postVideo}
            postAudio={postAudio}
          >
            <CommentArea id={post_id} />
          </GenericModal>
        </div>
        <div class="col-md-3 ">
          <RightSidebar />
        </div>
      </div>
    </>
  );
};

export default Main;
