import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { NODE_URL,BASE_URL} from "./Config";
import Cookies from 'js-cookie';

export const CommunityAll = createAsyncThunk(
    "post/CommunityAll",
    async (data) => { 
        let url = NODE_URL+'MembersDetails';
        const token=Cookies.get("token");
        try{
            
            const response = (await axios.post(url,data,{
                    headers: {
                        Authorization: `Bearer ${token}`, 
                    },
            }

            )).data
          
            if(response.status == "success"){
               
                return Promise.resolve(response)
            } else {
                return Promise.reject(response)
            }
        }catch (error) {
            return Promise.reject(error)
        }
    }
)