import React, { useState } from "react";
import EditAlbum from "./editAlbum";
import { useDispatch ,useSelector} from "react-redux";
import { galleryDelete,galleryGet } from "../apis/api/GalleryApi";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import Loading from "../hooks/Loading";

function AlbumCard({ album, onClick }) {
  const dispatch = useDispatch();

  const [showEditAlbum, setShowEditAlbum] = useState(false);
  const [selectedAlbum, setSelectedAlbum] = useState(null);
  const loading = useSelector((state) => state.gallerySlice.loading);
 
  const photosToShow = album.file_url.slice(0, 4);

  const isSinglePhoto = photosToShow.length === 1;

  const user_id = Cookies.get("userId");
  const company_id = Cookies.get("company_id");

  const handleShowEditAlbum = (e) => {
    e.stopPropagation();  
    setSelectedAlbum(album);
    setShowEditAlbum(true);
  };

  const handleDeleteAlbum =async (e) => {
    e.stopPropagation();  
    let album_id=album.album_id;
    const response = await dispatch(galleryDelete(album_id));
    if(response.payload.status=='success'){
      const response=await dispatch(galleryGet({ user_id, company_id }));
      if(response.payload.status=='success'){
        toast.success("Album deleted successful!");
      }
      
    }
    
    console.log(album.album_id)
  };
 
  const handleClose = () => setShowEditAlbum(false);

 
  const isVideoFile = (fileUrl) => {
    return fileUrl?.endsWith(".webm") || fileUrl?.endsWith(".mp4") || fileUrl?.endsWith(".mov");
  };

  return (
    <>
      <EditAlbum
        showModal={showEditAlbum}
        closeModal={handleClose}
        albumData={selectedAlbum}
        updateAlbum={(updatedAlbum) => {
          setSelectedAlbum(updatedAlbum);
        }}
      />
     <Loading loading={loading} />
      <div className="col-xl-3 col-xxl-3  col-sm-12 col-md-6 mb-3 mt-2">
        <div    
          className="album-card"
          onClick={onClick}  
          style={{ position: "relative" }}
        >
          <button
            className="btn btn-secondary btn-sm position-absolute bg-theme-color top-0 start-0 m-2 z-index-2"
            onClick={handleShowEditAlbum}  
          >
            <i className="fas fa-pen"></i>
          </button>

          <button className="btn btn-danger btn-sm position-absolute top-0 end-0 m-2 z-index-2"
          onClick={handleDeleteAlbum}  
          >
            <i className="fas fa-trash"></i>
          </button>

          <div className={`cover-photos ${isSinglePhoto ? "single-photo" : ""}`}>
            {photosToShow.map((photo, index) => {
              if (isVideoFile(photo)) {
                return (
                  <video
                    key={index}
                    src={photo.replace("/thumb/", "/")}
                    alt={`Cover video ${index + 1}`}
                    className="cover-photo"
                    controls
                    onError={(e) => {
                      e.target.src = "/no-attrony.png"; 
                    }}
                  />
                );
              } else {
                return (
                  <img
                    key={index}
                    src={photo}
                    alt={`Cover ${index + 1}`}
                    className="cover-photo"
                    onError={(e) => {
                      e.target.src = "/no-attrony.png"; 
                    }}
                  />
                );
              }
            })}
          </div>

          <p className="album-name pt-3 pb-3 mb-0 bg-light">{album.title}</p>
        </div>
      </div>
    </>
  );
}

export default AlbumCard;
