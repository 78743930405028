// Live URL Link
// export const BASE_URL = "https://clubnet.app/MVP/member/"
// export const FILE_URL = "https://clubnet.app/MVP/"
// export const NODE_URL = "https://clubnet.app/node/api/"
// development URL Link
export const BASE_URL = "https://clubnet.app/MVP_DEV/member/"
export const FILE_URL = "https://clubnet.app/MVP_DEV/"

export const NODE_URL="https://clubnet.app/dev_node/api/"

export const menus = ["event", "one2one", "network"]

// Login Update Attempt
export const signupUrl = "auth/signup";
export const signinUrl = "auth/login";

// Data APIs
export const countrylist_url = BASE_URL+"data/country/countryList";
export const statelist_url = BASE_URL+"data/statelist";