import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Link, useNavigate } from "react-router-dom"; // Import useNavigate
import { useLocation } from "react-router-dom";
import { Column } from "primereact/column";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faSearch } from "@fortawesome/free-solid-svg-icons";

const ActiveMemberTable = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredProfiles, setFilteredProfiles] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [selectedProfiles, setSelectedProfiles] = useState([]);

  const location = useLocation();
  const navigate = useNavigate(); // Initialize useNavigate
  const { activeMemberData, recent, active } = location.state || {};


  useEffect(() => {
    if (activeMemberData) {
      const query = searchQuery.toLowerCase();
      const filteredData = activeMemberData.filter((customer) => {
        const fieldsToSearch = [
          customer.member_name || "",
          customer.company_name || "",
          customer.company_country || "",
          customer.country || "",
          customer.company_city || "",
          customer.membership_plan_name || "",
          customer.website || "",
        ];

        // Check if any of the fields include the search query
        return (
          fieldsToSearch.some((field) => field.toLowerCase().includes(query)) ||
          // Check membership dates and activation
          customer.membership_start === searchQuery ||
          customer.membership_end === searchQuery ||
          customer.membership_activation === searchQuery ||
          customer.membership_since === searchQuery
        );
      });

      setFilteredProfiles(filteredData);
    }
  }, [searchQuery, activeMemberData]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const onSelectionChange = (e) => {
    setSelectedProfiles(e.value);
  };

  const handleRowClick = (rowData) => {
    navigate( `/member/${rowData.company_id}/${rowData.member_id}`);
   
  };

  

  const nameBodyTemplate = (rowData) => (
    <div className="d-flex align-items-center">
      <img
        src={`${rowData?.member_logo || "default-logo.png"}`}
        style={{
          width: "30px",
          height: "30px",
          marginRight: "10px",
          borderRadius: "50%",
        }}
        onError={(e) => {
          e.target.src = "/dumy.jpeg"; // Default image
        }}
      />
      {rowData.member_name}
    </div>
  );

  const activityBodyTemplate = (rowData) => (
    <div className="me-2">
      <Link to={`/member/${rowData.company_id}/${rowData.member_id}`} >

        <button className="btn btn-primary me-1 p-0">
          <FontAwesomeIcon icon={faEye} className="text-white p-1" />
        </button>
      </Link>
    </div>
  );
  

  const handleRowsChange = (e) => {
    setRowsPerPage(Number(e.target.value));
  };
  const handleBack = () => {
    navigate(-1);
  };
  return (
    <div className="datatable-crud-demo container-fluid ">
       <div className="d-flex justify-content-between align-items-center mb-2 mt-3">
      <h5 className="mb-2 mt-3 text-color d-flex align-items-center">
        <i className="fas fa-users me-1"></i>
        <span>{recent && <span>{recent}</span>}</span>
        <span>{active && <span>{active}</span>}</span>
      </h5>
      <div className="ms-auto">
    <button className="btn btn-secondary bg-theme-color btn-sm"   onClick={handleBack}>
      <i className="fas fa-arrow-left me-2"></i>Back
    </button>
  </div>
      </div>

      <div className="d-flex justify-content-end align-items-center mb-3">
        <div className="input-group" style={{ maxWidth: "300px" }}>
          <input
            type="text"
            className="form-control"
            placeholder="Search by ..."
            value={searchQuery}
            onChange={handleSearchChange}
          />
          <button className="btn btn-primary bg-theme mt-0">
            <FontAwesomeIcon icon={faSearch} />
          </button>
        </div>
        <select
          className="form-select ms-2"
          value={rowsPerPage}
          onChange={handleRowsChange}
          style={{ width: "100px" }}
        >
          <option value={5}>5</option>
          <option value={10}>10</option>
          <option value={20}>20</option>
          <option value={30}>30</option>
          <option value={50}>50</option>
        </select>
      </div>

      <DataTable
        value={filteredProfiles}
        paginator
        rows={rowsPerPage}
        emptyMessage="No members found."
        rowClassName={() => "custom-row"}
        selection={selectedProfiles}
        onSelectionChange={onSelectionChange}
        selectionMode="checkbox"
        onRowClick={(e) => handleRowClick(e.data)}
      >
        <Column
          field="member_name"
          body={nameBodyTemplate}
          header="Name"
          headerClassName="bg-theme-color pt-2 pb-2"
          headerStyle={{ paddingLeft: "25px", paddingRight: "30px" }}
          bodyStyle={{ paddingLeft: "25px", paddingRight: "30px" }}
          sortable
        />

        <Column
          field="company_name"
          header="Company"
          headerStyle={{ paddingLeft: "15px", paddingRight: "15px" }}
          bodyStyle={{ paddingLeft: "15px", paddingRight: "15px" }}
          headerClassName="bg-theme-color"
          sortable
        />

        <Column
          field="company_country"
          header="Country"
          body={(rowData) => rowData.company_country.split(":")[1]}
          headerStyle={{ paddingLeft: "15px", paddingRight: "15px" }}
          bodyStyle={{ paddingLeft: "15px", paddingRight: "15px" }}
          headerClassName="bg-theme-color"
          sortable
        />

        <Column
          field="membership_plan_name"
          header="Membership Plan"
          headerStyle={{ paddingLeft: "15px", paddingRight: "15px" }}
          bodyStyle={{ paddingLeft: "15px", paddingRight: "15px" }}
          headerClassName="bg-theme-color"
          sortable
        />

        <Column
          field="membership_start"
          header="Membership Start Date"
          headerStyle={{ paddingLeft: "15px", paddingRight: "15px" }}
          bodyStyle={{ paddingLeft: "15px", paddingRight: "15px" }}
          headerClassName="bg-theme-color"
          sortable
          body={(rowData) => {
            if (!rowData.membership_start || rowData.membership_start == 0) {
              return <span>&nbsp;</span>;
            }
            const timestamp = parseInt(rowData.membership_start) * 1000;
            const date = new Date(timestamp);
            const day = String(date.getDate()).padStart(2, "0");
            const month = String(date.getMonth() + 1).padStart(2, "0");
            const year = date.getFullYear();
            const formattedDate = `${day}-${month}-${year}`;
            return <span>{formattedDate}</span>;
          }}
        />

        <Column
          field="membership_expire"
          header="Membership end Date"
          headerStyle={{ paddingLeft: "15px", paddingRight: "15px" }}
          bodyStyle={{ paddingLeft: "15px", paddingRight: "15px" }}
          headerClassName="bg-theme-color"
          sortable
          body={(rowData) => {
            if (!rowData.membership_expire|| rowData.membership_expire == 0) {
              return <span>&nbsp;</span>;
            }
            const timestamp = parseInt(rowData.membership_expire ) * 1000;
            const date = new Date(timestamp);
            const day = String(date.getDate()).padStart(2, "0");
            const month = String(date.getMonth() + 1).padStart(2, "0");
            const year = date.getFullYear();
            const formattedDate = `${day}-${month}-${year}`;
            return <span>{formattedDate}</span>;
          }}
        />

        <Column
          field="membership_since"
          header="Membership Since Date"
          headerStyle={{ paddingLeft: "15px", paddingRight: "15px" }}
          bodyStyle={{ paddingLeft: "15px", paddingRight: "15px" }}
          headerClassName="bg-theme-color"
          sortable
          body={(rowData) => {
            if (!rowData.membership_since || rowData.membership_since == 0) {
              return <span>&nbsp;</span>;
            }
            const timestamp = parseInt(rowData.membership_since) * 1000;
            const date = new Date(timestamp);
            const day = String(date.getDate()).padStart(2, "0");
            const month = String(date.getMonth() + 1).padStart(2, "0");
            const year = date.getFullYear();
            const formattedDate = `${day}-${month}-${year}`;
            return <span>{formattedDate}</span>;
          }}
        />

        <Column
          header="Action"
          body={activityBodyTemplate}
          headerStyle={{ paddingLeft: "15px", paddingRight: "15px" }}
          bodyStyle={{ paddingLeft: "15px", paddingRight: "15px" }}
          headerClassName="bg-theme-color"
        />
      </DataTable>
    </div>
  );
};

export default ActiveMemberTable;
