import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Profileget } from "../apis/api/ProfileApi";
import { galleryGet } from "../apis/api/GalleryApi";
import { MediaGet } from "../apis/api/GalleryApi";
import {ImportExportAll} from "../apis/api/DashboardApi";
import Cookies from "js-cookie";
import { latestMember, activeMember } from "../apis/api/DashboardApi";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTachometerAlt,
  faChartBar,
  faBookmark,
  faUser,
  faSignOutAlt,
  faUserFriends,
  faUsers,
  faUserCheck,
  faClock,
  faKey,
} from "@fortawesome/free-solid-svg-icons";
import MyChatBot from "../chatbot/MyChatBot";

const Sidebar = ({ children }) => {
  const dispatch = useDispatch();
  const [member, setMember] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [active ,setActive]=useState();
  const [Latest ,setLatest]=useState();
  const sourceType = Cookies.get("sorce_type");
  const navigate = useNavigate();
  const Recent = "Recent member";
  const Active = "Active member";
  const user_id = Cookies.get("userId");
  const company_id = Cookies.get("company_id");



  useEffect(() => {
 
    dispatch(MediaGet({ company_id:company_id ,user_id:user_id}));
    dispatch(latestMember({ source_type: sourceType }));
    dispatch(activeMember({ source_type: sourceType }));
    dispatch(ImportExportAll());
    fetchData();
    // const company_id = Cookies.get("company_id");

    dispatch(galleryGet({ user_id, company_id }));
  }, []);

  let { latestmember } = useSelector((state) => state.dashboardSlice);
  latestmember = latestmember?.result || [];
  let { activemember } = useSelector((state) => state.dashboardSlice);
  activemember = activemember?.result || [];
  const ActiveMember = activemember;

  const plan_validation = JSON.parse(Cookies.get("userJSON"));
  // const user_id = Cookies.get("userId");

  
 
  const fetchData = async () => {
    const response = await dispatch(Profileget({ company_id: company_id,member_id:user_id }));
    if (response.payload.data) {
      const memberData = { ...response.payload.data };
      const selectedMember = memberData.members.find(
        (members) => members.id == user_id
      );
      setMember(selectedMember);
    }
  };
 
  const RedirectActiveMemberTable = () => {
    navigate("/activeMember", {
      state: { activeMemberData: ActiveMember, active: Active },
    });
  };
  const RedirectRecentMemberTable = () => {
    navigate("/activeMember", {
      state: { activeMemberData: latestmember, recent: Recent },
    });
  };

  const clearAllCookies = () => {
    const allCookies = Cookies.get();
    Object.keys(allCookies).forEach((cookie) => {
      Cookies.remove(cookie);
    });
  };

  const handleClick = (event) => {
    event.preventDefault();
    clearAllCookies();
    toast.success("Logout successful!");
    navigate("/");
  };

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="container-fluid">
      <div className="row flex-nowrap">
        <div
          className={`bg-theme ${isOpen
              ? "col-8"
              : "d-none d-md-block col-auto col-md-4 col-xl-3 col-xxl-2 px-sm-2 px-0"
            }`}
          style={{ minHeight: "100vh", backgroundColor: "#343a40" }}
        >
          <div
            className="d-flex flex-column align-items-start px-3 pt-2 text-white position-fixed"
            style={{ height: "100%", width: "100%" }}
          >
            <div className="mb-3 mt-3">
              <img
                src={`${process.env.PUBLIC_URL}/FL-select-logo2.png`}
                alt="Logo"
                style={{ width: "11%" }}
              />
            </div>

            <ul className="nav" id="menu">
              <li className="nav-item">
                <Link
                  to="/home"
                  className="nav-link align-middle px-0 d-flex align-items-center"
                >
                  <FontAwesomeIcon
                    icon={faTachometerAlt}
                    className="nav_icon"
                  />
                  <span className="ms-2 sidebar-text">Dashboard</span>
                </Link>
              </li>
              {plan_validation.post_access ? (
                <li className="nav-item">
                  <Link
                    to="/feeds"
                    className="nav-link px-0 align-middle d-flex align-items-center"
                  >
                    <FontAwesomeIcon icon={faChartBar} className="nav_icon" />
                    <span className="ms-2 sidebar-text">
                      Global Timeline Posts
                    </span>
                  </Link>
                </li>
              ) : null}

              <li className="nav-item">
                <button
                  className="nav-link align-middle px-0 d-flex align-items-center btn btn-link text-white"
                  data-bs-toggle="collapse"
                  data-bs-target="#communityCollapse"
                  aria-expanded="false"
                  aria-controls="communityCollapse"
                >
                  <FontAwesomeIcon icon={faUserFriends} className="nav_icon" />
                  <span className="ms-2 sidebar-text">Communities</span>
                </button>
                <div className="collapse" id="communityCollapse">
                  <ul className="nav flex-column ms-4 list-unstyled">
                    <li className="nav-item ">
                      <Link to="/community" className="nav-link px-0">
                        <FontAwesomeIcon icon={faUsers} className="me-2" />
                        Community
                      </Link>
                    </li>
                    <li className="nav-item" style={{ cursor: "pointer" }}>
                      <span
                        className="nav-link px-0 text-white"
                        onClick={RedirectActiveMemberTable}
                      >
                        <FontAwesomeIcon icon={faUserCheck} className="me-2" />
                        Online Community
                      </span>
                    </li>
                    {/* <li className="nav-item" style={{ cursor: "pointer" }}>
                      <span
                        className="nav-link px-0 text-white"
                        onClick={RedirectRecentMemberTable}
                      >
                        <FontAwesomeIcon icon={faClock} className="me-2" />
                        Recent Community
                      </span>
                    </li> */}
                  </ul>
                </div>
              </li>

              <li className="nav-item">
                <Link
                  to="/membership"
                  className="nav-link px-0 align-middle d-flex align-items-center"
                >
                  <FontAwesomeIcon icon={faBookmark} className="nav_icon" />
                  <span className="ms-2 sidebar-text">Membership</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/profile"
                  className="nav-link px-0 align-middle d-flex align-items-center"
                >
                  <FontAwesomeIcon icon={faUser} className="nav_icon" />
                  <span className="ms-2 sidebar-text">Profile</span>
                </Link>
              </li>
            </ul>

            <div className="dropdown pb-4">
              <a
                href="#"
                className="d-flex align-items-center text-white text-decoration-none dropdown-toggle"
                id="dropdownUser1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img
                  src={`${member?.member_logo || "/default-logo.png"}`} 
                  alt="profile"
                  width="30"
                  height="30"
                  className="rounded-circle"
                  onError={(e) => {
                    e.target.src = "/dumy.jpeg"; 
                  }}
                />
                <span className="d-none d-sm-inline mx-1 ms-2 sidebar-text">
                  user
                </span>
              </a>
              <ul
                className="dropdown-menu dropdown-menu-dark text-small shadow"
                aria-labelledby="dropdownUser1"
              >
                <Link to="/profile" className="text-decoration-none">
                  <span className="dropdown-item">
                    <FontAwesomeIcon icon={faUser} className="me-2" /> Profile
                  </span>
                </Link>

                <li>
                  <hr className="dropdown-divider" />
                </li>

                <Link to="/reset_password" className="text-decoration-none">
                  <span className="dropdown-item">
                    <FontAwesomeIcon icon={faKey} className="me-2" /> Reset
                    password
                  </span>
                </Link>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                <Link to="/" className="text-decoration-none ">
                  <a className="dropdown-item" href="#" onClick={handleClick}>
                    <FontAwesomeIcon icon={faSignOutAlt} className="nav_icon" />{" "}
                    Sign out
                  </a>
                </Link>
              </ul>
            </div>
          </div>
        </div>

        <button
          className="btn btn-dark d-md-none"
          onClick={toggleSidebar}
          style={{ position: "fixed", top: "10px", left: "10px", zIndex: 1000 }}
        >
          {isOpen ? "Hide Menu" : "Show Menu"}
        </button>

        <div
          className={`col py-3 ${isOpen ? "d-none offset-md-3" : ""}`}
          style={{ marginLeft: "auto", position: "relative", zIndex: 2 }}
        >
          {children}
          {/* <MyChatBot /> */}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
