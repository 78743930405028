import { createChatBotMessage } from 'react-chatbot-kit';
import DogPicture from './DogPicture';

const botName = 'FreightLounge Bot';


const getGreetingMessage = () => {
  const currentHour = new Date().getHours();
  if (currentHour < 12) {
    return "Good Morning! I'm FL Bot. How can I help you?";
  } else if (currentHour < 18) {
    return "Good Afternoon! I'm FL Bot. How can I help you?";
  } else {
    return "Good Evening! I'm FL Bot. How can I help you?";
  }
};


const getOptionsMessage = () => {
  return createChatBotMessage("Please select an option:", {
    widget: 'options', 
  });
};

const buttonStyle = {
  padding: '10px 15px',
  borderRadius: '8px',
  border: 'none',
  backgroundColor: 'rgb(0, 89, 164)',
  color: '#fff',
  fontSize: '16px',
  cursor: 'pointer',
  transition: 'background-color 0.3s, transform 0.2s',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
};

const config = {
  initialMessages: [
    createChatBotMessage(getGreetingMessage()),
    getOptionsMessage(), 
  ],
  widgets: [
    {
      widgetName: 'dogPicture',
      widgetFunc: (props) => <DogPicture {...props} />,
    },
    {
      widgetName: 'options',
      widgetFunc: (props) => (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
               <button
        className="btn btn-sm"
        onClick={() => props.actionProvider.handleOption1()}
        style={buttonStyle}
        onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = 'rgb(0, 100, 200)')}
        onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = 'rgb(0, 89, 164)')}
      >
        Dashboard
      </button>
      <button
        className="btn btn-sm"
        onClick={() => props.actionProvider.handleOption2()}
        style={buttonStyle}
        onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = 'rgb(0, 100, 200)')}
        onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = 'rgb(0, 89, 164)')}
      >
        Global Timeline Post
      </button>
      <button
        className="btn btn-sm"
        onClick={() => props.actionProvider.handleCommunity()}
        style={buttonStyle}
        onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = 'rgb(0, 100, 200)')}
        onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = 'rgb(0, 89, 164)')}
      >
        Community
      </button>
      <button
        className="btn btn-sm"
        onClick={() => props.actionProvider.handleMembershipProfile()}
        style={buttonStyle}
        onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = 'rgb(0, 100, 200)')}
        onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = 'rgb(0, 89, 164)')}
      >
        Membership Plan
      </button>

        </div>
      ),
    },
  ],
  botName: botName,
  customStyles: {
    botMessageBox: {
      backgroundColor: 'rgb(46, 158, 223)',
    },
    chatButton: {
      backgroundColor: 'rgb(0, 89, 164)',
    },
  },
};

export default config;
