import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  countrycode,
  MemberEmailUnique,
  CityBranch,
} from "../apis/api/DataApi";
import { Profileget, MemberInsert } from "../apis/api/ProfileApi";
import FileInput from "../hooks/FileInput";
import Cookies from "js-cookie";
import Select from "react-select";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import Loading from "../hooks/Loading";
import useFormValidation from "../hooks/useFormValidation";
import { useForm, Controller } from "react-hook-form";

const Addmember = (props) => {
  const dispatch = useDispatch();
  const useId = Cookies.get("userId");
  const {
    register,
    handleSubmit,
    setValue,
    control,
    reset,
    formState: { errors },
  } = useForm();

  const { handleChange } = useFormValidation({});

  const [file, setFile] = useState(null);
  const [role, setRole] = useState(null);
  const [error, setError] = useState("");

  const Apicall = async () => {
    const userId = await Cookies.get("userId");
    const company_id = Cookies.get("company_id");
    await dispatch(CityBranch({ company_id: company_id }));
  };

  useEffect(() => {
    dispatch(countrycode());
    Apicall();
  }, [dispatch]);

  const { country_code, citybranch } = useSelector((state) => state.dataSlice);
  let memberData = useSelector((state) => state.profileSlice.profile);

  const countryCodeOptions = country_code?.data || [];
  const city_branch = citybranch?.data || [];

  const loading = useSelector((state) => state.profileSlice.loading);
  const userJSON = JSON.parse(Cookies.get("userJSON"));

  const titleOptions = [
    { value: "Mr", label: "Mr" },
    { value: "Mrs", label: "Mrs" },
    { value: "Ms", label: "Ms" },
  ];

  const departmentOptions = [
    { value: "Management", label: "Management" },
    { value: "Sales", label: "Sales" },
    { value: "Operations", label: "Operations" },
    { value: "Financial", label: "Financial" },
  ];

  const handleTitleChange = (selectedOption, label) => {
    handleChange({
      target: { name: label, value: selectedOption.value },
    });
  };

  const handledepartmentChange = (selectedOption, label) => {
    handleChange({
      target: { name: label, value: selectedOption.value },
    });
  };

  const handleCountryCodeChange = (selectedOption, label) => {
    handleChange({
      target: { name: label, value: selectedOption.value },
    });
  };

  const handleLocationChange = (selectedOption, label) => {
    handleChange({
      target: { name: label, value: selectedOption.value },
    });
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.value;
    setFile(selectedFile);
  };

  const onSubmitHandler = async (formData) => {
    if (role === null) {
      setError("Member Access is required.");
      return;
    }
    setError("");
    const data = new FormData();
    const company_id = Cookies.get("company_id");
    const registration_type = Cookies.get("sorce_type");
    const userId = Cookies.get("userId");
    Object.keys(formData).forEach((key) => {
      if (
        typeof formData[key] === "object" &&
        formData[key] !== null &&
        formData[key] !== undefined &&
        "value" in formData[key]
      ) {
        data.append(key, formData[key].value);
      } else {
        if (formData[key] == undefined) {
          data.append(key, "");
        } else {
          data.append(key, formData[key]);
        }
      }
    });

    if (file) {
      data.append("file", file);
    }
    data.append("Member_Access", role);
    data.append("company_id", company_id);
    data.append("sender_user_id", userId);
    data.append("registration_type", registration_type);
    data.append("limit", userJSON.extra_contact_limit);

    memberData?.data?.members.forEach((member) => {
      if (member.id == userId) {
        data.append("membership_since", member.membership_since);
      }
    });

    const response = await dispatch(MemberInsert(data));

    if (response?.payload?.status === "success") {
      await dispatch(Profileget({ company_id: company_id, member_id: userId }));
      props.onHide();
      toast.success("Member Inserted Successfully!");
      reset();
      setRole(null);
      setFile(null);
    } else if (response?.payload?.status === "error") {
      toast.error(response?.payload?.msz);
    } else {
      toast.error("Failed to Update Member Details.");
      toast.error(" Member aleredy exist.");
    }
  };

  const validateEmail = async (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      return "Invalid email format";
    }
    const response = await dispatch(MemberEmailUnique({ email: email }));
    if (response.payload.unique == 1) {
      return "Email already exists";
    }
    return true;
  };

  const handleClose = () => {
    reset();
    props.onHide();
  };

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        // onHide={props.onHide}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <span className="text-bold">
              Add New Contact
              <span className="form-title no-border"></span>
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Loading loading={loading} />
          <div className="row mx-1">
            <form
              method="post"
              onSubmit={handleSubmit(onSubmitHandler)}
              encType="multipart/form-data"
            >
              <div className="form-container">
                <div className="d-block border rounded my-2 px-2 py-0">
                  <div className="position-relative text-left">
                    <span className="d-inline bg-primary text-white border rounded px-2 py-1 button-theme">
                      <span className="text-bold">
                        Contact Information
                        <span className="form-title no-border"></span>
                      </span>
                    </span>
                    <div className="row">
                      <div className="row mt-2">
                        <div className="col-md-12">
                          <FileInput
                            name="file"
                            label="Profile Picture"
                            handleChange={handleFileChange}
                          />
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <label className="form-label">
                              Title <span className="text-danger">*</span>
                            </label>
                            <Controller
                              name="Title"
                              control={control}
                              rules={{ required: "Title is required" }}
                              render={({ field }) => (
                                <Select
                                  {...field}
                                  options={titleOptions}
                                  onChange={(selectedOption) => {
                                    if (selectedOption) {
                                      field.onChange(selectedOption);
                                      handleTitleChange(selectedOption);
                                    } else {
                                      field.onChange(null);
                                    }
                                  }}
                                />
                              )}
                            />
                            {errors.Title && (
                              <span className="text-danger">
                                {errors.Title.message}
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="col-md-4">
                          <label>First Name</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="First Name"
                            {...register("FirstName", {
                              required: "First Name is required",
                            })}
                          />
                          {errors.FirstName && (
                            <span className="text-danger">
                              {errors.FirstName.message}
                            </span>
                          )}
                        </div>
                        <div className="col-md-4">
                          <label>Middle Name</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Middle Name"
                            {...register("MiddleName")}
                          />
                        </div>
                        <div className="col-md-4">
                          <label>LastName</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Last Name"
                            {...register("LastName", {
                              required: "Last Name is required",
                            })}
                          />
                          {errors.LastName && (
                            <span className="text-danger">
                              {errors.LastName.message}
                            </span>
                          )}
                        </div>

                        <div className="col-md-12">
                          <div className="form-group">
                            <label className="form-label">
                              Location <span className="text-danger">*</span>
                            </label>
                            <Controller
                              name="Location"
                              control={control}
                              rules={{ required: "Location is required" }}
                              render={({ field }) => (
                                <Select
                                  {...field}
                                  options={city_branch}
                                  onChange={(selectedOption) => {
                                    if (selectedOption) {
                                      field.onChange(selectedOption);
                                      handleLocationChange(selectedOption);
                                    } else {
                                      field.onChange(null);
                                    }
                                  }}
                                />
                              )}
                            />
                            {errors.Location && (
                              <span className="text-danger">
                                {errors.Location.message}
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="form-label">
                              Job Title <span class="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control border border-grey border-1 rounded-1  Removefocus"
                              placeholder="Job Title"
                              {...register("JobTitle", {
                                required: "Job Title is required",
                              })}
                            />
                            {errors.JobTitle && (
                              <span className="text-danger">
                                {errors.JobTitle.message}
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="form-label">
                              Department <span className="text-danger">*</span>
                            </label>
                            <Controller
                              name="Department"
                              control={control}
                              rules={{ required: "Department is required" }}
                              render={({ field }) => (
                                <Select
                                  {...field}
                                  options={departmentOptions}
                                  onChange={(selectedOption) => {
                                    if (selectedOption) {
                                      field.onChange(selectedOption);
                                      handledepartmentChange(selectedOption);
                                    } else {
                                      field.onChange(null);
                                    }
                                  }}
                                />
                              )}
                            />
                            {errors.Department && (
                              <span className="text-danger">
                                {errors.Department.message}
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="form-group mb-3">
                            <label className="form-label">
                              Phone Number Direct{" "}
                            </label>
                            <div className="d-flex Removefocus w-100">
                              <Controller
                                name="country_code_direct"
                                control={control}
                                // rules={{ required: "Country Code is required" }}
                                render={({ field }) => (
                                  <Select
                                    {...field}
                                    options={countryCodeOptions}
                                    onChange={(selectedOption) => {
                                      if (selectedOption) {
                                        field.onChange(selectedOption);
                                        handleCountryCodeChange(selectedOption);
                                      } else {
                                        field.onChange("");
                                      }
                                    }}
                                  />
                                )}
                              />
                              <input
                                type="number"
                                className="form-control border border-grey border-1 rounded-1  Removefocus w-60"
                                placeholder="area code"
                                {...register("areacode")}
                              />
                              <input
                                type="text"
                                className="form-control border border-grey border-1 rounded-1  Removefocus w-60"
                                placeholder="Number"
                                {...register("phone_no_direct")}
                              />
                            </div>
                            {/* {errors.country_code_direct && (
                              <div className="text-danger">
                                {errors.country_code_direct.message}
                              </div>
                            )}

                            {errors.phone_no_direct && (
                              <div className="text-danger">
                                {errors.phone_no_direct.message}
                              </div>
                            )} */}
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="form-group">
                            <label className="form-label">Extension</label>
                            <input
                              type="text"
                              className="form-control border border-grey border-1 rounded-1  Removefocus"
                              placeholder="Extension"
                              {...register("Extension")}
                            />
                            {errors.Extension && (
                              <div className="text-danger">
                                {errors.Extension.message}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group mb-3">
                            <label className="form-label">
                              Phone Number Mobile{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <div className="d-flex">
                              <Controller
                                name="country_code"
                                control={control}
                                rules={{ required: "Country Code is required" }}
                                render={({ field }) => (
                                  <Select
                                    {...field}
                                    options={countryCodeOptions}
                                    onChange={(selectedOption) => {
                                      if (selectedOption) {
                                        field.onChange(selectedOption);
                                        handleCountryCodeChange(selectedOption);
                                      } else {
                                        field.onChange(null);
                                      }
                                    }}
                                  />
                                )}
                              />
                              <input
                                type="tel"
                                className="form-control border border-grey border-1 rounded-1"
                                placeholder="Number"
                                {...register("Mobile", {
                                  required: "Contact No is required",
                                })}
                              />
                            </div>
                            {errors.country_code_direct && (
                              <div className="text-danger">
                                {errors.country_code_direct.message}
                              </div>
                            )}

                            {errors.Mobile && (
                              <div className="text-danger">
                                {errors.Mobile.message}
                              </div>
                            )}
                          </div>
                        </div>
                        {/* <div className="col-md-12">
                          <div className="form-group">
                            <label className="form-label">
                              Email address{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control border border-grey border-1 rounded-1"
                              placeholder="Email address"
                              {...register("email", {
                                required: "Email is required",
                              })}
                            />
                            {errors.email && (
                              <span className="text-danger">
                                {errors.email.message}
                              </span>
                            )}
                          </div>
                        </div> */}

                        <div className="col-md-12">
                          <div className="form-group">
                            <label className="form-label">
                              Email address{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control border border-grey border-1 rounded-1"
                              placeholder="Email address"
                              {...register("email", {
                                required: "Email is required",
                                validate: validateEmail,
                              })}
                            />
                            {errors.email && (
                              <span className="text-danger">
                                {errors.email.message}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <label className="form-label">
                              Member Access{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <div className="d-flex">
                              <div className="form-check me-3">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  id="admin"
                                  name="role"
                                  value="1"
                                  onChange={() => setRole(1)}
                                  checked={role == 1}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="admin"
                                >
                                  Admin
                                </label>
                              </div>

                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  id="member"
                                  name="role"
                                  value="0"
                                  onChange={() => setRole(0)}
                                  checked={role == 0}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="member"
                                >
                                  Member
                                </label>
                              </div>
                            </div>

                            {error && (
                              <span className="text-danger">{error}</span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12 mt-2">
                <button type="submit" className="btn button-theme">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Addmember;
