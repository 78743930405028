import { createSlice } from "@reduxjs/toolkit";
import { WizardInsert,reset,Forget } from "../api/WizardApi";

const WizardSlice = createSlice({
    name: "wizardSlice",
    initialState: {
      loading: false,
      wizard: null,

    },
    reducers:{
        // signup_details :(state, action)=>{
        // state.signup_detail="";
       
        // },

        // Login_detail :(state, action)=>{
        //   state.Login_detail="";
       
        //   }

    },

    extraReducers: (builder) => {
      builder.addCase(WizardInsert.pending, state => {
        state.loading = true
      })
      builder.addCase(WizardInsert.fulfilled,  (state, action) => {
       state.wizard=action.payload;
        state.loading = false
       
      })
      builder.addCase(WizardInsert.rejected, (state,action) => {
       
        state.loading = false
      })

   
      builder.addCase(reset.pending, state => {
        state.loading = true
      })
      builder.addCase(reset.fulfilled,  (state, action) => {
       state.wizard=action.payload;
        state.loading = false
       
      })
      builder.addCase(reset.rejected, (state,action) => {
      
        state.loading = false
      })

      builder.addCase(Forget.pending, state => {
        state.loading = true
      })
      builder.addCase(Forget.fulfilled,  (state, action) => {
       state.wizard=action.payload;
        state.loading = false
       
      })
      builder.addCase(Forget.rejected, (state,action) => {
      
        state.loading = false
      })

    
    }
  })

  //Actions
//   export const { signup_details } = authSlice.actions;
  
  //Reducer
  export default WizardSlice.reducer;