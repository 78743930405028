import React, { useState, useRef, useCallback } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import Cropper from "react-easy-crop";
import { Profileget, profileUpdate } from "../apis/api/ProfileApi";
import Loading from "../hooks/Loading";
import { toast } from "react-toastify";
import getCroppedImg from "../hooks/cropImage"; // Function to process the cropped image

function UploadProfile(props) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [fileName, setFileName] = useState("");
  const [fileSize, setFileSize] = useState("");
  const [cannotUpload, setCannotUpload] = useState(false);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
  const [file, setFile] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [showCropper, setShowCropper] = useState(false);

  const fileInputRef = useRef(null);
  const uploadedFileRef = useRef(null);

  // Handle file change (when user selects a file)
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setFileName(selectedFile.name);
      setFileSize((selectedFile.size / 1024).toFixed(1) + " KB");
      uploadedFileRef.current.style.display = "flex";

      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreviewUrl(reader.result); // Set the image preview
        setShowCropper(true); // Show the cropper once image is loaded
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  // Handle drag over event for drag-and-drop
  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  // Handle drop event for drag-and-drop
  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const selectedFile = e.dataTransfer.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setFileName(selectedFile.name);
      setFileSize((selectedFile.size / 1024).toFixed(1) + " KB");
      uploadedFileRef.current.style.display = "flex";

      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreviewUrl(reader.result); 
        setShowCropper(true); 
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels); 
  }, []);

  
  const handleUploadClick = async () => {
    if (!file) {
      setCannotUpload(true);
      return; 
    }

    setLoading(true);

    
    if (croppedAreaPixels && imagePreviewUrl) {
      try {
        const croppedImage = await getCroppedImg(imagePreviewUrl, croppedAreaPixels); 

        
        const response = await fetch(croppedImage);
        const blob = await response.blob();
        const croppedFile = new File([blob], fileName, { type: blob.type });

        
        const formData = new FormData();
        const company_id = Cookies.get("company_id");
        formData.append("file", croppedFile); 
        formData.append("company_id", company_id);
        const userId = Cookies.get("userId");

        
        const uploadResponse = await dispatch(profileUpdate(formData));

        if (uploadResponse.payload.status === "success") {
          const Response = await dispatch(Profileget({ company_id: company_id, member_id: userId }));
          if (Response.payload.status === "success") {
            toast.success("Profile Upload Successful!");
          }
        } else {
          toast.error("Profile Upload Failed!");
        }
      } catch (error) {
        console.error("Cropping or Uploading failed: ", error);
        toast.error("An error occurred while processing the image.");
      } finally {
        setLoading(false);
        handleModalClose();
        props.onHide();
      }
    } else {
      setCannotUpload(true);
      setLoading(false);
    }
  };

  
  const handleModalClose = () => {
    setImagePreviewUrl(null);
    setFileName("");
    setFileSize("");
    setFile(null);
    setShowCropper(false);
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => {
        handleModalClose();
        props.onHide();
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Upload Profile Picture</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Loading loading={loading} />
        <form className="form-container" encType="multipart/form-data">
          <div className="upload-files-container">
            
            {!showCropper && (
              <div
                className={`drag-file-area ${imagePreviewUrl ? "d-none" : ""}`}
                onDrop={handleDrop}
                onDragOver={handleDragOver}
              >
                <h5 className="dynamic-message">Drag & drop any file here</h5>
                <label className="label me-5 w-100">
                  or
                  <span className="browse-files">
                    <input
                      type="file"
                      className="default-file-input"
                      ref={fileInputRef}
                      onClick={() => (fileInputRef.current.value = "")}
                      onChange={handleFileChange}
                    />
                    <span className="browse-files-text">browse file</span>
                    <span>from device</span>
                  </span>
                </label>
              </div>
            )}

          
            {showCropper && imagePreviewUrl && (
              <div className="cropper-container" style={{ height: 500, width: 500, position: "relative" }}>
                <Cropper
                  image={imagePreviewUrl}
                  crop={crop}
                  zoom={zoom}
                  onCropChange={setCrop}
                  onZoomChange={setZoom}
                  onCropComplete={onCropComplete}
                  aspect={undefined} 
                />
              </div>
            )}

            
            {cannotUpload && (
              <span className="cannot-upload-message">
                <span className="material-icons-outlined">error</span>
                Please select a file first
                <span
                  className="material-icons-outlined cancel-alert-button"
                  onClick={() => setCannotUpload(false)}
                >
                  cancel
                </span>
              </span>
            )}

            
            {!showCropper && imagePreviewUrl && (
              <div className="image-preview-container mt-3">
                <h5>Image Preview:</h5>
                <img
                  src={imagePreviewUrl}
                  alt="Image preview"
                  className="image-preview"
                  height="200"
                />
              </div>
            )}

            
            <div className="file-block" ref={uploadedFileRef} style={{ display: "none" }}>
              <div className="file-info">
                <span className="material-icons-outlined file-icon">description</span>
                <span className="file-name">{fileName}</span> |{" "}
                <span className="file-size">{fileSize}</span>
              </div>
              <span
                className="material-icons remove-file-icon ms-4"
                onClick={() => {
                  setImagePreviewUrl(null);
                  setFileName("");
                  setFileSize("");
                  setFile(null);
                  setShowCropper(false);
                }}
              >
                delete
              </span>
            </div>

          
            <button type="button" className="upload-button" onClick={handleUploadClick}>
              Upload
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default UploadProfile;
