import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch,useSelector } from "react-redux";
import { galleryEdit ,galleryGet} from "../apis/api/GalleryApi";
import Loading from "../hooks/Loading";
import { toast } from "react-toastify";
import Cookies from "js-cookie";

const EditAlbumModal = ({ showModal, closeModal, albumData, updateAlbum }) => {
  const dispatch = useDispatch();

  const [albumTitle, setAlbumTitle] = useState(albumData?.title || "");
  const [mediaFiles, setMediaFiles] = useState(
    albumData?.file_url?.map((url) => ({
      preview: url,
      isNew: false,
      id: url,
    })) || []
  );
  const [removedFiles, setRemovedFiles] = useState([]);
  
  const loading = useSelector((state) => state.gallerySlice.loading);

  useEffect(() => {
    setAlbumTitle(albumData?.title || "");
    setMediaFiles(
      albumData?.file_url?.map((url) => ({
        preview: url,
        isNew: false,
        id: url,
      })) || []
    );
    setRemovedFiles([]);
  }, [albumData]);

  const handleFileChange = (e) => {
    const files = e.target.files;
    if (files) {
      setMediaFiles((prevFiles) => [
        ...prevFiles,
        ...Array.from(files).map((file) => ({
          file,
          preview: URL.createObjectURL(file),
          isNew: true,
          id: URL.createObjectURL(file),
        })),
      ]);
    }
  };

  const removeFile = (fileIndex) => {
    const fileToRemove = mediaFiles[fileIndex];
    setMediaFiles((prevFiles) =>
      prevFiles.filter((_, index) => index !== fileIndex)
    );

    if (!fileToRemove.isNew) {
      setRemovedFiles((prevRemovedFiles) => [
        ...prevRemovedFiles,
        fileToRemove.id,
      ]);
    }
  };

  const handleTitleChange = (e) => {
    setAlbumTitle(e.target.value);
  };

  const handleSave =async () => {
    const oldFiles = [];
    const newFiles = [];
    
    const user_id = Cookies.get("userId");
    const company_id = Cookies.get("company_id");

    mediaFiles.forEach((media) => {
      if (!removedFiles.includes(media.id)) {
        if (!media.isNew && media.preview) {
          oldFiles.push(media.preview);
        }
      }
    });

    mediaFiles.forEach((media) => {
      if (media.isNew && media.file) {
        newFiles.push(media.file);
      }
    });

    const formData = new FormData();
    formData.append("title", albumTitle);

    oldFiles.forEach((oldFile) => {
      formData.append("file_url[]", oldFile);
    });

    newFiles.forEach((newFile) => {
      formData.append("Files", newFile);
    });
    formData.append("album_id", albumData.album_id);
    formData.append("user_id", user_id);
    formData.append("company_id", company_id);
    
    const response= await dispatch(galleryEdit(formData))
    if(response.payload.status=='success'){
      updateAlbum(response.payload.data);
      console.log(response.payload.data)
      const response2= await dispatch(galleryGet({ user_id, company_id }));
      if(response2.payload.status=='success'){
        toast.success("Album edited successful!");
        closeModal();
      }
     
    }
    

  };

  return (
    <>
    
    <Modal show={showModal} size="lg" centered onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Album</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Loading loading={loading} />
        <div className="container">
          <div className="mb-3">
            <button
              className="btn btn-secondary bg-theme-color btn-sm mt-2 ms-3"
              type="button"
              style={{ position: "absolute", top: "10px", left: "10px" }}
              onClick={() => document.getElementById("fileInput").click()}
            >
              <i className="fas fa-upload"></i> Upload
            </button>

            <input
              id="fileInput"
              type="file"
              multiple
              className="d-none"
              onChange={handleFileChange}
            />
          </div>

          <div className="mb-3 mt-5">
            <label htmlFor="albumTitle" className="form-label">
              Album Title
            </label>
            <input
              type="text"
              className="form-control"
              id="albumTitle"
              value={albumTitle}
              onChange={handleTitleChange}
              placeholder="Enter album title"
            />
          </div>

          <div className="row">
            <label className="form-label">Uploaded Albums</label>
            {mediaFiles.map((media, index) => (
              <div key={index} className="col-xl-3 col-xxl-3 col-sm-12 col-md-6 position-relative">
                <button
                  type="button"
                  className="btn btn-danger btn-sm position-absolute top-0 end-0 zindex-2"
                  onClick={() => removeFile(index)}
                >
                  <i className="fas fa-times"></i>
                </button>
                <div className="media-item mt-3">
                
                  {(media.preview?.endsWith(".webm") ||
                    media.preview?.endsWith(".mp4") ||
                    media.preview?.endsWith(".mov") ||
                    media.file?.type.startsWith("video")) ? (
                    <video controls src={media.preview} className="img-fluid" />
                  ) : (
                 
                    <img
                      src={media.preview}
                      alt={`Media ${index + 1}`}
                      className="img-fluid"
                    />
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-secondary bg-theme-color"
          onClick={handleSave}
        >
          Save Changes
        </button>
      </Modal.Footer>
    </Modal>
    </>
    
  );
};

export default EditAlbumModal;
