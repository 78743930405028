import React, { useState, useEffect } from "react";

import useFormValidation from "../hooks/useFormValidation";
import { useSelector, useDispatch } from "react-redux";
import {
  statelist,
  countrylist,
  countrycode,
  services,
} from "../apis/api/DataApi";
import { serviceInsert } from "../apis/api/SignupApi";
import Cookies from "js-cookie";
import Select from "react-select";
import { toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import { useLocation } from 'react-router-dom';
import { useNavigate } from "react-router-dom";

const BranchDetailsAndServices = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [states, setStates] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");

  const location = useLocation(); 
  const { user_data }= location.state || {};
  
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();
 
  const {  handleChange } = useFormValidation({});

  useEffect(() => {
    dispatch(countrylist());
    dispatch(countrycode());
    dispatch(statelist());
    dispatch(services());
  }, [dispatch]);

  const { country_list, state_list, country_code, service } = useSelector(
    (state) => state.dataSlice
  );

  const countryOptions = country_list?.data || [];
  const stateOptions = state_list?.data || [];
  const countryCodeOptions = country_code?.data || [];
  const service_data = service?.data || [];

  useEffect(() => {
    if (selectedCountry) {
      setStates(stateOptions[selectedCountry]);
    } else {
      setStates([]);
    }
  }, [selectedCountry]);

  const handleCountryChange = (selectedOption, label) => {
    setSelectedCountry(selectedOption.value);
    handleChange({
      target: { name: label, value: selectedOption.value },
    });
  };
  const handleStateChange = (selectedOption, label) => {
    handleChange({
      target: { name: label, value: selectedOption.value },
    });
  };

  const onSubmitHandler = async (formData) => {
    const data = new FormData();
    const company_id = user_data.company_id;

   
    Object.keys(formData).forEach((key) => {
   
      if (typeof formData[key] === "object" && formData[key] !== null) {
        if (Array.isArray(formData[key])) {
          formData[key].forEach((item, index) => {
            if (item.value) {
              data.append(`${key}[${index}]`, item.value);
            } else {
              data.append(`${key}[${index}]`, item);
            }
          });
        } 
     
        else {
          if (key === "company" && formData[key].country) {
            data.append("country", formData[key].country.value);
          } else if (key === "Company" && formData[key].state) {
            data.append("state", formData[key].state.value);
          } else if (formData[key]?.value) {
            data.append(key, formData[key].value); 
          } else {
            Object.keys(formData[key]).forEach((subKey) => {
              const subItem = formData[key][subKey];
              if (subItem.value) {
                data.append(`${key}[${subKey}]`, subItem.value);
              }
            });
          }
        }
      } 
      else {
        data.append(key, formData[key]);
      }
    });
    data.append("company_id", company_id);
  
    const response = await dispatch(serviceInsert(data));
  
    if (response?.payload?.status === "success") {
      toast.success("Branch Details Updated Successfully!");
      Cookies.set("userId", user_data.userId, { expires: 1 });
      Cookies.set("company_id", company_id, { expires: 1 });
      Cookies.set("sorce_type", user_data.registration_type, { expires: 1 });
      Cookies.set("userJSON", user_data.userJSON, { expires: 1 });
      Cookies.set("member_type", user_data.member_type, { expires: 1 });

      navigate("/home");
      toast.success("Login Successfull!");

    } else {
      toast.error("Failed to Update Branch Details.");
    }
  };
  
  

  return (
    <div className="container-fluid d-flex justify-content-center align-items-center">
      <div className="bg-white mt-4 vh-100 rounded">
        <h4 className="text-center mb-4 pt-3">Branch Details</h4>
        <form method="post" onSubmit={handleSubmit(onSubmitHandler)}>
          <div className="form-container">
            <div className="d-block border rounded my-2 px-2 py-0">
              <div className="position-relative text-left">
                <span className="d-inline bg-theme-color text-white border rounded px-2 py-1 ">
                  <span className=" text-sm ">Branch Details</span>
                </span>
                <div className="row mt-2">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">
                        Country Name <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="company[country]"
                        control={control}
                        rules={{ required: "Country is required" }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={countryOptions}
                            onChange={(selectedOption) => {
                              // Ensure selectedOption is not null or undefined before accessing its properties
                              if (selectedOption) {
                                field.onChange(selectedOption);
                                handleCountryChange(selectedOption);
                              } else {
                                field.onChange(null);
                              }
                            }}
                            placeholder={"Select the country"}
                          />
                        )}
                      />
                      {errors.company?.country && (
                        <span className="text-danger">
                          {errors.company.country.message}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">
                        State Name <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="Company[state]"
                        control={control}
                        rules={{ required: "State is required" }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={states}
                            onChange={(selectedOption) => {
                              field.onChange(selectedOption);
                              handleStateChange(
                                selectedOption,
                                "Company[state]"
                              );
                            }}
                            placeholder={"select the state"}
                          />
                        )}
                      />
                      {errors.Company?.state && (
                        <span className="text-danger">
                          {errors.Company.state.message}
                        </span>
                      )}
                    </div>
                  </div>

                  {/* City Name */}
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">
                        City Name <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control border border-grey border-1 rounded-1"
                        placeholder="City Name"
                        {...register("CityName", {
                          required: "City Name is required",
                        })}
                      />
                      {errors.CityName && (
                        <span className="text-danger">
                          {errors.CityName.message}
                        </span>
                      )}
                    </div>
                  </div>

                  {/* Employee Strength */}
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">
                        Employee Strength <span className="text-danger">*</span>
                      </label>
                      <input
                        type="number"
                        className="form-control border border-grey border-1 rounded-1"
                        placeholder="Employee Strength"
                        {...register("EmployeeStrength", {
                          required: "Employee Strength is required",
                        })}
                      />
                      {errors.EmployeeStrength && (
                        <span className="text-danger">
                          {errors.EmployeeStrength.message}
                        </span>
                      )}
                    </div>
                  </div>

                  {/* Contact Number */}
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label className="form-label">
                        Contact Number <span className="text-danger">*</span>
                      </label>
                      <div className="d-flex">
                        <Select
                          options={countryCodeOptions}
                          onChange={(selectedOption) =>
                            setValue("country_code", selectedOption.value)
                          }
                          className="country-code-select h-1rem w-100"
                        />
                        <input
                          type="text"
                          className="form-control border border-grey border-1 rounded-1"
                          placeholder="Number"
                          {...register("Mobile", {
                            required: "Contact No is required",
                          })}
                        />
                      </div>
                      {errors.country_list && (
                        <span className="text-danger">
                          {errors.country_list.message}
                        </span>
                      )}
                      {errors.Mobile && (
                        <span className="text-danger">
                          {errors.Mobile.message}
                        </span>
                      )}
                    </div>
                  </div>
                  {/* Address */}
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">
                        Address <span className="text-danger">*</span>
                      </label>
                      <textarea
                        type="text"
                        className="form-control border border-grey border-1 rounded-1 textarea.form-control"
                        placeholder="Address"
                        {...register("Address", {
                          required: "Address is required",
                        })}
                      />
                      {errors.Address && (
                        <span className="text-danger">
                          {errors.Address.message}
                        </span>
                      )}
                    </div>
                  </div>

                  {/* Branch Name */}
                  <div className="container-fluid mt-4">
                    <div className=" mt-4">
                      <span className="d-inline bg-theme-color text-white border rounded px-2 py-1">
                        <span className="text-bold">Services</span>
                      </span>
                      <div className="row mx-1">
                        <form onSubmit={onSubmitHandler} className="w-100">
                          <div className="d-block my-2 px-2 py-0">
                            <div className="row mt-2">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="form-label">
                                    Services{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <Controller
                                    name="base_service"
                                    control={control}
                                    rules={{ required: "Service is required" }}
                                    render={({ field }) => (
                                      <Select
                                        {...field}
                                        options={service_data.base_service}
                                        isMulti
                                        onChange={(selectedOptions) => {
                                          field.onChange(selectedOptions);
                                          setValue(
                                            "base_service",
                                            selectedOptions
                                          );
                                        }}
                                        placeholder="Select services"
                                      />
                                    )}
                                  />
                                  {errors.base_service && (
                                    <span className="text-danger">
                                      {errors.base_service.message}
                                    </span>
                                  )}
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="form-label text-sm">
                                    Special services{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <Controller
                                    name="special_service"
                                    control={control}
                                    rules={{
                                      required: "Special service is required",
                                    }}
                                    render={({ field }) => (
                                      <Select
                                        {...field}
                                        options={service_data.special_service}
                                        isMulti
                                        onChange={(selectedOptions) => {
                                          field.onChange(selectedOptions);
                                          setValue(
                                            "special_service",
                                            selectedOptions
                                          );
                                        }}
                                        placeholder="Select special services"
                                      />
                                    )}
                                  />
                                  {errors.special_service && (
                                    <span className="text-danger">
                                      {errors.special_service.message}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-12 mt-4 text-center">
            <button type="submit" className="btn  bg-theme-color">
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default BranchDetailsAndServices;
