import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { ResendOtp, VerifyOtp } from "../../apis/api/SignupApi";
import Cookies from "js-cookie";
import useFormValidation from "../../hooks/useFormValidation";
import Loading from "../../hooks/Loading";

const validate = (formData) => {
  const errors = {};
  if (!formData.otp) errors.username = "OTP is required";
  return errors;
};

const ForgetOTP = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const initialState = { otp: "" };
  const { formData, errors, handleChange, handleSubmit } = useFormValidation(
    initialState,
    validate
  );

  const [otp, setOtp] = useState("");
  const email = sessionStorage.getItem("otpemail");

  const initialTime = 2 * 60 * 1000;
  const [timeLeft, setTimeLeft] = useState(initialTime);
  const [buttonState, setButtonState] = useState(0);
  const [buttonSubmit, setbuttonSubmit] = useState(0);

  const inputRefs = useRef([]);

  const resendOtp = () => {
    setButtonState(0);
    sessionStorage.removeItem("endTime");
    setupTimer(initialTime, setTimeLeft, setButtonState);
    const otpId = Cookies.get("otpId");
    dispatch(ResendOtp({ otpId: otpId }));
  };

  const setupTimer = (initialTime, setTimeLeft, setButtonState) => {
    const storedEndTime = sessionStorage.getItem("endTime");
    let endTime;

    if (storedEndTime) {
      endTime = parseInt(storedEndTime, 10);
      const currentTime = new Date().getTime();
      const remainingTime = endTime - currentTime;
      setTimeLeft(remainingTime > 0 ? remainingTime : 0);
    } else {
      endTime = new Date().getTime() + initialTime;
      sessionStorage.setItem("endTime", endTime);
      setTimeLeft(initialTime);
    }

    const interval = setInterval(() => {
      const currentTime = new Date().getTime();
      const remainingTime = endTime - currentTime;
      setTimeLeft(remainingTime > 0 ? remainingTime : 0);

      if (remainingTime <= 0) {
        setTimeLeft("");
        setButtonState(1);
        clearInterval(interval);
        sessionStorage.removeItem("endTime");
      }
    }, 1000);

    return () => clearInterval(interval);
  };

  useEffect(() => {
    const cleanupTimer = setupTimer(initialTime, setTimeLeft, setButtonState);

    return () => cleanupTimer();
  }, [initialTime]);

  const formatTime = (milliseconds) => {
    if (milliseconds) {
      const totalSeconds = Math.floor(milliseconds / 1000);
      const minutes = Math.floor(totalSeconds / 60);
      const seconds = totalSeconds % 60;
      return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
        2,
        "0"
      )}`;
    }
  };

  const onSubmit = async () => {
    const otpId = Cookies.get("otpId");
    try {
      const response = await dispatch(
        VerifyOtp({ otp_id: otpId, otp: formData.otp })
      );

      if (response.payload.status === "success") {
        localStorage.clear();
        sessionStorage.clear();
        Cookies.remove("otpId");
        const userJSON = JSON.stringify(response.payload.Data.Membership);

        const userId = response.payload.Data.memberData.member_id;
        const company_id = response.payload.Data.memberData.company_id;
        const registration_type =
          response.payload.Data.memberData.registration_type;
        const token = response.payload.token;
        const status = response.payload.Data.memberData.status;
        const member_type = response.payload.Data.memberData.member_type;

        Cookies.set("token", token, { expires: 1 });

        Cookies.set("userId", userId, { expires: 1 });
        Cookies.set("company_id", company_id, { expires: 1 });
        Cookies.set("sorce_type", registration_type, { expires: 1 });
        Cookies.set("userJSON", userJSON, { expires: 1 });
        Cookies.set("member_type", member_type, { expires: 1 });
        
        navigate("/Forget_Password");
      } else {
        toast.error("Invalid OTP!");
        navigate("/otp");
      }
    } catch (error) {
      toast.error("Invalid OTP!");
      navigate("/otp");
    }
  };

  return (
    <div className="auth-page">
      <div className="container-fluid p-0">
        <div className="row g-0">
          <div className="col-xxl-3 col-lg-4 col-md-5 text-center">
            <div className="auth-full-page-content d-flex p-sm-5 p-4">
              <div className="w-100">
                <div className="d-flex flex-column h-100">
                  <div className="mb-4 mb-md-5 text-center">
                    <a href="index" className="d-block auth-logo">
                      <img src="FLlogo.png" alt="" height="50" />
                    </a>
                  </div>
                  <div className="auth-content mt-3">
                    <div className="text-center">
                      <h3 className="mb-3">Enter Verification Code</h3>
                    </div>
                    <div className="text-center">
                      <p className="text-muted w-100 mt-2 ps-3 pe-3">
                        We have just sent verification code to {email}
                      </p>
                    </div>
                    <form
                      className="mt-4 pt-2"
                      onSubmit={(e) => handleSubmit(e, onSubmit)}
                    >
                      {/* <form className="mt-4 pt-2 form-submit"> */}
                      <div className="row mb-4 text-center">
                        <div className="inputs d-flex flex-row justify-content-center mt-2">
                          {/* <input
                              key={index}
                              className="m-2 text-center form-control rounded"
                              type="text"
                            
                              value={data}
                              onChange={(e) => handleOtpChange(e.target, index)}
                              onKeyDown={(e) => handleKeyDown(e, index)}
                              ref={(el) => (inputRefs.current[index] = el)}
                            /> */}

                          <input
                            type="text"
                            name="otp"
                            className="form-control fc-enter"
                            id="otp"
                            placeholder="Enter OTP"
                            value={formData.otp}
                            onChange={handleChange}
                          />
                          {errors.otp && (
                            <span className="error-text">{errors.otp}</span>
                          )}
                        </div>
                      </div>
                      <div className="mb-5 text-center">
                        <button
                          type="submit"
                          className="btn btn-success w-100 fs-5 rounded-pill"
                        >
                          <i className="fa-regular fa-circle-check me-2 fs-5"></i>
                          Verify
                        </button>
                      </div>
                      <div className="mb-3 text-center text-wrap">
                        <button
                          type="button"
                          className="btn btn-outline-danger w-70 waves-effect waves-light rounded-pill text-wrap"
                          onClick={resendOtp}
                          disabled={buttonState === 0}
                        >
                          <i className="fa-solid fa-rotate-right me-2 "></i>Send
                          the code again!
                        </button>
                        <div>{formatTime(timeLeft)}</div>
                      </div>
                    </form>
                  </div>
                  <div className="mt-4 mt-md-5 text-center">
                    <p className="mb-0">© MVP Freight Lounge</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xxl-9 col-lg-8 col-md-7 rt-col">
            <div className="auth-bg pt-md-5 p-4 d-flex">
              <div className="bg-overlay bg-primary1"></div>
              <ul className="bg-bubbles">
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
              </ul>
              <div className="row justify-content-center align-items-center">
                <div className="col-xl-7">
                  <div className="p-0 p-sm-4 px-xl-0">{/* Content Here */}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgetOTP;
