import Modal from "react-bootstrap/Modal";
import React, { useState, useEffect } from "react";
import useFormValidation from "../hooks/useFormValidation";
import { useSelector, useDispatch } from "react-redux";
import { statelist, countrylist, countrycode,citylist } from "../apis/api/DataApi";
import { BranchAdd } from "../apis/api/ProfileApi";
import Cookies from "js-cookie";
import Select from "react-select";
import { toast } from "react-toastify";
import Loading from "../hooks/Loading";
import { useForm, Controller } from "react-hook-form";
import { Profileget } from "../apis/api/ProfileApi";

const BranchDetailsModal = ({ showModal, closeModal }) => {
  const dispatch = useDispatch();
  const [city, setCity] = useState([]);
  // const [selectedCountry, setSelectedCountry] = useState("");
  const loading = useSelector((state) => state.profileSlice.loading);

  const branch = JSON.parse(Cookies.get("userJSON"));
  let memberData = useSelector((state) => state.profileSlice.profile);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    reset,
    formState: { errors },
  } = useForm();

  const { handleChange } = useFormValidation({});

  useEffect(() => {
    dispatch(countrylist());
    dispatch(countrycode());
    dispatch(citylist());
  }, [dispatch]);

  const { country_list, city_list, country_code } = useSelector(
    (state) => state.dataSlice
  );


  const countryOptions = country_list?.data || [];
  const cityOptions = city_list?.data || [];
  const countryCodeOptions = country_code?.data || [];

  useEffect(() => {
    // Check if memberData exists to avoid unnecessary updates
    const countryNameValue = memberData?.data?.company?.[0]?.country;
    if (countryNameValue) {
      const countryNameLabel = countryNameValue?.split(":")[1]?.trim();
      setValue("country", {
        value: countryNameValue,
        label: countryNameLabel,
      });
    }
  }, [showModal]);

  // const handleCountryChange = (selectedOption, label) => {
  //   // setSelectedCountry(selectedOption.value);
  //   handleChange({
  //     target: { name: label, value: selectedOption.value },
  //   });
  // };

  // const handlecityChange = (selectedOption, label) => {
  //   handleChange({
  //     target: { name: label, value: selectedOption.value },
  //   });
  // };

  const onSubmitHandler = async (formData) => {

    const data = new FormData();
    const company_id = Cookies.get("company_id");
    const userId = Cookies.get("userId");
 

    const branchIdsByMemberType = memberData.data.members.filter((member) => member.member_type == 1).map((member) => member.branch_id);

    const branchIdByUserId = memberData.data.members.find((member) => member.id == userId)?.branch_id;

    const idsByMemberType = memberData.data.members.filter((member) => member.member_type == 1).map((member) => member.id);
    
    
    Object.keys(formData).forEach((key) => {
      if (typeof formData[key] === "object" && formData[key] !== null && "value" in formData[key]) {
        // If it's an object with a "value" property, append only that value
        data.append(key, formData[key].value);
      } else {
        // Otherwise, append it as-is
        data.append(key, formData[key]);
      }
    });


    
    data.append("company_id", company_id);
    data.append("userId", userId);
    data.append("branch_limit", branch.branch_office_limit);
    data.append("branchIds_Mainmember", branchIdsByMemberType);
    data.append("branchIdsByUserId", branchIdByUserId);
    data.append("IdMain_Member", idsByMemberType);

    const response = await dispatch(BranchAdd(data));

    
    if (response?.payload?.status === "success") {
        const profile = await dispatch(Profileget({ company_id:company_id,member_id:userId }));
        if (profile.payload.status === "success") {
            toast.success("Branch Details Updated Successfully!");
            closeModal();
            reset();
        }
    } else if (response?.payload?.status === "error") {
        toast.error(response?.payload?.msz);
    } else {
        toast.error("Failed to Update Branch Details.");
    }
};
const selectedCountry = watch("country");

  const handleClose = () => {
    reset();
    closeModal();
  };
  
  
  return (
    <Modal
      show={showModal}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <span className="text-bold">
            Add New Branch
            <span className="form-title no-border"></span>
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Loading loading={loading} />
        <div className="row mx-1">
          <form
            method="post"
            onSubmit={handleSubmit(onSubmitHandler)}
            encType="multipart/form-data"
          >
            <div className="form-container">
              <div className="d-block border rounded my-2 px-2 py-0">
                <div className="position-relative text-left">
                  <span className="d-inline bg-primary text-white border rounded px-2 py-1 button-theme">
                    <span className="text-bold">
                      Branch Information
                      <span className="form-title no-border"></span>
                    </span>
                  </span>
                  <div className="row">
                    <div className="row mt-2">
                     

                       
                      {/* City Name */}
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="form-label">
                          Address <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control border border-grey border-1 rounded-1"
                            placeholder="Address"
                            {...register("Address", {
                              required: "Address is required",
                            })}
                          />
                          {errors.Address && (
                            <span className="text-danger">
                              {errors.Address.message}
                            </span>
                          )}
                        </div>
                      </div>

                      {/* house no */}
                      <div className="col-md-6">
                          <div className="form-group">
                            <label className="form-label">
                              House No <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control border border-grey border-1 rounded-1"
                              placeholder="House Number"
                              {...register("house_no", {
                                required: "House Number is required",
                              })}
                            />
                            {errors.house_no && (
                              <span className="text-danger">
                                {errors.house_no.message}
                              </span>
                            )}
                          </div>
                        </div>

                        {/* Zip Code */}
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="form-label">
                              Zip Code <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control border border-grey border-1 rounded-1"
                              placeholder="Zip Code"
                              {...register("ZipCode", {
                                required: "Zip Code is required",
                              })}
                            />
                            {errors.ZipCode && (
                              <span className="text-danger">
                                {errors.ZipCode.message}
                              </span>
                            )}
                          </div>
                        </div>

                      {/* City Name */}
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="form-label">
                            City Name <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control border border-grey border-1 rounded-1"
                            placeholder="City Name"
                            {...register("CityName", {
                              required: "City Name is required",
                            })}
                          />
                          {errors.CityName && (
                            <span className="text-danger">
                              {errors.CityName.message}
                            </span>
                          )}
                        </div>
                      </div> 
                      {/* State Name */}
                      {/* <div className="col-md-6">
                        <div className="form-group">
                          <label className="form-label">
                            City Name <span className="text-danger">*</span>
                          </label>
                          <Controller
                            name="city"
                            control={control}
                            rules={{ required: "city is required" }}
                            render={({ field }) => (
                              <Select
                                {...field}
                                options={city}
                                onChange={(selectedOption) => {
                                  field.onChange(selectedOption);
                                  handlecityChange(
                                    selectedOption,
                                    "city"
                                  );
                                }}
                                placeholder={"select the state"}
                              />
                            )}
                          />
                          {errors?.city && (
                            <span className="text-danger">
                              {errors.city.message}
                            </span>
                          )}
                        </div>
                      </div> */}
                      {/* City Name */}
                      <div className="col-md-12">
                        <div className="form-group">
                          <label className="form-label">
                            Country Name <span className="text-danger">*</span>
                          </label>
                          <Select
                              options={countryOptions}
                              value={selectedCountry}
                              onChange={(selectedOption) =>
                                setValue("country", selectedOption)
                              }
                              isDisabled
                            />
                          {/* <Controller
                            name="country"
                            control={control}
                            rules={{ required: "Country is required" }}
                            render={({ field }) => (
                              <Select
                                {...field}
                                options={countryOptions}
                                onChange={(selectedOption) => {
                                  if (selectedOption) {
                                    field.onChange(selectedOption);
                                    handleCountryChange(selectedOption);
                                  } else {
                                    field.onChange(null);
                                  }
                                }}
                                placeholder={"Select the country"}
                              />
                            )}
                          /> */}
                          {errors?.country && (
                            <span className="text-danger">
                              {errors?.country.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-12">
                          <div className="form-group">
                            <label className="form-label">
                              Email <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control border border-grey border-1 rounded-1"
                              placeholder="City Name"
                              {...register("Email", {
                                required: "Email is required",
                              })}
                            />
                            {errors.Email && (
                              <span className="text-danger">
                                {errors.Email.message}
                              </span>
                            )}
                          </div>
                        </div>

                      {/* Contact Number */}
                      <div className="col-12">
                        <div className="form-group">
                          <label className="form-label">
                            Phone Number{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <div className="d-flex">
                            <Controller
                              name="countryCode"
                              className="w-100"
                              control={control}
                              rules={{ required: "Country code is required" }}
                              render={({ field }) => (
                                <Select
                                  {...field}
                                  options={countryCodeOptions}
                                  onChange={(selectedOption) => {
                                    field.onChange(selectedOption);
                                    handleChange({
                                      target: {
                                        name: "countryCode",
                                        value: selectedOption.value,
                                      },
                                    });
                                  }}
                                  placeholder={"Code"}
                                  className="me-2 "
                                  styles={{
                                    container: (provided) => ({
                                      ...provided,
                                      width: "45%",
                                    }),
                                  }}
                                />
                              )}
                            />
                            <input
                              type="text"
                              className="form-control border border-grey border-1 rounded-1"
                              placeholder="Number"
                              {...register("ContactNumber", {
                                required: "Contact Number is required",
                              })}
                            />
                          </div>
                          {errors.countryCode && (
                              <span className="text-danger">
                                {errors.countryCode.message}
                              </span>
                            )}

                          {errors.ContactNumber && (
                            <span className="text-danger">
                              {errors.ContactNumber.message}
                            </span>
                          )}
                        </div>
                      </div>

                      {/* Address */}
                      {/* <div className="col-md-12">
                        <div className="form-group">
                          <label className="form-label">
                            Branch Address{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <textarea
                            className="form-control border border-grey border-1 rounded-1"
                            placeholder="Enter Address"
                            rows="3"
                            {...register("address", {
                              required: "Branch Address is required",
                            })}
                          />
                          {errors.address && (
                            <span className="text-danger">
                              {errors.address.message}
                            </span>
                          )}
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
                <button
                  type="submit"
                  className="btn btn-secondary bg-theme-color my-2 px-4"
                  disabled={loading}
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default BranchDetailsModal;
