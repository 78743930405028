import React, { useEffect, useState } from "react";

export default function WebSocketTest() {
  const [connectionStatus, setConnectionStatus] = useState("Connecting...");
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const socketUrl = "wss://clubnet.app/dev_node/connection"; // Ensure it's WebSocket (wss://)

  useEffect(() => {
    const socket = new WebSocket(socketUrl);

    // On connection open
    socket.onopen = () => {
      console.log("WebSocket connected");
      setConnectionStatus("Connected");
    };

    // On message received from the server
    socket.onmessage = (event) => {
      console.log("Message received:", event.data);
      setMessages((prev) => [...prev, `Server: ${event.data}`]);
    };

    // On WebSocket close
    socket.onclose = () => {
      console.log("WebSocket disconnected");
      setConnectionStatus("Disconnected");
    };

    // On WebSocket error
    socket.onerror = (error) => {
      console.error("WebSocket error:", error);
      setConnectionStatus("Error");
    };

    // Cleanup on component unmount
    return () => {
      socket.close();
    };
  }, [socketUrl]);

  // Send message to WebSocket server
  const sendMessage = () => {
    if (inputMessage.trim() !== "") {
      const socket = new WebSocket(socketUrl);
      socket.onopen = () => {
        socket.send(inputMessage); // Send the input message to the server
        setMessages((prev) => [...prev, `You: ${inputMessage}`]); // Update local messages
        setInputMessage(""); // Clear the input field
      };
    }
  };

  return (
    <div style={{ padding: "20px", maxWidth: "600px", margin: "auto" }}>
      <h2>WebSocket Chat</h2>
      <p>Status: {connectionStatus}</p>
      
      <div
        style={{
          border: "1px solid black",
          padding: "10px",
          height: "200px",
          overflowY: "scroll",
          marginBottom: "10px",
        }}
      >
        {messages.map((msg, index) => (
          <div key={index}>{msg}</div>
        ))}
      </div>

      <input
        type="text"
        value={inputMessage}
        onChange={(e) => setInputMessage(e.target.value)}
        placeholder="Type a message"
        style={{ width: "80%", padding: "8px", marginBottom: "10px" }}
      />
      <button
        onClick={sendMessage}
        style={{
          padding: "8px 16px",
          cursor: "pointer",
          marginLeft: "10px",
          backgroundColor: "#4CAF50",
          color: "white",
          border: "none",
        }}
      >
        Send
      </button>
    </div>
  );
}
