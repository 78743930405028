import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Forget } from '../../apis/api/WizardApi';
import Cookies from "js-cookie";
import { toast } from "react-toastify";

const ResetPassword = () => {

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
 
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordMatchError, setPasswordMatchError] = useState("");
  const [passwordStrength, setPasswordStrength] = useState(""); 
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const checkPasswordStrength = (password) => {
    if (password.length < 6 ) {
      setPasswordStrength("Invalid - Password must be greater than 6 characters.");

    } else if (!/[A-Z]/.test(password)) {
      setPasswordStrength("Weak - Add an uppercase letter.");
    } else if (!/[a-z]/.test(password)) {
      setPasswordStrength("Weak - Add a lowercase letter.");
    } else if (!/[0-9]/.test(password)) {
      setPasswordStrength("Weak - Add a number.");
    } else if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
      setPasswordStrength("Weak - Add a special character.");
    } else {
      setPasswordStrength("Strong - Your password meets all requirements.");
    }
  };
  
  const clearAllCookies = () => {
    const allCookies = Cookies.get();
    Object.keys(allCookies).forEach((cookie) => {
      Cookies.remove(cookie);
    });
  };
  const handleNewPasswordChange = (e) => {
    const value = e.target.value;
    setNewPassword(value);
    checkPasswordStrength(value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const company_id = Cookies.get("company_id");
    const userId = Cookies.get("userId");

    // Password match validation
    if (newPassword !== confirmPassword) {
      setPasswordMatchError("New password and confirm password do not match.");
      return;
    }

    // Reset any previous error
    setPasswordMatchError("");

    const formData = new FormData();

    formData.append("newPassword", newPassword);
    formData.append("confirmPassword", confirmPassword);
    formData.append("company_id", company_id);
    formData.append("userId", userId);

    const response = await dispatch(Forget(formData));
    if (response.payload.status === "success"){
      clearAllCookies();
      navigate("/");
      navigate("/");
      toast.success("password Reset Successfull!");
  }
 
  };

  return (
    <div className="mainDiv" style={{ backgroundColor: "#FAFBFC" }}>
      <div className="cardStyle">
      <div className="logo-container" style={{ textAlign: "center", marginBottom: "20px" }}>
          <img src="/FLlogo.png" alt="Logo" style={{ width: "200px", height: "auto" }} />
        </div>

        <form id="signupForm" onSubmit={handleSubmit}>
          <h5 className="formTitle">Reset your account password</h5>

         

          {/* New Password Field */}
          <div className="inputDiv">
            <label className="inputLabel" htmlFor="newPassword">
              New Password
            </label>
            <div className="inputWithIcon">
              <input
                type={showNewPassword ? "text" : "password"}
                id="newPassword"
                name="newPassword"
                required
                placeholder="Enter new password"
                value={newPassword}
                onChange={handleNewPasswordChange}
              />
              <button
                type="button"
                onClick={() => setShowNewPassword(!showNewPassword)}
                className="eyeButton"
              >
                {showNewPassword ? "👁️" : "👁️‍🗨️"}
              </button>
            </div>
            {/* Display password strength */}
            <div className="password-strength" style={{
              color: passwordStrength.includes("Strong") ? "green" : "red"
            }}>
              {passwordStrength}
            </div>
          </div>

          {/* Confirm Password Field */}
          <div className="inputDiv">
            <label className="inputLabel" htmlFor="confirmPassword">
              Confirm Password
            </label>
            <div className="inputWithIcon">
              <input
                type={showConfirmPassword ? "text" : "password"}
                id="confirmPassword"
                name="confirmPassword"
                placeholder="Confirm new password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <button
                type="button"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                className="eyeButton"
              >
                {showConfirmPassword ? "👁️" : "👁️‍🗨️"}
              </button>
            </div>
          </div>

          {/* Password Match Error */}
          {passwordMatchError && (
            <div className="error-message" style={{ color: "red" }}>
              {passwordMatchError}
            </div>
          )}

          {/* Submit Button */}
          <div className="buttonWrapper">
            <button
              type="submit"
              id="submitButton"
              className="submitButton pure-button pure-button-primary"
            >
              <span>Submit</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
