import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom"; 
import Modal from "react-bootstrap/Modal";
import Loading from "../hooks/Loading";
import { galleryGet } from "../apis/api/GalleryApi";

function ImageGallery({ user_id, company_id }) {
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Initialize useNavigate
  const [selectedImage, setSelectedImage] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        await dispatch(galleryGet({ user_id, company_id }));
     
      } catch (error) {
        console.error("Error fetching gallery data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [dispatch, user_id, company_id]);

  const galleryData =
    useSelector((state) => state.gallerySlice.gallery?.data) || [];

  useEffect(() => {
    
  }, [galleryData]);

  const handleImageClick = (albumId) => {
    navigate(`/album/${albumId}`); 
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedImage(null);
  };

  if (loading) {
    return <Loading />;
  }

  if (galleryData.length === 0) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <div>No Album found.</div>
      </div>
    );
  }

  return (
    <div className="container-fluid image-gallery">
      <div className="row gallery-grid">
        {galleryData.map((item) =>
          item.file_url && item.file_url.length > 0 ? (
            <div
              key={item.id}
              className="col-md-3 col-6 gallery-item"
              onClick={() => handleImageClick(item.id)} 
            >

              <div
                className="image-box"
                style={{
                  position: "relative",
                  padding: "5px",
                  border: "2px solid #ddd",
                  borderRadius: "8px",
                  overflow: "hidden",
                }}
              >
                <div className="text-center mb-2">
                  <h5>{item.title}</h5>
                </div>
                {/* Check for the number of images in the album */}
                {item.file_url.length === 1 ? (
                  <img
                    src={item.file_url[0]} // Display the single image
                    className="img-fluid modal-image" // Add className
                    onError={(e) => {
                      e.target.src = "/no-attrony.png"; // Fallback image
                    }}
                    style={{
                      width: "100%",
                      height: "auto",
                      borderRadius: "4px",
                    }} 
                  />
                ) : (
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "repeat(2, 1fr)",
                      gap: "5px",
                    }}
                  >
                    {item.file_url.slice(0, 4).map((fileUrl, index) => (
                      <img
                        key={index}
                        src={fileUrl}
                        alt={`Album ${item.title} Image ${index + 1}`}
                        className="img-fluid modal-image" // Add className
                        onError={(e) => {
                          e.target.src = "/no-attrony.png"; // Fallback image
                        }}
                        style={{
                          width: "100%",
                          height: "auto",
                          borderRadius: "4px",
                        }} 
                      />
                    ))}
                  </div>
                )}
              </div>
            </div>
          ) : null
        )}
      </div>

      <Modal show={showModal} onHide={closeModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Image Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          {selectedImage && (
            <img
              src={selectedImage}
              alt="Selected"
              className="img-fluid modal-image" // Add className
              onError={(e) => {
                e.target.src = "/no-attrony.png"; // Fallback image
              }}
            />
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ImageGallery;
