import { createSlice } from "@reduxjs/toolkit";
import { membership,MembershipUpgrade } from "../api/MembershipApi";


const membershipSlice = createSlice({

    name: "membershipSlice",
    initialState: {
        loading: false,
        membership_detail: '',
    
    },
    reducers:{
      
    },

    extraReducers: (builder) => {
// membership plan
      builder.addCase(membership.pending, state => {
        state.loading = true
      })
      builder.addCase(membership.fulfilled,  (state, action) => {
       state.membership_detail=action.payload;
        state.loading = false
      })
      builder.addCase(membership.rejected, (state,action) => {
    
        state.loading = false
      })

      // MembershipUpgrade plan
      builder.addCase(MembershipUpgrade.pending, state => {
        state.loading = true
      })
      builder.addCase(MembershipUpgrade.fulfilled,  (state, action) => {
        state.loading = false
      })
      builder.addCase(MembershipUpgrade.rejected, (state,action) => {
        state.loading = false
      })




    }
  })
  export default membershipSlice.reducer;