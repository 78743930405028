import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import FileInput from "../hooks/FileInput";
import { services } from "../apis/api/DataApi";
import { WizardInsert } from "../apis/api/WizardApi";
import Cookies from "js-cookie";
import { Profileget } from "../apis/api/ProfileApi";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const steps = [
  { id: 1, title: "Services", iconClass: "fa fa-folder-open" },
  { id: 2, title: "Profile", iconClass: "fa fa-user" },
  { id: 3, title: "Password", iconClass: "fa fa-paper-plane" },
];

function MultiStepForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(1);

  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [company_logo, setcompany_logo] = useState(null);
  const [bannerLogo, setbannerLogo] = useState(null);
  const [memberLogo, setmemberLogo] = useState(null);
  const {
    control,
    handleSubmit,
    setValue,
    register,
    formState: { errors },
  } = useForm();
  const [passwordStrength, setPasswordStrength] = useState("");

  // Function to check password strength
  const checkPasswordStrength = (password) => {
    if (password.length < 5 || password.length > 8) {
      setPasswordStrength("Invalid - must be 5-8 characters");
    } else if (!/[A-Z]/.test(password)) {
      setPasswordStrength("Weak - add an uppercase letter");
    } else if (!/[0-9]/.test(password)) {
      setPasswordStrength("Weak - add a number");
    } else if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
      setPasswordStrength("Weak - add a special character");
    } else {
      setPasswordStrength("Strong");
    }
  };
  useEffect(() => {
    dispatch(services());
  }, []);

  const { service } = useSelector((state) => state.dataSlice);

  const service_data = service?.data || [];

  const nextStep = () => {
    if (currentStep < steps.length) {
      setCurrentStep(currentStep + 1);
    }
  };

  const prevStep = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const onSubmitHandler = async (formData) => {
    if (formData.newPassword === formData.confirmPassword) {
      const data = new FormData();
    
      const company_id = Cookies.get("company_id");
      const userId = Cookies.get("userId");

      const baseServiceValues = formData.base_service
        ? formData.base_service.map((service) => service.value)
        : [];
      const specialServiceValues = formData.special_service
        ? formData.special_service.map((service) => service.value)
        : [];

      data.append("base_service", baseServiceValues);
      data.append("special_service", specialServiceValues);

      // Append other form data
      Object.keys(formData).forEach((key) => {
        if (key !== "base_service" && key !== "special_service") {
          // Skip original structures
          if (typeof formData[key] === "object" && formData[key] !== null) {
            Object.keys(formData[key]).forEach((subKey) => {
              data.append(`${key}[${subKey}]`, formData[key][subKey]);
            });
          } else {
            data.append(key, formData[key]);
          }
        }
      });

      data.append("company_id", company_id);
      data.append("userId", userId);

      if (company_logo) {
        data.append("company_logo", company_logo);
      }
      if (bannerLogo) {
        data.append("banner_logo", bannerLogo);
      }
      if (memberLogo) {
        data.append("member_logo", memberLogo);
      }

      const response = await dispatch(WizardInsert(data));
      if (response.payload.status == "success") {
        const profile = await dispatch(Profileget({ company_id }));
        if (profile.payload.status == "success") {
          navigate("/profile");
          toast.success("Profile Updated Success");
        }
      } else if (response.payload.status == "error") {
        toast.error(response.payload.msg);
      } else {
        toast.success("Error!");
      }
    } else {
      toast.error("New password and confirm password does not match!");
    }
  };

  const company_logoChange = (event) => {
    const selectedFile = event.target.value;
    setcompany_logo(selectedFile);
  };

  const bannerLogoChange = (event) => {
    const selectedFile = event.target.value;
    setbannerLogo(selectedFile);
  };

  const memberLogoChange = (event) => {
    const selectedFile = event.target.value;
    setmemberLogo(selectedFile);
  };

  const renderStepContent = () => {
    switch (currentStep) {
      case 1:
        return (
          <form onSubmit={handleSubmit(nextStep)}>
            <div className="container-fluid mt-4">
              <div className="row mx-1">
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="form-label">
                      Services <span className="text-danger">*</span>
                    </label>
                    <Controller
                      name="base_service"
                      control={control}
                      rules={{ required: "Service is required" }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={service_data.base_service}
                          isMulti
                          onChange={(selectedOptions) => {
                            field.onChange(selectedOptions);
                            setValue("base_service", selectedOptions);
                          }}
                          placeholder="Select services"
                        />
                      )}
                    />
                    {errors.base_service && (
                      <span className="text-danger">
                        {errors.base_service.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-12 mt-4">
                  <div className="form-group">
                    <label className="form-label text-sm">
                      Special Services <span className="text-danger">*</span>
                    </label>
                    <Controller
                      name="special_service"
                      control={control}
                      rules={{ required: "Special service is required" }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={service_data.special_service}
                          isMulti
                          onChange={(selectedOptions) => {
                            field.onChange(selectedOptions);
                            setValue("special_service", selectedOptions);
                          }}
                          placeholder="Select special services"
                        />
                      )}
                    />
                    {errors.special_service && (
                      <span className="text-danger">
                        {errors.special_service.message}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="mt-3 d-flex justify-content-center">
                <button
                  type="submit"
                  className="btn btn-secondary bg-theme-color"
                >
                  Next
                </button>
              </div>
            </div>
          </form>
        );

      case 2:
        return (
          <form onSubmit={handleSubmit(nextStep)}>
            <div className="form-row">
              <div className="col-md-6">
                <FileInput
                  name="company_logo"
                  label="Company Logo"
                  handleChange={company_logoChange}
                />
              </div>
              <div className="col-md-6">
                <FileInput
                  name="bannerLogo"
                  label="Banner Logo"
                  handleChange={bannerLogoChange}
                />
              </div>
            </div>

            <div className="form-row">
              <div className="col-md-6">
                <FileInput
                  name="memberLogo"
                  label="Profile Picture"
                  handleChange={memberLogoChange}
                />
              </div>
            </div>

            <div className="mt-5 d-flex justify-content-center">
              <button
                type="submit"
                className="btn btn-secondary bg-theme-color"
              >
                Next
              </button>
            </div>
          </form>
        );

      case 3:
        return (
          <form onSubmit={handleSubmit(onSubmitHandler)}>
            <div className="container">
              <div className="">
                <h5 className="formTitle">Reset your account password</h5>
                <div className="row">
                  <div className="col-md-6">
                    <div className="inputDivv position-relative">
                      <label
                        className="inputLabel fs-6"
                        htmlFor="currentPassword"
                      >
                        Current Password
                      </label>
                      <input
                        type={showCurrentPassword ? "text" : "password"}
                        id="currentPassword"
                        name="currentPassword"
                        className="form-control"
                        {...register("currentPassword", { required: true })}
                        placeholder="Enter current password"
                        style={{ paddingRight: "2rem" }} // Space for icon
                      />
                      <span
                        className="position-absolute"
                        onClick={() =>
                          setShowCurrentPassword(!showCurrentPassword)
                        }
                        style={{
                          top: "80%", // Center the icon vertically
                          right: "10px",
                          transform: "translateY(-50%)", // Vertically center the icon
                          cursor: "pointer",
                        }}
                      >
                        {showCurrentPassword ? <FaEyeSlash /> : <FaEye />}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="inputDivv position-relative">
                      <label className="inputLabel fs-6" htmlFor="newPassword">
                        New Password
                      </label>
                      <input
                        type={showNewPassword ? "text" : "password"}
                        id="newPassword"
                        name="newPassword"
                        className="form-control"
                        {...register("newPassword", { required: true })}
                        placeholder="Enter new password"
                        style={{ paddingRight: "2rem" }} // Space for icon
                        onChange={(e) => checkPasswordStrength(e.target.value)} // Check strength on change
                      />
                      <span
                        className="position-absolute"
                        onClick={() => setShowNewPassword(!showNewPassword)}
                        style={{
                          top: "80%",
                          right: "10px",
                          transform: "translateY(-50%)",
                        }}
                      >
                        {showNewPassword ? <FaEyeSlash /> : <FaEye />}
                      </span>
                      {/* Password strength feedback container */}
                    </div>
                    <div className="mt-2">
                      <small
                        className={`password-strength ${passwordStrength.toLowerCase()}`}
                      >
                        {passwordStrength}
                      </small>
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-6">
                    <div className="inputDivv position-relative">
                      <label
                        className="inputLabel fs-6"
                        htmlFor="confirmPassword"
                      >
                        Confirm Password
                      </label>
                      <input
                        type={showConfirmPassword ? "text" : "password"}
                        id="confirmPassword"
                        name="confirmPassword"
                        className="form-control"
                        {...register("confirmPassword", { required: true })}
                        placeholder="Confirm new password"
                        style={{ paddingRight: "2rem" }} // Space for icon
                      />
                      <span
                        className="position-absolute"
                        onClick={() =>
                          setShowConfirmPassword(!showConfirmPassword)
                        }
                        style={{
                          top: "80%",
                          right: "10px",
                          transform: "translateY(-50%)",
                          cursor: "pointer",
                        }}
                      >
                        {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="buttonWrapper mt-4 d-flex justify-content-center">
                  <button
                    type="submit"
                    className="btn btn-secondary bg-theme-color"
                  >
                    <span>Submit</span>
                  </button>
                </div>
              </div>
            </div>
          </form>
        );

      default:
        return null;
    }
  };

  return (
    <div className="container multi-step-form">
      <StepNavigation
        steps={steps}
        currentStep={currentStep}
        goToStep={setCurrentStep}
      />
      <div className="step-content">{renderStepContent()}</div>
      <div className="step-wrapper">
        <button
          type="button"
          className="btn btn-primary"
          onClick={prevStep}
          disabled={currentStep === 1}
        >
          Back
        </button>
        {currentStep < steps.length ? (
          <button type="button" className="btn btn-primary" onClick={nextStep}>
            Next
          </button>
        ) : (
          <button
            type="submit"
            className="btn btn-primary"
            onClick={handleSubmit(onSubmitHandler)}
          >
            Submit
          </button>
        )}
      </div>
    </div>
  );
}

function StepNavigation({ steps, currentStep, goToStep }) {
  return (
    <ol className="step-indicator">
      {steps.map((step) => (
        <li
          key={step.id}
          className={`step-item ${
            step.id === currentStep
              ? "active"
              : step.id < currentStep
              ? "complete"
              : ""
          }`}
          onClick={() => goToStep(step.id)}
        >
          <div className="step">
            <i className={step.iconClass}></i>
          </div>
          <div className="caption">
            Step {step.id}: {step.title}
          </div>
        </li>
      ))}
    </ol>
  );
}

export default MultiStepForm;
