import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import AddVideo from "./AddVideo";
import { VideoDelete, MediaGet } from "../../apis/api/GalleryApi";
import Loading from "../../hooks/Loading";
import { toast } from "react-toastify";
import Cookies from "js-cookie";

function VideoGallery() {
  const dispatch = useDispatch();

  const [selectedVideo, setSelectedVideo] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const [addvideo, setaddvideo] = useState(false);
  const handleCloseVideo = () => setaddvideo(false);
  const user_id = Cookies.get("userId");
  const company_id = Cookies.get("company_id");

  let { media, loading } = useSelector((state) => state.gallerySlice);
  const videoGalleryData = media?.data?.videos || [];

  // Function to handle the video click to open in modal
  const handleVideoClick = (videoUrl) => {
    console.log("Selected video URL:", videoUrl); // Debugging line
    setSelectedVideo(videoUrl); // Set the selected video URL
    setShowModal(true); // Show the modal
  };

  // Close the modal
  const closeModal = () => {
    setShowModal(false);
    setSelectedVideo(null); // Reset the selected video
  };

  // Go back to the previous page
  const handleBack = () => {
    navigate(-1);
  };

  // Handle video deletion
  const handleDelete = async (id) => {
    const response = await dispatch(VideoDelete(id));

    if (response.payload.status === "success") {
      const response = await dispatch(MediaGet({ company_id: company_id, user_id: user_id }));
      if (response.payload.status === "success") {
        toast.success("Video Delete Successful!");
      }
    } else if (response.payload.status === "error") {
      toast.error(response.payload.msz);
    } else {
      toast.success("Error occurred");
    }
  };

  return (
    <>
      <AddVideo AddVideo={addvideo} onClose={handleCloseVideo} />
      <Loading loading={loading} />
      <div className="container-fluid video-gallery">
        <div className="d-flex justify-content-between align-items-center mt-4">
          <button
            className="btn btn-secondary bg-theme btn-sm mb-3 me-4"
            onClick={() => setaddvideo(true)}
          >
            <i className="fa fa-plus me-2 ms-1"></i>Add Videos
          </button>

          <button
            className="btn btn-secondary bg-theme btn-sm mb-3 ms-auto"
            onClick={handleBack}
          >
            <i className="fa fa-arrow-left me-2 ms-1"></i>Back
          </button>
        </div>

        <div className="row gallery-grid mt-2">
          {videoGalleryData.map((item) =>
            item.file_url ? (
              <div
                key={item.id}
                className="col-xl-3 col-md-6 col-sm-12 mt-5 gallery-item"
              >
                <div className="card" style={{ width: "100%" }}>
                  <div className="position-relative">
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDelete(item.id);
                      }}
                      className="btn btn-danger btn-sm position-absolute top-0 end-0 z-index-2 m-2"
                    >
                      <i className="fas fa-trash"></i>
                    </button>
                    <div
                      className="card-img-top"
                      style={{
                        padding: "5px",
                        borderRadius: "8px",
                        overflow: "hidden",
                      }}
                    >
                      <video
                        src={Array.isArray(item.file_url) ? item.file_url[0] : item.file_url}
                        className="img-fluid"
                        controls={false}
                        muted
                        onClick={() => handleVideoClick(Array.isArray(item.file_url) ? item.file_url[0] : item.file_url)}  // Open modal on click
                        onError={(e) => {
                          e.target.src = "/no-video.png";
                        }}
                        style={{
                          width: "100%",
                          height: "auto",
                          borderRadius: "4px",
                        }}
                      />
                    </div>
                  </div>
                  <div className="card-body">
                    <h6 className="card-title text-center">{item.tittle}</h6>
                  </div>
                </div>
              </div>
            ) : null
          )}
        </div>

        {/* Modal for video preview */}
        <Modal show={showModal} onHide={closeModal} centered>
          <Modal.Header closeButton>
            <Modal.Title>Video Preview</Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-center">
            {selectedVideo && (
              <video
                src={selectedVideo}  // The selected video URL
                controls
                autoPlay
                className="img-fluid modal-video"
                onError={(e) => {
                  e.target.src = "/no-video.png";
                }}
                style={{
                  width: "100%",
                  height: "auto",
                  borderRadius: "4px",
                }}
              />
            )}
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}

export default VideoGallery;
