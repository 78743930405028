import React, { useState, useEffect } from "react";
import "../Signin.css";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import useFormValidation from "../../hooks/useFormValidation";
import { verifyEmail } from "../../apis/api/SignupApi";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loading from "../../hooks/Loading";
import Cookies from "js-cookie";

const validate = (formData) => {
  const errors = {};
  if (!formData.username) errors.username = "Email is required";

  return errors;
};

function Forget() {
  const initialState = { username: "", password: "" };
  const { formData, errors, handleChange, handleSubmit } = useFormValidation(
    initialState,
    validate
  );

  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { Login_detail, loading } = useSelector((state) => state.authSlice);

  const onSubmit = async () => {
    try {
      const response = await dispatch(verifyEmail(formData));

      if (response.payload.status == "success") {
       
        const Id = response.payload.otp_id;

        const email = response.payload.email;
        Cookies.set("otpId", Id, { expires: 1 });
        let id = Cookies.get("otpId");
        sessionStorage.setItem("otpemail", email);
        navigate("/Forgetotp");
      }
    } catch (error) {
      toast.error("User Not Found");
    //   navigate("/");
      console.error(error);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <Loading loading={loading} />
      <div className="container-fluid p-0">
        <div className="row g-0">
          <div className="col-xxl-3 col-lg-4 col-md-5">
            <div className="auth-full-page-content d-flex p-sm-5 p-4">
              <div className="w-100">
                <div className="d-flex flex-column h-100">
                  <div className="mb-4 mb-md-5  d-flex align-items-center justify-content-around">
                    <img src="FLlogo.png" alt="" height="50" />
                  </div>
                  <div className="auth-content my-auto">
                    <div className="text-center">
                      {/* <h5 className="mb-0">Welcome Back !</h5> */}
                      <p className="text-muted mt-2">
                        Enter email id to forget password...
                      </p>
                    </div>
                    <form
                      className="mt-4 pt-2"
                      onSubmit={(e) => handleSubmit(e, onSubmit)}
                    >
                      <div className="mb-3">
                        <label className="form-label">Email ID</label>
                        <input
                          type="text"
                          name="username"
                          className="form-control fc-enter"
                          id="username"
                          placeholder="Enter Email "
                          value={formData.username}
                          onChange={handleChange}
                        />
                        {errors.username && (
                          <span className="error-text">{errors.username}</span>
                        )}
                      </div>

                      <div className="mb-3">
                        <button
                          className="btn btn-primary w-100 waves-effect waves-light"
                          type="submit"
                        >
                          Forget
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xxl-9 col-lg-8 col-md-7 rt-col">
            <div className="auth-bg pt-md-5 p-4 d-flex">
              <div className="bg-overlay bg-primary1"></div>
              <ul className="bg-bubbles">
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
              </ul>
              <div className="row justify-content-center align-items-center">
                <div className="col-xl-7">
                  <div className="p-0 p-sm-4 px-xl-0">
                    <div
                      id="reviewcarouselIndicators"
                      className="carousel slide"
                      data-bs-ride="carousel"
                    >
                      <div className="carousel-indicators carousel-indicators-rounded justify-content-start ms-0 mb-0">
                        <button
                          type="button"
                          data-bs-target="#reviewcarouselIndicators"
                          data-bs-slide-to="0"
                          className="active"
                          aria-current="true"
                          aria-label="Slide 1"
                        ></button>
                        <button
                          type="button"
                          data-bs-target="#reviewcarouselIndicators"
                          data-bs-slide-to="1"
                          aria-label="Slide 2"
                        ></button>
                        <button
                          type="button"
                          data-bs-target="#reviewcarouselIndicators"
                          data-bs-slide-to="2"
                          aria-label="Slide 3"
                        ></button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center align-items-center">
                <div className="col-xl-7">
                  <div className="p-0 p-sm-4 px-xl-0">{/* Content Here */}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Forget;
