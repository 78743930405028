import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL, NODE_URL ,signupUrl,signinUrl } from "./Config";

export const submitSignUp = createAsyncThunk(
    "post/submitSignUp",
    async (data) => { 
        let url = BASE_URL+signupUrl
        try{
            
            const response = (await axios.post(url, data)).data
            if(response.status == "success"){  
                return Promise.resolve(response)
            } else {
                return Promise.reject(response)
            }
        }catch (error) {
           
            return Promise.reject(error)
        }
    }
)

export const submitLogin = createAsyncThunk(
    "post/submitLogin",
    async (data) => { 
        let url = BASE_URL+signinUrl
        try{
            
            const response = (await axios.post(url, data)).data
            if(response.status == "success"){
                return Promise.resolve(response)
            }else if(response.status == "error") {
                return Promise.resolve(response)
            } else {
                return Promise.reject(response)
            }
        }catch (error) {
          
            return Promise.reject(error)
        }
    }
)

export const ResendOtp = createAsyncThunk(
    "post/ResendOtp",
    async (data) => { 
        let url = BASE_URL+'auth/otp-resend'
        try{
            
            const response = (await axios.post(url, data)).data
            if(response.status == "success"){
                return Promise.resolve(response)
            } else {
                return Promise.reject(response)
            }
        }catch (error) {
          
            return Promise.reject(error)
        }
    }
)
export const verifyEmail = createAsyncThunk(
    "post/verifyEmail",
    async (data) => { 
        let url = BASE_URL+'auth/validateEmail'
        try{
            
            const response = (await axios.post(url, data)).data
            if(response.status == "success"){
                return Promise.resolve(response)
            } else {
                return Promise.reject(response)
            }
        }catch (error) {
          
            return Promise.reject(error)
        }
    }
)

export const VerifyOtp = createAsyncThunk(
    "post/VerifyOtp",
    async (data) => { 
        let url = NODE_URL+'otp'
        try{
            
            const response = (await axios.post(url, data)).data
            if(response.status == "success"){
                return Promise.resolve(response)
            } else {
                return Promise.reject(response)
            }
        }catch (error) {
         
            return Promise.reject(error)
        }
    }
)

export const serviceInsert = createAsyncThunk(
    "post/serviceInsert",
    async (data) => { 
        let url = NODE_URL+'serviceInsert'
        try{
            
            const response = (await axios.post(url, data)).data
            if(response.status == "success"){
                return Promise.resolve(response)
            } else {
                return Promise.reject(response)
            }
        }catch (error) {
            return Promise.reject(error)
        }
    }
)



