import React, { useState, useEffect } from "react";
import useFormValidation from "../hooks/useFormValidation";
import FileInput from "../hooks/FileInput";
import { useSelector, useDispatch } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Loading from "../hooks/Loading";

import {
  countrylist,
  statelist,
  membershipPlan,
  countrycode,
  MemberEmailUnique,
  CompanyEmailUnique
} from "../apis/api/DataApi";
import { ProfileInsert } from "../apis/api/ProfileApi";
import Select from "react-select";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import "./Registation.css";
import ReCAPTCHA from "react-google-recaptcha";

const MemberRegistration = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState(null);
  const [defaultPlan, setdefaultPlan] = useState("platinum");
  const [loadingg, setLoadingg] = useState(false);

  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isEmailUnique, setIsEmailUnique] = useState(null); 

  const [ismemberEmailValid, setIsmemberEmailValid] = useState(true);
  const [ismemberEmailUnique, setIsmemberEmailUnique] = useState(null); 

  const loading = useSelector((state) => state.profileSlice.loading);
  const url = window.location.pathname;
  const parts = url.split("/").filter((url) => url.length > 0);

  const id = parts[parts.length - 1];
  const [startDate, setStartDate] = useState(null);

  useEffect(() => {
    if (parts.length > 2) {
      const Plan = parts[parts.length - 2];
      setdefaultPlan(Plan);
    } else {
      setdefaultPlan("platinum");
    }
  }, []);

  useEffect(() => {
    setLoadingg(loading);
  }, [loading]);

  useEffect(() => {
    dispatch(countrylist());
    dispatch(statelist());
    dispatch(membershipPlan());
    dispatch(countrycode());
  }, [dispatch]);

  const { country_list, state_list, memberplan_list, country_code } =
    useSelector((state) => state.dataSlice);
  const data = state_list?.data || [];
  const countryOptions = country_list?.data || [];
  const membership = memberplan_list?.data || [];
  const countryCodeOptions = country_code?.data || [];

  const { formData, errors, handleChange, handleSubmit } = useFormValidation(
    {},
    validate
  );

  const [states, setStates] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [recaptchaToken, setrecaptchaToken] = useState(null);

  useEffect(() => {
    const allStates = Object.values(data).flat();
    setStates(allStates);
  }, [data]);

  const handleCountryChange = (selectedOption, name) => {
    setSelectedCountry(selectedOption.value);
    handleChange({
      target: { name: name, value: selectedOption.value },
    });
  };

 

  const handlemembership = (selectedOption) => {
    handleChange({
      target: { name: "Company[membershipPlan]", value: selectedOption.value },
    });
  };

  const companyCountryCode = (selectedOption, name) => {
    handleChange({
      target: { name: name, value: selectedOption.value },
    });
  };
  
  const handlecompanyEmail = async () => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    setIsEmailValid(emailRegex.test(formData.Company.email));
    if(setIsEmailValid){
      const response = await dispatch(CompanyEmailUnique({email:formData.Company.email}));
    
      if (response.payload.unique==1) {
        setIsEmailUnique(false) ;
      }else{
        setIsEmailUnique(true) ;
      }
    }
    
  };
  

  const handlememberEmail = async () => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    setIsmemberEmailValid(emailRegex.test(formData.key?.email));
    if(setIsmemberEmailValid){
      const response = await dispatch(MemberEmailUnique({email:formData.key?.email}));
     
      if (response.payload.unique==1) {
        setIsmemberEmailUnique(false) ;
      }else{
        setIsmemberEmailUnique(true) ;
      }
    }
    
  };

  const membershipOptions =
    memberplan_list?.plans?.map((plan) => ({
      label: plan.label,
      value: plan.value,
    })) || [];

  const filteredOptions =
    id === 1
      ? membershipOptions.filter((option) =>
        option.value.toLowerCase().includes("platinum")
      )
      : membershipOptions.filter((option) => {
        const planName = option.value?.split(":")[1].toLowerCase().trim();
        return planName.includes(defaultPlan.toLowerCase());
      });

  const titleOptions = [
    { value: "Mr", label: "Mr" },
    { value: "Mrs", label: "Mrs" },
    { value: "Ms", label: "Ms" },
  ];

  const onSubmit = async (formData) => {
    const data = new FormData();

    // Append formData fields to data
    Object.keys(formData).forEach((key) => {
      if (typeof formData[key] === "object" && formData[key] !== null) {
        Object.keys(formData[key]).forEach((subKey) => {
          data.append(`${key}[${subKey}]`, formData[key][subKey]);
        });
      } else {
        data.append(key, formData[key]);
      }
      if (formData.Company?.company_banner) {
        data.append("Company[company_banner]", formData.Company.company_banner);
      }
      if (formData.Company?.member_logo) {
        data.append("Company[member_logo]", formData.Company.member_logo);
      }
    });

    data.append("Company[source]", id);
    data.append("key[membertype]", 1);
    data.append("Company[membershipPlan]", filteredOptions[0].value);
  
     if (startDate) {
      const date = new Date(startDate); 
      const gmtDate = date.toUTCString();
   
      
      const localDate = new Date(gmtDate);
      const utcTimestamp = Math.floor((localDate.getTime() - localDate.getTimezoneOffset() * 60000) / 1000);
   
      
      data.append("Company[foundation_date]", utcTimestamp);
    }
      
      // const timestamp = Math.floor(startDate.getTime() / 1000);  
      // data.append("Company[foundation_date]", timestamp);
  
  
  
    try {
      if ((isEmailValid ==true) && (isEmailUnique ==true)  && (ismemberEmailValid ==true)  && (ismemberEmailUnique ==true) ){
        const response = await dispatch(ProfileInsert(data));
        if (response.payload.status === "success") {
          navigate("/thankyou");
        }
      }
       
    } catch (error) {
      console.error("Error dispatching ProfileInsert:", error);
    }
  };

  return (
    <>
      <Loading loading={loadingg} />
      <div class="container-fluid px-1 py-5 mx-auto registration">
        <div class="row d-flex justify-content-center">
          <div class="col-xl-7 col-lg-8 col-md-9 col-11 ">
            <div class="card card_registartion">
              <p class="text-center mb-4 black-text  text-bold font-weight-bold text-custom">
                Member Registration
              </p>

              <div className="main-content mt-2">
                <div className="page-content">
                  <div className="container-fluid">
                    <div className="row">
                      <form
                        method="post"
                        onSubmit={(e) => handleSubmit(e, onSubmit)}
                      >
                        <div class="form-container">
                          <div class="d-block border rounded my-2 px-2 py-0">
                            <div class="position-relative text-left">
                              <span class="d-inline bg-primary text-white border rounded px-2 py-1 button-theme">
                                <span class="text-bold">
                                  Company Information
                                  <span class="form-title no-border"></span>
                                </span>
                              </span>
                              <div class="row">
                                <div className="row mt-2">
                                  <div className="col-md-12">
                                    <div className="form-group">
                                      <label className="form-label">
                                        {" "}
                                        Company Name{" "}
                                        <span class="text-danger">*</span>{" "}
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control border border-grey border-1 rounded-1  Removefocus"
                                        placeholder="your company Name"
                                        name="Company[company_name]"
                                        value={formData.Company?.company_name}
                                        onChange={handleChange}
                                      />
                                      {errors.Company?.company_name && (
                                        <span className="text-danger">
                                          {errors.Company.company_name}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="form-group mb-4">
                                      <label className="form-label">
                                        Address Line 1{" "}
                                        <span class="text-danger">*</span>
                                      </label>
                                      <textarea
                                        className="form-control border border-grey border-1 rounded-1  Removefocus"
                                        placeholder="Street, Apartment"
                                        name="Company[address1]"
                                        value={formData.Company?.address1}
                                        onChange={handleChange}
                                      ></textarea>
                                      {errors.Company?.address1 && (
                                        <span className="text-danger">
                                          {errors.Company.address1}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="form-group mb-4">
                                      <label className="form-label">
                                        House number{" "}
                                        <span class="text-danger">*</span>
                                      </label>
                                      <textarea
                                        className="form-control border border-grey border-1 rounded-1  Removefocus"
                                        placeholder="House number"
                                        name="Company[address2]"
                                        value={formData.Company?.address2}
                                        onChange={handleChange}
                                      ></textarea>
                                      {errors.Company?.address2 && (
                                        <span className="text-danger">
                                          {errors.Company.address2}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div className="row ">
                                    <div className="col-md-6">
                                      <div className="form-group mb-4">
                                        <label className="form-label">
                                          Zip Code{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="Company[zip_code]"
                                          placeholder="Enter zip code"
                                          value={formData.Company?.zip_code}
                                          onChange={handleChange}
                                        />
                                        {errors.Company?.zip_code && (
                                          <span className="text-danger">
                                            {errors.Company.zip_code}
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="form-group mb-4">
                                        <label className="form-label">
                                          City
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control border border-grey border-1 rounded-1  Removefocus"
                                          placeholder="your City"
                                          name="Company[city]"
                                          value={formData.Company?.city}
                                          onChange={handleChange}
                                        />
                                        {errors.Company?.city && (
                                          <span className="text-danger">
                                            {errors.Company.city}
                                          </span>
                                        )}
                                      </div>
                                    </div>

                                    {/* <div className="col-md-4">
                                      <div className="form-group mb-4">
                                        <label className="form-label">
                                          State{" "}
                                          <span class="text-danger">*</span>
                                        </label>
                                        <Select
                                          options={states}
                                          onChange={(selectedOption) =>
                                            handleStateChange(
                                              selectedOption,
                                              "Company[state]"
                                            )
                                          }
                                          placeholder={"select the state"}
                                        />
                                        {errors.Company?.state && (
                                          <span className="text-danger">
                                            {errors.Company.state}
                                          </span>
                                        )}
                                      </div>
                                    </div> */}
                                    <div className="col-md-12">
                                      <div className="form-group mb-4">
                                        <label className="form-label">
                                          Country{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <Select
                                          options={countryOptions}
                                          onChange={(selectedOption) =>
                                            handleCountryChange(
                                              selectedOption,
                                              "Company[country]"
                                            )
                                          }
                                          placeholder="Select the country"
                                        />
                                        {errors.Company?.country && (
                                          <span className="text-danger">
                                            {errors.Company.country}
                                          </span>
                                        )}
                                      </div>
                                    </div>

                                    <div className="col-md-12">
                                      <div className="form-group mb-3">
                                        <label className="form-label">
                                          Telephone{" "}
                                          <span class="text-danger">*</span>
                                        </label>
                                        <div className="d-flex Removefocus">
                                          <Select
                                            options={countryCodeOptions}
                                            onChange={(selectedOption) =>
                                              companyCountryCode(
                                                selectedOption,
                                                "Company[country_code]"
                                              )
                                            }
                                            className="country-code-select h-1rem w-100"
                                          />
                                          {/* <input
                                            type="number"
                                            className="form-control border border-grey border-1 rounded-1  Removefocus w-60"
                                            placeholder="area code"
                                            name="Company[pincode]"
                                            value={formData.Company?.pincode}
                                            onChange={handleChange}
                                          /> */}

                                          <input
                                            type="text"
                                            className="form-control border border-grey border-1 rounded-1  Removefocus"
                                            placeholder="Phone Number"
                                            name="Company[contact]"
                                            value={formData.Company?.contact}
                                            onChange={handleChange}
                                          />
                                        </div>
                                        {errors.Company?.country_code && (
                                          <div className="text-danger">
                                            {errors.Company.country_code}
                                          </div>
                                        )}
                                        {errors.Company?.pincode && (
                                          <div className="text-danger">
                                            {errors.Company.pincode}
                                          </div>
                                        )}
                                        {errors.Company?.contact && (
                                          <div className="text-danger">
                                            {errors.Company.contact}
                                          </div>
                                        )}
                                      </div>
                                    </div>

                                    <div className="col-md-12">
                                      <div className="form-group mb-4">
                                        <label className="form-label">
                                          Company Email{" "}
                                          <span class="text-danger">*</span>
                                        </label>

                                        <input
                                          type="email"
                                          className="form-control border border-grey border-1 rounded-1  Removefocus"
                                          placeholder="Company email"
                                          name="Company[email]"
                                          value={formData.Company?.email}
                                          onChange={handleChange}
                                          onKeyUp={handlecompanyEmail}
                                        />
                                        {!isEmailValid && (
          <span className="text-danger">Please enter a valid email address.</span>
        )}
          {/* Show duplicate email error */}
          {isEmailUnique === false && (
          <span className="text-danger">This email is already taken.</span>
        )}

                                        {errors.Company?.email && (
                                          <span className="text-danger">
                                            {errors.Company.email}
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                    <div className="col-md-12">
                                      <div className="form-group mb-4">
                                        <label className="form-label">
                                          Website
                                        </label>

                                        <input
                                          type="text"
                                          className="form-control border border-grey border-1 rounded-1  Removefocus"
                                          placeholder="your website"
                                          name="Company[website]"
                                          value={formData.Company?.website}
                                          onChange={handleChange}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="form-group mb-4">
                                        <label className="form-label">
                                          Chamber of commerce number{" "}
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control border border-grey border-1 rounded-1  Removefocus"
                                          placeholder="Chamber of commerce number "
                                          name="Company[Chamber_no]"
                                          value={formData.Company?.Chamber_no}
                                          onChange={handleChange}
                                        />
                                        {errors.Company?.Chamber_no && (
                                          <span className="text-danger">
                                            {errors.Company.Chamber_no}
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="form-group mb-4">
                                        <label className="form-label">
                                          Tax Registration Number{" "}
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control border border-grey border-1 rounded-1  Removefocus"
                                          placeholder="Tax Registration Number"
                                          name="Company[Tax_Registration]"
                                          value={
                                            formData.Company?.Tax_Registration
                                          }
                                          onChange={handleChange}
                                        />
                                        {errors.Company?.Tax_Registration && (
                                          <span className="text-danger">
                                            {errors.Company.Tax_Registration}
                                          </span>
                                        )}
                                      </div>
                                    </div>

                                    <div className="form-group mb-4">
                                      <label className="form-label">
                                        Foundation Date
                                      </label>

                                      <div className="col-md-8">
                                        <DatePicker
                                          selected={startDate}
                                          onChange={(date) => setStartDate(date)}
                                          className="form-control custom-datepicker"
                                          placeholderText="Select a date"
                                          dateFormat="dd/MM/yyyy"
                                          showYearDropdown
                                          showMonthDropdown
                                          dropdownMode="select"
                                        />
                                      </div>

                                    </div>

                                    <div className="col-md-6">
                                      <div className="form-group mb-4 d-none">
                                        <label className="form-label">
                                          Membership Plan{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <Select
                                          className="Removefocus"
                                          options={filteredOptions}
                                          defaultValue={filteredOptions}
                                          isDisabled={true}
                                          onChange={handlemembership}
                                          placeholder={
                                            "Select the membership plan"
                                          }
                                        />
                                        {errors.Company?.membershipPlan && (
                                          <span className="text-danger">
                                            {errors.Company.membershipPlan}
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="form-container">
                          <div class="d-block border rounded my-2 px-2 py-0">
                            <div class="position-relative text-left">
                              <span class="d-inline bg-primary text-white border rounded px-2 py-1 button-theme">
                                <span class="text-bold">
                                  Key Contact
                                  <span class="form-title no-border"></span>
                                </span>
                              </span>
                              <div class="row">
                                <div className="row mt-2">
                                  <div className="col-md-9">
                                    <div className="form-group">
                                      <label className="form-label">
                                        Title{" "}
                                        <span className="text-danger">*</span>
                                      </label>
                                      <select
                                        className="form-control border border-grey border-1 rounded-1 Removefocus"
                                        name="key[Title]"
                                        value={formData.key?.Title}
                                        onChange={handleChange}
                                      >
                                        <option value="">Select Title</option>

                                        <option value="Mr.">Mr</option>
                                        <option value="Mrs.">Mrs</option>
                                        <option value="Ms.">Ms</option>
                                      </select>
                                      {errors.key?.Title && (
                                        <span className="text-danger">
                                          {errors.key.Title}
                                        </span>
                                      )}
                                    </div>
                                  </div>

                                  <div className="col-md-4">
                                    <div className="form-group">
                                      <label className="form-label">
                                        First Name{" "}
                                        <span class="text-danger">*</span>{" "}
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control border border-grey border-1 rounded-1  Removefocus"
                                        placeholder="Name"
                                        name="key[first_Name]"
                                        value={formData.key?.first_Name}
                                        onChange={handleChange}
                                      />
                                      {errors.key?.first_Name && (
                                        <span className="text-danger">
                                          {errors.key.first_Name}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="form-group">
                                      <label className="form-label">
                                        Middle Name
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control border border-grey border-1 rounded-1  Removefocus"
                                        placeholder="Name"
                                        name="key[middle_Name]"
                                        value={formData.key?.middle_Name}
                                        onChange={handleChange}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="form-group">
                                      <label className="form-label">
                                        Last Name{" "}
                                        <span class="text-danger">*</span>{" "}
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control border border-grey border-1 rounded-1  Removefocus"
                                        placeholder="Name"
                                        name="key[last_Name]"
                                        value={formData.key?.last_Name}
                                        onChange={handleChange}
                                      />
                                      {errors.key?.last_Name && (
                                        <span className="text-danger">
                                          {errors.key.last_Name}
                                        </span>
                                      )}
                                    </div>
                                  </div>

                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label className="form-label">
                                        Job Title{" "}
                                        <span class="text-danger">*</span>
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control border border-grey border-1 rounded-1  Removefocus"
                                        placeholder="Job Title"
                                        name="key[Job_Title]"
                                        value={formData.key?.Job_Title}
                                        onChange={handleChange}
                                      />
                                      {errors.key?.Job_Title && (
                                        <span className="text-danger">
                                          {errors.key.Job_Title}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label className="form-label">
                                        Department{" "}
                                        <span className="text-danger">*</span>
                                      </label>
                                      <select
                                        className="form-control border border-grey border-1 rounded-1 Removefocus"
                                        name="key[Department]"
                                        value={formData.key?.Department || ""}
                                        onChange={handleChange}
                                      >
                                        <option value="" disabled>
                                          Select Department
                                        </option>{" "}
                                        <option value="Management">
                                          Management
                                        </option>
                                        <option value="Sales">Sales</option>
                                        <option value="Operations">
                                          Operations
                                        </option>
                                        <option value="Financial">
                                          Financial
                                        </option>
                                      </select>
                                      {errors.key?.Department && (
                                        <span className="text-danger">
                                          {errors.key.Department}
                                        </span>
                                      )}
                                    </div>
                                  </div>

                                  <div className="col-md-12">
                                    <div className="form-group mb-3">
                                      <label className="form-label">
                                        Phone Number Direct{" "}
                                      
                                      </label>
                                      <div className="d-flex Removefocus">
                                        <Select
                                          options={countryCodeOptions}
                                          onChange={(selectedOption) =>
                                            companyCountryCode(
                                              selectedOption,
                                              "key[country_code_direct]"
                                            )
                                          }
                                          className="country-code-select  h-1rem w-100"
                                        />
                                        <input
                                          type="number"
                                          className="form-control border border-grey border-1 rounded-1  Removefocus w-60"
                                          placeholder="area code"
                                          name="key[direct_pincode]"
                                          value={formData.key?.direct_pincode}
                                          onChange={handleChange}
                                        />
                                        <input
                                          type="text"
                                          className="form-control border border-grey border-1 rounded-1  Removefocus"
                                          placeholder="Phone Number"
                                          name="key[direct_telephone]"
                                          value={formData.key?.direct_telephone}
                                          onChange={handleChange}
                                        />
                                      </div>
                                      {/* {errors.key?.country_code_direct && (
                                        <div className="text-danger">
                                          {errors.key.country_code_direct}
                                        </div>
                                      )}
                                      {errors.key?.direct_pincode && (
                                        <div className="text-danger">
                                          {errors.key.direct_pincode}
                                        </div>
                                      )}
                                      {errors.key?.direct_telephone && (
                                        <div className="text-danger">
                                          {errors.key.direct_telephone}
                                        </div>
                                      )} */}
                                    </div>
                                  </div>

                                  <div className="col-md-9">
                                    <div className="form-group">
                                      <label className="form-label">
                                        Extension
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control border border-grey border-1 rounded-1  Removefocus"
                                        placeholder="Extension"
                                        name="key[Extension]"
                                        value={formData.key?.Extension}
                                        onChange={handleChange}
                                      />
                                    </div>
                                  </div>

                                  <div className="col-md-12">
                                    <div className="form-group mb-3">
                                      <label className="form-label">
                                        Phone Number Mobile{" "}
                                        <span class="text-danger">*</span>
                                      </label>
                                      <div className="d-flex Removefocus">
                                        <Select
                                          options={countryCodeOptions}
                                          onChange={(selectedOption) =>
                                            companyCountryCode(
                                              selectedOption,
                                              "key[country_code]"
                                            )
                                          }
                                          className="country-code-select  h-1rem w-100"
                                        />
                                        {/* <input
                                          type="number"
                                          className="form-control border border-grey border-1 rounded-1  Removefocus w-60"
                                          placeholder="area code"
                                          name="key[pincode]"
                                          value={formData.key?.pincode}
                                          onChange={handleChange}
                                        /> */}
                                        <input
                                          type="text"
                                          className="form-control border border-grey border-1 rounded-1  Removefocus"
                                          placeholder="Phone Number"
                                          name="key[Mobile]"
                                          value={formData.key?.Mobile}
                                          onChange={handleChange}
                                        />
                                      </div>
                                      {errors.key?.country_code && (
                                        <div className="text-danger">
                                          {errors.key.country_code}
                                        </div>
                                      )}
                                      {errors.key?.pincode && (
                                        <div className="text-danger">
                                          {errors.key.pincode}
                                        </div>
                                      )}
                                      {errors.key?.Mobile && (
                                        <div className="text-danger">
                                          {errors.key.Mobile}
                                        </div>
                                      )}
                                    </div>
                                  </div>

                                  <div className="col-md-9">
                                    <div className="form-group">
                                      <label className="form-label">
                                        Email address{" "}
                                        <span class="text-danger">*</span>
                                      </label>
                                      <input
                                        type="email"
                                        className="form-control border border-grey border-1 rounded-1  Removefocus"
                                        placeholder="Email address "
                                        name="key[email]"
                                        value={formData.key?.email}
                                        onChange={handleChange}
                                        onKeyUp={handlememberEmail}
                                      />
                                      {!ismemberEmailValid && (
    <span className="text-danger">Please enter a valid email address.</span>
  )}
    {/* Show duplicate email error */}
    {ismemberEmailUnique === false && (
    <span className="text-danger">This email is already taken.</span>
  )}
                                      {errors.key?.email && (
                                        <span className="text-danger">
                                          {errors.key.email}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <h5 class="text-danger">Note :- </h5>
                        <span class="mt-2">
                          {" "}
                          To run back ground check we need 2 references
                          (forwarding companies ) from different countries than
                          your own, who can testify on your performance,
                          services and so on. This cannot be branch related
                          offices.
                        </span>

                        <div class="form-container mt-2">
                          <div class="d-block border rounded my-2 px-2 py-0">
                            <div class="position-relative text-left">
                              <span class="d-inline bg-primary text-white border rounded px-2 py-1 button-theme">
                                <span class="text-bold">
                                  References
                                  <span class="form-title no-border"></span>
                                </span>
                              </span>
                              <div class="row">
                                <div className="row mt-2">
                                  <div className="col-md-12">
                                    <div className="form-group">
                                      <label className="form-label">
                                        Company Name{" "}
                                        <span class="text-danger">*</span>
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control border border-grey border-1 rounded-1  Removefocus"
                                        placeholder="Company Name"
                                        name="References[company_name]"
                                        value={
                                          formData.References?.company_name
                                        }
                                        onChange={handleChange}
                                      />
                                      {errors.References?.company_name && (
                                        <span className="text-danger">
                                          {errors.References.company_name}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-md-12">
                                    <div className="form-group mb-4">
                                      <label className="form-label">
                                        Country{" "}
                                        <span class="text-danger">*</span>
                                      </label>
                                      <Select
                                        options={countryOptions}
                                        onChange={(selectedOption) =>
                                          handleCountryChange(
                                            selectedOption,
                                            "References[country]"
                                          )
                                        }
                                        placeholder={"select the country"}
                                      />
                                      {errors.References?.country && (
                                        <span className="text-danger">
                                          {errors.References.country}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="form-group">
                                      <label className="form-label">
                                        First Name{" "}
                                        <span className="text-danger">*</span>
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control border border-grey border-1 rounded-1 Removefocus"
                                        placeholder="First Name"
                                        name="References[first_Name]" // Unique name attribute
                                        value={
                                          formData.References?.first_Name || ""
                                        }
                                        onChange={handleChange}
                                      />
                                      {errors.References?.first_Name && (
                                        <span className="text-danger">
                                          {errors.References.first_Name}
                                        </span>
                                      )}
                                    </div>
                                  </div>

                                  <div className="col-md-4">
                                    <div className="form-group">
                                      <label className="form-label">
                                        Middle Name
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control border border-grey border-1 rounded-1 Removefocus"
                                        placeholder="Middle Name"
                                        name="References[middle_Name]"
                                        value={
                                          formData.References?.middle_Name || ""
                                        }
                                        onChange={handleChange}
                                      />
                                    </div>
                                  </div>

                                  <div className="col-md-4">
                                    <div className="form-group">
                                      <label className="form-label">
                                        Last Name{" "}
                                        <span className="text-danger">*</span>
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control border border-grey border-1 rounded-1 Removefocus"
                                        placeholder="Last Name"
                                        name="References[last_Name]"
                                        value={
                                          formData.References?.last_Name || ""
                                        }
                                        onChange={handleChange}
                                      />
                                      {errors.References?.last_Name && (
                                        <span className="text-danger">
                                          {errors.References.last_Name}
                                        </span>
                                      )}
                                    </div>
                                  </div>

                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label className="form-label">
                                        Job Title{" "}
                                        <span class="text-danger">*</span>
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control border border-grey border-1 rounded-1  Removefocus"
                                        placeholder="Job Title"
                                        name="References[Job_Title]"
                                        value={formData.References?.Job_Title}
                                        onChange={handleChange}
                                      />
                                      {errors.References?.Job_Title && (
                                        <span className="text-danger">
                                          {errors.References.Job_Title}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label className="form-label">
                                        Department{" "}
                                        <span className="text-danger">*</span>
                                      </label>
                                      <select
                                        className="form-control border border-grey border-1 rounded-1 Removefocus"
                                        name="References[Department]"
                                        value={
                                          formData.References?.Department || ""
                                        }
                                        onChange={handleChange}
                                      >
                                        <option value="" disabled>
                                          Select Department
                                        </option>{" "}
                                        <option value="Management">
                                          Management
                                        </option>
                                        <option value="Sales">Sales</option>
                                        <option value="Operations">
                                          Operations
                                        </option>
                                        <option value="Financial">
                                          Financial
                                        </option>
                                      </select>
                                      {errors.References?.Department && (
                                        <span className="text-danger">
                                          {errors.References.Department}
                                        </span>
                                      )}
                                    </div>
                                  </div>

                                  <div className="col-md-12">
                                    <div className="form-group mb-3">
                                      <label className="form-label">
                                        Phone Number Direct{" "}
                                        
                                      </label>
                                      <div className="d-flex Removefocus">
                                        <Select
                                          options={countryCodeOptions}
                                          onChange={(selectedOption) =>
                                            companyCountryCode(
                                              selectedOption,
                                              "References[direct_country_code]"
                                            )
                                          }
                                          className="country-code-select  h-1rem w-100"
                                        />
                                        <input
                                          type="number"
                                          className="form-control border border-grey border-1 rounded-1  Removefocus w-60"
                                          placeholder="area code"
                                          name="References[direct_pincode]"
                                          value={
                                            formData.References?.direct_pincode
                                          }
                                          onChange={handleChange}
                                        />
                                        <input
                                          type="text"
                                          className="form-control border border-grey border-1 rounded-1  Removefocus"
                                          placeholder="Phone Number"
                                          name="References[telephone_direct]"
                                          value={
                                            formData.References
                                              ?.telephone_direct
                                          }
                                          onChange={handleChange}
                                        />
                                      </div>
                                      {/* {errors.References
                                        ?.direct_country_code && (
                                          <div className="text-danger">
                                            {
                                              errors.References
                                                .direct_country_code
                                            }
                                          </div>
                                        )} */}
                                      {/* {errors.References?.direct_pincode && (
                                        <div className="text-danger">
                                          {errors.References.direct_pincode}
                                        </div>
                                      )} */}
                                      {/* {errors.References?.telephone_direct && (
                                        <div className="text-danger">
                                          {errors.References.telephone_direct}
                                        </div>
                                      )} */}
                                    </div>
                                  </div>

                                  <div className="col-md-12">
                                    <div className="form-group mb-3">
                                      <label className="form-label">
                                        Phone Number Mobile{" "}
                                        <span class="text-danger">*</span>
                                      </label>
                                      <div className="d-flex Removefocus">
                                        <Select
                                          options={countryCodeOptions}
                                          onChange={(selectedOption) =>
                                            companyCountryCode(
                                              selectedOption,
                                              "References[country_code]"
                                            )
                                          }
                                          className="country-code-select  h-1rem w-100"
                                        />
                                        {/* <input
                                          type="number"
                                          className="form-control border border-grey border-1 rounded-1  Removefocus w-60"
                                          placeholder="area code"
                                          name="References[pincode]"
                                          value={formData.References?.pincode}
                                          onChange={handleChange}
                                        /> */}
                                        <input
                                          type="text"
                                          className="form-control border border-grey border-1 rounded-1  Removefocus"
                                          placeholder="Phone Number"
                                          name="References[Mobile]"
                                          value={formData.References?.Mobile}
                                          onChange={handleChange}
                                        />
                                      </div>
                                      {errors.References?.country_code && (
                                        <div className="text-danger">
                                          {errors.References.country_code}
                                        </div>
                                      )}
                                      {errors.References?.pincode && (
                                        <div className="text-danger">
                                          {errors.References.pincode}
                                        </div>
                                      )}
                                      {errors.References?.Mobile && (
                                        <div className="text-danger">
                                          {errors.References.Mobile}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-md-12">
                                    <div className="form-group">
                                      <label className="form-label">
                                        Email address{" "}
                                        <span class="text-danger">*</span>
                                      </label>
                                      <input
                                        type="email"
                                        className="form-control border border-grey border-1 rounded-1  Removefocus"
                                        placeholder="Email address "
                                        name="References[email]"
                                        value={formData.References?.email}
                                        onChange={handleChange}
                                      />
                                      {errors.References?.email && (
                                        <span className="text-danger">
                                          {errors.References.email}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-md-12">
                                    <div className="form-group">
                                      <label className="form-label">
                                        Website
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control border border-grey border-1 rounded-1  Removefocus"
                                        placeholder="Website"
                                        name="References[Website]"
                                        value={formData.References?.Website}
                                        onChange={handleChange}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <span class="d-inline bg-primary text-white border rounded px-2 py-1 button-theme">
                                <span class="text-bold">
                                  References 2
                                  <span class="form-title no-border"></span>
                                </span>
                              </span>
                              <div class="row mt-2">
                                <div className="row mt-2">
                                  <div className="col-md-12">
                                    <div className="form-group">
                                      <label className="form-label">
                                        Company Name{" "}
                                        <span class="text-danger">*</span>
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control border border-grey border-1 rounded-1  Removefocus"
                                        placeholder="Company Name"
                                        name="References2[company_name]"
                                        value={
                                          formData.References2?.company_name
                                        }
                                        onChange={handleChange}
                                      />
                                      {errors.References2?.company_name && (
                                        <span className="text-danger">
                                          {errors.References2.company_name}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-md-12">
                                    <div className="form-group mb-4">
                                      <label className="form-label">
                                        Country{" "}
                                        <span class="text-danger">*</span>
                                      </label>
                                      <Select
                                        options={countryOptions}
                                        onChange={(selectedOption) =>
                                          handleCountryChange(
                                            selectedOption,
                                            "References2[country]"
                                          )
                                        }
                                        placeholder={"select the country"}
                                      />
                                      {errors.References2?.country && (
                                        <span className="text-danger">
                                          {errors.References2.country}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="form-group">
                                      <label className="form-label">
                                        First Name{" "}
                                        <span className="text-danger">*</span>
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control border border-grey border-1 rounded-1 Removefocus"
                                        placeholder="First Name"
                                        name="References2[first_Name]"
                                        value={
                                          formData.References2?.first_Name || ""
                                        }
                                        onChange={handleChange}
                                      />
                                      {errors.References2?.first_Name && (
                                        <span className="text-danger">
                                          {errors.References2.first_Name}
                                        </span>
                                      )}
                                    </div>
                                  </div>

                                  <div className="col-md-4">
                                    <div className="form-group">
                                      <label className="form-label">
                                        Middle Name
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control border border-grey border-1 rounded-1 Removefocus"
                                        placeholder="Middle Name"
                                        name="References2[MiddleName]"
                                        value={
                                          formData.References2?.MiddleName || ""
                                        }
                                        onChange={handleChange}
                                      />
                                    </div>
                                  </div>

                                  <div className="col-md-4">
                                    <div className="form-group">
                                      <label className="form-label">
                                        Last Name{" "}
                                        <span className="text-danger">*</span>
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control border border-grey border-1 rounded-1 Removefocus"
                                        placeholder="Last Name"
                                        name="References2[last_Name]"
                                        value={
                                          formData.References2?.last_Name || ""
                                        }
                                        onChange={handleChange}
                                      />
                                      {errors.References2?.last_Name && (
                                        <span className="text-danger">
                                          {errors.References2.last_Name}
                                        </span>
                                      )}
                                    </div>
                                  </div>

                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label className="form-label">
                                        Job Title{" "}
                                        <span class="text-danger">*</span>
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control border border-grey border-1 rounded-1  Removefocus"
                                        placeholder="Job Title"
                                        name="References2[Job_Title]"
                                        value={formData.References2?.Job_Title}
                                        onChange={handleChange}
                                      />
                                      {errors.References2?.Function_Title && (
                                        <span className="text-danger">
                                          {errors.References2.Job_Title}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label className="form-label">
                                        Department{" "}
                                        <span className="text-danger">*</span>
                                      </label>
                                      <select
                                        className="form-control border border-grey border-1 rounded-1 Removefocus"
                                        name="References2[Department]"
                                        value={
                                          formData.References2?.Department || ""
                                        }
                                        onChange={handleChange}
                                      >
                                        <option value="" disabled>
                                          Select Department
                                        </option>{" "}
                                        <option value="Management">
                                          Management
                                        </option>
                                        <option value="Sales">Sales</option>
                                        <option value="Operations">
                                          Operations
                                        </option>
                                        <option value="Financial">
                                          Financial
                                        </option>
                                      </select>
                                      {errors.References2?.Department && (
                                        <span className="text-danger">
                                          {errors.References2.Department}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-md-12">
                                    <div className="form-group mb-3">
                                      <label className="form-label">
                                        Phone Number Direct{" "}
                                       
                                      </label>
                                      <div className="d-flex Removefocus">
                                        <Select
                                          options={countryCodeOptions}
                                          onChange={(selectedOption) =>
                                            companyCountryCode(
                                              selectedOption,
                                              "References2[direct_country_code]"
                                            )
                                          }
                                          className="country-code-select  h-1rem w-100"
                                        />
                                        <input
                                          type="number"
                                          className="form-control border border-grey border-1 rounded-1  Removefocus w-60"
                                          placeholder="area code"
                                          name="References2[direct_pincode]"
                                          value={
                                            formData.References2?.direct_pincode
                                          }
                                          onChange={handleChange}
                                        />
                                        <input
                                          type="text"
                                          className="form-control border border-grey border-1 rounded-1  Removefocus"
                                          placeholder="phone number"
                                          name="References2[telephone_direct]"
                                          value={
                                            formData.References2
                                              ?.telephone_direct
                                          }
                                          onChange={handleChange}
                                        />
                                      </div>
                                      {/* {errors.References2
                                        ?.direct_country_code && (
                                          <div className="text-danger">
                                            {
                                              errors.References2
                                                .direct_country_code
                                            }
                                          </div>
                                        )}
                                      {errors.References2?.direct_pincode && (
                                        <div className="text-danger">
                                          {errors.References2.direct_pincode}
                                        </div>
                                      )}
                                      {errors.References2?.telephone_direct && (
                                        <div className="text-danger">
                                          {errors.References2.telephone_direct}
                                        </div>
                                      )} */}
                                    </div>
                                  </div>

                                  <div className="col-md-12">
                                    <div className="form-group mb-3">
                                      <label className="form-label">
                                        Phone Number Mobile{" "}
                                        <span class="text-danger">*</span>
                                      </label>
                                      <div className="d-flex Removefocus">
                                        <Select
                                          options={countryCodeOptions}
                                          onChange={(selectedOption) =>
                                            companyCountryCode(
                                              selectedOption,
                                              "References2[country_code]"
                                            )
                                          }
                                          className="country-code-select  h-1rem w-100"
                                        />
                                        {/* <input
                                          type="number"
                                          className="form-control border border-grey border-1 rounded-1  Removefocus w-60"
                                          placeholder="area code"
                                          name="References2[pincode]"
                                          value={formData.References2?.pincode}
                                          onChange={handleChange}
                                        /> */}
                                        <input
                                          type="text"
                                          className="form-control border border-grey border-1 rounded-1  Removefocus"
                                          placeholder="Phone Number"
                                          name="References2[Mobile]"
                                          value={formData.References2?.Mobile}
                                          onChange={handleChange}
                                        />
                                      </div>
                                      {errors.References2?.country_code && (
                                        <div className="text-danger">
                                          {errors.References2.country_code}
                                        </div>
                                      )}
                                      {errors.References2?.pincode && (
                                        <div className="text-danger">
                                          {errors.References2.pincode}
                                        </div>
                                      )}
                                      {errors.References2?.Mobile && (
                                        <div className="text-danger">
                                          {errors.References2.Mobile}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-md-12">
                                    <div className="form-group">
                                      <label className="form-label">
                                        Email address{" "}
                                        <span class="text-danger">*</span>
                                      </label>
                                      <input
                                        type="email"
                                        className="form-control border border-grey border-1 rounded-1  Removefocus"
                                        placeholder="Email address "
                                        name="References2[email]"
                                        value={formData.References2?.email}
                                        onChange={handleChange}
                                      />
                                      {errors.References2?.email && (
                                        <span className="text-danger">
                                          {errors.References2.email}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-md-12">
                                    <div className="form-group">
                                      <label className="form-label">
                                        Website
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control border border-grey border-1 rounded-1  Removefocus"
                                        placeholder="Website"
                                        name="References2[Website]"
                                        value={formData.References2?.Website}
                                        onChange={handleChange}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="form-row">
                          <div class="form-group col-md-12">
                            <label>
                              You are engaging in and agreeing to an official
                              contract at an agreed cost and you have read and
                              agreed to:
                            </label>

                            <ul className="custom-list">
                              <li>
                                <a
                                  href="https://select.freightlounge.network/wp-content/uploads/2023/03/Freight-lounge-Code-of-Conduct-1.pdf"
                                  target="_blank"
                                  rel="noopener"
                                >
                                  Code of Conduct
                                </a>
                              </li>
                              <li>
                                <a
                                  href="https://select.freightlounge.network/wp-content/uploads/2023/03/Freight-Lounge-B.V-Terms-and-Conditions-16-July-2019_kopie.pdf"
                                  target="_blank"
                                  rel="noopener"
                                >
                                  Terms and Conditions
                                </a>
                              </li>
                              <li>
                                <a
                                  href="https://select.freightlounge.network/wp-content/uploads/2023/03/Freight-Lounge-B.V-Website-Usage-Terms-and-Conditions-13-Nov-2017.pdf"
                                  target="_blank"
                                  rel="noopener"
                                >
                                  Website Usage Terms and Conditions
                                </a>
                              </li>
                              <li>
                                <a
                                  href="https://select.freightlounge.network/privacy/"
                                  target="_blank"
                                  rel="noopener"
                                >
                                  Privacy Policy
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>

                        <div class="form-row">
                          <div class="form-group col-md-12">
                            <div class="form-check">
                              <div class="check-box form-check-block">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  name="registration[terms]"
                                  value="1"
                                  placeholder="Terms and Conditions"
                                  onChange={handleChange}
                                />
                                <label class="form-check-label">
                                  I accept the terms and conditions.{" "}
                                  <span class="text-red">*</span>
                                </label>
                              </div>
                            </div>
                            {errors.registration?.terms && (
                              <span className="text-danger">
                                {errors.registration.terms}
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="col-md-12 mt-2">
                          <button type="submit" className="btn button-theme">
                            Submit
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default MemberRegistration;
function validate(formData) {
  const errors = {};
  // company validation-------------

  if (!formData.Company?.country_code) {
    errors.Company = {
      ...errors.Company,
      country_code: "Country code is required ",
    };
  }
  // if (!formData.Company?.pincode) {
  //   errors.Company = { ...errors.Company, pincode: "pincode No is required " };
  // }
  if (!formData.Company?.zip_code) {
    errors.Company = { ...errors.Company, zip_code: "Zip code  is required " };
  }
  if (!formData.Company?.contact) {
    errors.Company = { ...errors.Company, contact: "Contact No is required " };
  }

  if (!formData.Company?.email) {
    errors.Company = { ...errors.Company, email: "Email is required" };
  }

  if (!formData.Company?.company_name) {
    errors.Company = {
      ...errors.Company,
      company_name: "Company name is required",
    };
  }

  if (!formData.Company?.country) {
    errors.Company = { ...errors.Company, country: "Country is required " };
  }

  if (!formData.Company?.city) {
    errors.Company = { ...errors.Company, city: "City is required" };
  }

  if (!formData.Company?.address1) {
    errors.Company = {
      ...errors.Company,
      address1: "Company Address1 is required",
    };
  }

  if (!formData.Company?.address2) {
    errors.Company = {
      ...errors.Company,
      address2: "House Number is required",
    };
  }

  // key contact validation---

  if (!formData.key?.Title) {
    errors.key = { ...errors.key, Title: "Title is required" };
  }

  if (!formData.key?.first_Name) {
    errors.key = { ...errors.key, first_Name: "First Name is required" };
  }

  if (!formData.key?.last_Name) {
    errors.key = { ...errors.key, last_Name: "Last Name is required" };
  }

  // if (!formData.key?.country_code_direct) {
  //   errors.key = {
  //     ...errors.key,
  //     country_code_direct: "Country code is required",
  //   };
  // }

  // if (!formData.key?.direct_telephone) {
  //   errors.key = {
  //     ...errors.key,
  //     direct_telephone: "Contact No is required \n",
  //   };
  // }

  // if (!formData.key?.direct_pincode) {
  //   errors.key = {
  //     ...errors.key,
  //     direct_pincode: "areacode No is required \n",
  //   };
  // }

  if (!formData.key?.Department) {
    errors.key = {
      ...errors.key,
      Department: "Department  is required \n",
    };
  }

  if (!formData.key?.country_code) {
    errors.key = { ...errors.key, country_code: "Country code is required" };
  }

  if (!formData.key?.Mobile) {
    errors.key = { ...errors.key, Mobile: "Contact No is required" };
  }

  if (!formData.key?.email) {
    errors.key = { ...errors.key, email: "Email is required" };
  }

  if (!formData.key?.Job_Title) {
    errors.key = { ...errors.key, Job_Title: "Job Title is required" };
  }

  // Reference validation-------------

  if (!formData.References?.company_name) {
    errors.References = {
      ...errors.References,
      company_name: "Company Name is required",
    };
  }

  if (!formData.References?.first_Name) {
    errors.References = {
      ...errors.References,
      first_Name: " First Name is required",
    };
  }

  if (!formData.References?.last_Name) {
    errors.References = {
      ...errors.References,
      last_Name: " Last Name is required",
    };
  }

  if (!formData.References?.country) {
    errors.References = {
      ...errors.References,
      country: " country is required",
    };
  }

  if (!formData.References?.Job_Title) {
    errors.References = {
      ...errors.References,
      Job_Title: " Job Title is required",
    };
  }

  // if (!formData.References?.direct_country_code) {
  //   errors.References = {
  //     ...errors.References,
  //     direct_country_code: "Country Code is required",
  //   };
  // }

  if (!formData.References?.Department) {
    errors.References = {
      ...errors.References,
      Department: "Department  is required",
    };
  }

  // if (!formData.References?.pincode) {
  //   errors.References = {
  //     ...errors.References,
  //     pincode: "pincode Code is required",
  //   };
  // }

  // if (!formData.References?.telephone_direct) {
  //   errors.References = {
  //     ...errors.References,
  //     telephone_direct: " Contact No is required",
  //   };
  // }

  if (!formData.References?.country_code) {
    errors.References = {
      ...errors.References,
      country_code: "Country Code is required",
    };
  }

  if (!formData.References?.Mobile) {
    errors.References = {
      ...errors.References,
      Mobile: " Contact No is required",
    };
  }

  if (!formData.References?.email) {
    errors.References = { ...errors.References, email: " Email is required" };
  }

  // REFERENCE 2--------------
  if (!formData.References2?.company_name) {
    errors.References2 = {
      ...errors.References2,
      company_name: "Company Name is required",
    };
  }

  if (!formData.References2?.first_Name) {
    errors.References2 = {
      ...errors.References2,
      first_Name: "First Name is required",
    };
  }

  if (!formData.References2?.last_Name) {
    errors.References2 = {
      ...errors.References2,
      last_Name: "Last Name is required",
    };
  }

  if (!formData.References2?.country) {
    errors.References2 = {
      ...errors.References2,
      country: " country is required",
    };
  }

  if (!formData.References2?.Job_Title) {
    errors.References2 = {
      ...errors.References2,
      Job_Title: " Job Title is required",
    };
  }

  // if (!formData.References2?.direct_country_code) {
  //   errors.References2 = {
  //     ...errors.References2,
  //     direct_country_code: "Country Code is required",
  //   };
  // }

  if (!formData.References2?.Department) {
    errors.References2 = {
      ...errors.References2,
      Department: "Department  is required",
    };
  }

  // if (!formData. ?.pincode) {
  //   errors.References2 = {
  //     ...errors.References2,
  //     pincode: "pincode Code is required",
  //   };
  // }

  // if (!formData.References2?.telephone_direct) {
  //   errors.References2 = {
  //     ...errors.References2,
  //     telephone_direct: " Contact No is required",
  //   };
  // }

  if (!formData.References2?.country_code) {
    errors.References2 = {
      ...errors.References2,
      country_code: "Country Code is required \n",
    };
  }

  if (!formData.References2?.Mobile) {
    errors.References2 = {
      ...errors.References2,
      Mobile: " Contact No is required \n",
    };
  }

  if (!formData.References2?.email) {
    errors.References2 = { ...errors.References2, email: " Email is required" };
  }

  if (!formData.registration?.terms) {
    errors.registration = {
      ...errors.registration,
      terms: " This field is required.",
    };
  }

  return errors;
}
