import React, { useEffect, useState } from "react";
import EmojiPicker from "emoji-picker-react";
import ChatSidebar from "./ChatSidebar";
import io from "socket.io-client";
import Cookies from "js-cookie";

const socket = io("https://clubnet.app", { path: "/dev_node/connection" });

const ChatApp = () => {
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [message, setMessage] = useState([]);
  const [messages, setMessages] = useState([]);
  const [receiver, setReceiver] = useState([]);
  const [history, sethistory] = useState([]);

  const senderUserId = Cookies.get("userId");

  const handleEmojiClick = (emojiObject) => {
    setMessage((prevMessage) => prevMessage + emojiObject.emoji);
  };

  const handlesubmit = async () => {
    const receiverUserId = receiver.member_id;
    const date= new Date().toLocaleDateString("en-GB").replace(/\//g, "");
    if (message.trim()) {
      const timestrap = Math.floor(Date.now() / 1000);
      const data = {
        senderUserId,
        receiverUserId,
        message,
        timestrap,
        date,
      };

      socket.emit("sendPrivateMessage", data);

      if (history[date]) {
        sethistory((prevHistory) => ({
          ...prevHistory,
          [date]: [...prevHistory[date], data],
        }));
      } else {
        sethistory((prevHistory) => ({
          ...prevHistory,
          [date]: [data],
        }));
      }
      setMessage("");
      // if(history[date]){
      //   history[date].push(data);
      //   sethistory(history);
      //   console.log(history)
      // }else{
        
      // }
   
    }
  };

  useEffect(() => {
    socket.emit("registerUser", senderUserId);

    const receiverUserId = 322;
    const data = {
      senderUserId,
      receiverUserId,
    };
    socket.emit("getMessages", data);

    socket.on("chatHistory", (history) => {
      sethistory(history);
    });

    // socket.on("receivePrivateMessage", (data) => {
    //   const date=data.date
    //   if(history[date]){
    //     history[date].push(data);
    //     sethistory(history);
    //     console.log(history)
    //   }
    // });
    
    socket.on("receivePrivateMessage", (data) => {
      const date = data.date;
    
      sethistory((prevHistory) => {
        // If the date exists, append to the array; otherwise, create a new array
        return {
          ...prevHistory,
          [date]: [...(prevHistory[date] || []), data],
        };
      });
    });
    
    return () => {
      socket.off("receivePrivateMessage");
    };

  }, []);

  const handleMemberSelect = (selectedMember) => {
    console.log("Selected Member:", selectedMember);
    setReceiver(selectedMember);
  };

  const formatTime = (timestamp) => {
    const date = new Date(timestamp * 1000);
    return `${date.getHours()}:${
      date.getMinutes() < 10 ? "0" : ""
    }${date.getMinutes()} ${date.getHours() >= 12 ? "PM" : "AM"}`;
  };


  return (
    <div className="container-fluid ">
      <div className="chat-app-container mt-3">
        <ChatSidebar
          new_message={"dsv"}
          message_data={"vsd"}
          onSelectMember={handleMemberSelect}
        />

        <div className="chat-section  border border-grey">
          <div className="header-chat d-flex align-items-center p-3 bg-light">
            <div
              className="photo"
              style={{
                backgroundImage: `url(${receiver.member_logo || "/dumy.jpeg"})`,
              }}
              onError={(e) => {
                e.target.src = "/dumy.jpeg";
              }}
            >
              <div className="online"></div>
            </div>

            <p className="name m-0 ml-2">{receiver.member_name}</p>

            <i
              className="icon clickable fa fa-ellipsis-h ml-auto"
              aria-hidden="true"
            ></i>
          </div>
        
          <div className="messages-chat">
            {Object.keys(history).map((date, index) => (
              <div key={index}>
                <h4 className="text-muted">{date}</h4>
                {history[date].map((msg, idx) => (
                  <div key={idx}>
                    {msg.senderUserId === "320" ? (
                      <div className="message">
                        <div
                          className="photo"
                          style={{
                            backgroundImage:
                              "url(https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80)",
                          }}
                        >
                          <div className="online"></div>
                        </div>
                        <div>
                          <p className="text">{msg.message}</p>
                          <small className="text-muted text-end d-block">
                            {formatTime(msg.timestrap)}
                          </small>
                        </div>
                      </div>
                    ) : msg.receiverUserId === 320 ? (
                      <div className="message d-flex justify-content-end mb-3">
                        <div>
                          <p className="text wave-effect text-white p-2 rounded mb-1">
                            {msg.message}
                          </p>
                          <small className="text-muted text-end d-block">
                            {formatTime(msg.timestrap)}
                          </small>
                        </div>
                        <div
                          className="photo ml-2"
                          style={{
                            backgroundImage:
                              "url(https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80)",
                          }}
                        ></div>
                      </div>
                    ) : null}
                  </div>
                ))}
              </div>
            ))}
          </div>

          <div className="footer-chat position-relative">
            <i
              className="far fa-smile fs-5 me-2"
              style={{ cursor: "pointer" }}
              onClick={() => setShowEmojiPicker(!showEmojiPicker)}
            ></i>
            {showEmojiPicker && (
              <div
                className="emoji-picker-container position-absolute"
                style={{
                  bottom: "50px",
                  left: "0",
                  zIndex: "1000",
                }}
              >
                <EmojiPicker onEmojiClick={handleEmojiClick} />
              </div>
            )}
            <input
              type="text"
              className="write-message w-50"
              placeholder="Type your message here"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
            <button
              className="btn btn-primary btn-sm ms-1"
              onClick={handlesubmit}
            >
              <i className="far fa-paper-plane"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatApp;
