import AboutDetails from "../forms/AboutDetails";
import { useNavigate } from "react-router-dom";
import BranchDetails from "../forms/AddNewBranch";
import React, { useState, useEffect } from "react";
import { BranchDelete } from "../apis/api/ProfileApi";
import { useDispatch, useSelector } from "react-redux";
import showConfirmation from "../hooks/showConfirmation";
import { Profileget } from "../apis/api/ProfileApi";
import Cookies from "js-cookie";
import Loading from "../hooks/Loading";
import { toast } from "react-toastify";

function About() {
  const dispatch = useDispatch();

  const [aboutshow, setaboutshow] = React.useState(false);
  const [branch, setbranch] = React.useState(false);
  const [showModal, setShowModal] = useState(false);
  const member_type = Cookies.get("member_type");
  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);
  const navigate = useNavigate();
  const [Location, setLocation] = useState([]);
  const [editbranch, seteditbranch] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState(0);
  const access = JSON.parse(Cookies.get("access"));
 
  // const [EditBranch, setEditBranch] = useState(0);
  let memberData = useSelector((state) => state.profileSlice.profile);
  const userId = Cookies.get("userId");
  const loading = useSelector((state) => state.profileSlice.loading);
  const branch_access = JSON.parse(Cookies.get("userJSON"));

  useEffect(() => {
    if (memberData) {
      setLocation(memberData?.data.company_branch);
    }
  }, [memberData]);

  const handleBranchSelect = (index) => {
    setSelectedBranch(index);
  };

  const handleBranchDelete = async (id, member_id, company_id) => {
    try {
      const result = await showConfirmation(
        "Are you sure you want to delete this branch?"
      );
      if (result) {
        const deleteBranch = await dispatch(
          BranchDelete({ id, member_id, company_id })
        );
        if (deleteBranch.payload?.status === "success") {
          const company_id = Cookies.get("company_id");
          const response = await dispatch(
            Profileget({ company_id: company_id, member_id: userId })
          );
          if (response?.payload.status === "success") {
            const profile = await dispatch(
              Profileget({ company_id: company_id, member_id: userId })
            );
            if (profile.payload.status === "success") {
              setSelectedBranch(0);
              toast.success("Branch Deleted Successfully!");
            }
          }
        }
      }
    } catch (error) {
      console.error("Error :", error);
    }
  };

  const handleEditClick = (data) => {
    seteditbranch(data);
    setaboutshow(true);
  };

  const getGoogleMapsEmbedLink = (address) => {
    const encodedAddress = encodeURIComponent(address);
    return `https://www.google.com/maps?q=${encodedAddress}&output=embed`;
  };
  const handleBack = () => {
    navigate(-1);
  };
  return (
    <>
      <Loading loading={loading} />
      <AboutDetails
        show={aboutshow}
        onHide={() => setaboutshow(false)}
        branch={editbranch}
      />
      <BranchDetails showModal={showModal} closeModal={closeModal} />
      <div className="container-fluid mt-5">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-5 col-lg-4 col-xxl-2 col-xl-3 col-12 bg-theme-color rounded">
              {access.access == 1 && branch_access?.branch_office_access == 1 && (
                <button
                  className="btn btn-light bg-theme-color mt-3 mb-4 w-100"
                  onClick={openModal}
                >
                  <i className="fas fa-plus"></i> Add New Branch
                </button>
              )}

              <h5 className="mt-3 mb-4">
                Branche Detail of{" "}
                {Location[0]?.country?.split(":").filter(Boolean)[1]}{" "}
              </h5>
              <ul className="list-unstyled mt-3">
                {Location.map((branch, index) => (
                  <li key={branch.id}>
                    <button
                      onClick={() => handleBranchSelect(index)}
                      className="btn btn-light bg-theme-color mb-3 w-100 d-flex justify-content-between align-items-center text-wrap"
                      style={{ wordWrap: "break-word", whiteSpace: "normal" }}
                    >
                      {/* {branch.country?.split(":").filter(Boolean)[1]} */}
                      {branch.city}
                      {access.access == 1 && (
                        <i
                          className="fas fa-trash-alt text-danger ms-1"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleBranchDelete(
                              branch.id,
                              branch.member_id,
                              branch.company_id
                            );
                          }}
                        />
                      )}
                    </button>
                  </li>
                ))}
              </ul>
            </div>

            <div className="col-sm-8 col-md-7 col-lg-8  col-xxl-10 col-xl-9 col-12 rounded bg-white">
              <div className="d-flex justify-content-end">
                <button
                  className="btn btn-secondary bg-theme-color  mt-3"
                  onClick={handleBack}
                >
                  <i className="fas fa-arrow-left me-2"></i>Back
                </button>
              </div>

              <div className="d-flex justify-content-end align-items-center">
                {/* <div className="mx-auto text-center">
                <h5 className="mb-0">You have registered {registeredOffice.office.name}.</h5>
              </div> */}
                {/* {Location[selectedBranch].member_id==} */}

                {access.access == 1  &&
                  // Location[selectedBranch]?.member_id == userId && (
                    <button
                      className="btn btn-primary bg-theme btn-sm ms-2 mb-3 mt-3"
                      onClick={() => handleEditClick(Location[selectedBranch])}
                    >
                      <i className="fas fa-pen"></i> Edit
                    </button>
                  // )
                  
                  }
              </div>

              <div className="">
                <h5 className="text-center">
                  Details for{" "}
                  {
                    Location[selectedBranch]?.country
                      ?.split(":")
                      .filter(Boolean)[1]
                  }
                </h5>
                <p className="ms-5 mt-3">
                  <strong>Address:</strong>{" "}
                  {Location[selectedBranch]?.address || " "}
                </p>
                <p className="ms-5">
                  <strong>House Number:</strong>{" "}
                  {Location[selectedBranch]?.house_number || " "}
                </p>
                <p className="ms-5">
                  <strong>Zip code:</strong>{" "}
                  {Location[selectedBranch]?.pin_code || " "}
                </p>
                <p className="ms-5 mt-3">
                  <strong>City:</strong> {Location[selectedBranch]?.city || " "}
                </p>
                <p className="ms-5">
                  <strong>Phone Number:</strong>
                  {Location[selectedBranch]?.country_code
                    ?.replace(/^\d+:/, '') 
                    .replace(/([\uD83C][\uDDE6-\uDDFF][\uD83C][\uDDE6-\uDDFF])/, '$1+') +
                    "-" +
                    Location[selectedBranch]?.contact || " "}
                </p>
                <p className="ms-5 mt-3">
                  <strong>Email:</strong>{" "}
                  {Location[selectedBranch]?.email || " "}
                </p>

                {/* <p className='ms-5'><strong>Company Name:</strong> {selectedBranch.details.companyName}</p> */}

                <div className="ms-5 mt-4">
                  <iframe
                    src={getGoogleMapsEmbedLink(
                      Location[selectedBranch]?.country
                        ?.split(" ")
                        .slice(1)
                        .join(" ") || ""
                    )}
                    width="100%"
                    height="400vh"
                    allowFullScreen=""
                    loading="lazy"
                    title="Location Map"
                  ></iframe>
                </div>
              </div>
              {/* ) : (
              <p>Select a branch to view details.</p>
            )} */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default About;
