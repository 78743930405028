import GenericModal from "../hooks/GenericModel";
import { Link } from "react-router-dom";
import React, { useState, useRef, useEffect } from "react";
import CommentArea from "../../src/feeds/Comment";
import { FeedsInsert, CompanyFeedsGet, FeedsLikes } from "../apis/api/FeedsApi";
import { useSelector, useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { FILE_URL } from "../apis/api/Config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Lodder from "../hooks/Lodder";
import Tags from "../feeds/tags";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { Profilelist } from "../apis/api/ProfileApi";
import { Memberpost, PostDelete } from "../apis/api/FeedsApi";
import EditFeeds from "./Editfeeds";
import Loading from "../hooks/Loading";
import showConfirmation from "../hooks/showConfirmation";

const ProfileTimelines = (userid) => {
  const dispatch = useDispatch();
  const [showFilter, setShowFilter] = useState(false);
  const [company_id, setCompany] = useState("");
  const [filter, setfilter] = useState("");
  const [showTags, setShowTags] = useState(false);
  const [postImage, setPostImage] = useState("");
  const [postVideo, setPostVideo] = useState("");
  const [postAudio, setPostAudio] = useState("");
  const [updateFeed, setUpdateFeed] = useState(false);
  const [selectedPost, setSelectedPost] = useState(null);
  const [reload, setreload] = useState(0);
  const [showAllTags, setShowAllTags] = useState(false);
  const company_idd = Cookies.get("company_id");
  const Member_type = Cookies.get("member_type");
  const [showMoreTags, setShowMoreTags] = useState({});
 

  const handleMoreLessClick = (postId) => {
    setShowMoreTags((prevState) => ({
      ...prevState,
      [postId]: !prevState[postId],
    }));
  };
  const userId = Cookies.get("userId");
  const [date, setdate] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);

  const company_filter = (selectedOption) => {
    setCompany(selectedOption.value);
  };

  const handleFilterToggle = () => {
    setShowFilter(!showFilter);
  };

  const { loading } = useSelector((state) => state.feedsSlice);

  const [feedsData, setFeedsData] = useState([]);

  const [page, setPage] = useState(0);
  const [loadingMore, setLoadingMore] = useState(false);
  const [lastId, setLastId] = useState(null);
  const [fullfill, setfullfill] = useState(0);
  const [list, setlist] = useState(null);

  const [previews, setPreviews] = useState({
    musicPreview: null,
    imagePreview: null,
    videoPreview: null,
  });
  const memberId = userid.userid == null ? Cookies.get("userId") : userid;
  const CompanyId = userid.userid == null ? Cookies.get("company_id") : userid;

  useEffect(() => {
    fetchFeeds(false);
    fetchprofilelist();

    fetchfeeds();
  }, [CompanyId, reload, updateFeed]);
  const fetchfeeds = async () => {
    const response = await dispatch(
      CompanyFeedsGet({
        member: memberId,
        company: CompanyId,
        lastid: 0,
        pagination: 0,
      })
    );
    setUpdateFeed(false);
    setFeedsData(response.payload.data);
  };
  const fetchprofilelist = async () => {
    const pofilelist = await dispatch(Profilelist({ filter: 1 }));
    if (pofilelist.payload.status == "success") {
      let data = pofilelist.payload.data;
      setlist(data);
    }
  };

  const fetchFeeds = async (filter, filterpage) => {
    setLoadingMore(true);
    let response;

    if (fullfill == 0) {
      if (page === 0) {
        if (!filter) {
          response = await dispatch(
            Memberpost({
              member: memberId,
              company: CompanyId,
              lastid: 0,
              pagination: 0,
            })
          );
        } else {
          var filterdate = [
            {
              Start_Date: date[0].startDate.toDateString(),
              End_Date: date[0].endDate ? date[0].endDate.toDateString() : 0,
            },
          ];

          response = await dispatch(
            Memberpost({
              member: memberId,
              company: CompanyId,
              lastid: 0,
              pagination: 0,
              filter: filter,
              company_id: company_id,
              filterdate: filterdate,
            })
          );
        }
      } else {
        if (!filter) {
          response = await dispatch(
            Memberpost({
              member: memberId,
              company: CompanyId,
              lastid: lastId,
              pagination: page,
            })
          );
        } else if (filterpage) {
          var filterdate = [
            {
              Start_Date: date[0].startDate.toDateString(),
              End_Date: date[0].endDate ? date[0].endDate.toDateString() : 0,
            },
          ];

          response = await dispatch(
            Memberpost({
              member: memberId,
              company: CompanyId,
              lastid: lastId,
              pagination: 0,
              filter: filter,
              company_id: company_id,
              filterdate: filterdate,
            })
          );
        } else {
          var filterdate = [
            {
              Start_Date: date[0].startDate.toDateString(),
              End_Date: date[0].endDate ? date[0].endDate.toDateString() : 0,
            },
          ];

          response = await dispatch(
            Memberpost({
              member: memberId,
              company: CompanyId,
              lastid: lastId,
              pagination: page,
              filter: filter,
              company_id: company_id,
              filterdate: filterdate,
            })
          );
        }
      }
      if (response.payload.data == 0) {
        setfullfill(1);
      }
      if (response.payload.data) {
        if (response.payload.data.length > 0) {
          if (page === 0) {
            setFeedsData(response.payload.data);
          } else if (filterpage) {
            setFeedsData(response.payload.data);
          } else {
            setFeedsData((prevFeeds) => [
              ...prevFeeds,
              ...response.payload.data,
            ]);
          }

          if (
            lastId !==
            response.payload.data[response.payload.data.length - 1]
              .company_post_id
          ) {
            setLastId(
              response.payload.data[response.payload.data.length - 1]
                .company_post_id
            );
            if (filterpage) {
              setPage((prevPage) => 0 + 1);
            } else {
              setPage((prevPage) => prevPage + 1);
            }
          }
        }
      }
    }
    setLoadingMore(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop + 1 >=
          document.documentElement.scrollHeight &&
        !loadingMore
      ) {
        fetchFeeds(filter);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [loadingMore]);

  const [showModal, setShowModal] = useState(false);
  const [post_id, setpost_id] = useState(false);
  // const comments = [<CommentArea />];

  const handleShow = (id, imageUrl, Videourl, postAudio) => {
    setPostImage(imageUrl);
    setPostVideo(Videourl);
    setPostAudio(postAudio);
    setShowModal(true);
    setpost_id(id);
  };
  const handleClose = () => setShowModal(false);

  const [formData, setFormData] = useState({
    textarea: "",
    musicFile: null,
    imageFile: null,
    videoFile: null,
    tags: [],
  });

  const musicInputRef = useRef(null);
  const imageInputRef = useRef(null);
  const videoInputRef = useRef(null);
  const likeCountsRef = useRef({});

  const handleIconClick = (inputRef, fileType) => {
    inputRef.current.click();
    setShowTags(true);
  };

  const handleFileChange = (event, fileType) => {
    const file = event.target.files[0];
    if (!file) {
      setShowTags(false);
      return;
    }
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreviews((prevState) => ({
        ...prevState,
        [`${fileType.toLowerCase()}Preview`]: reader.result,
      }));
    };
    reader.readAsDataURL(file);

    const validTypes = {
      Music: ["audio/mpeg", "audio/wav", "audio/mp3"],
      Image: ["image/jpeg", "image/png", "image/gif"],
      Video: ["video/mp4", "video/avi", "video/mov"],
    };

    if (validTypes[fileType].includes(file.type)) {
      const fileURL = URL.createObjectURL(file);
      setFormData((prevData) => ({
        ...prevData,
        [`${fileType.toLowerCase()}File`]: file,
      }));

      setPreviews((prevPreviews) => ({
        ...prevPreviews,
        [`${fileType.toLowerCase()}Preview`]: fileURL,
      }));
    } else {
      toast.error(`Please select a valid ${fileType.toLowerCase()} file.`);
    }
  };

  const handleTextareaChange = (event) => {
    setFormData((prevData) => ({
      ...prevData,
      textarea: event.target.value,
    }));
    setShowTags(event.target.value !== "");
  };

  const handleRemoveFile = (fileType, inputRef) => {
    setFormData((prevData) => ({
      ...prevData,
      [`${fileType.toLowerCase()}File`]: null,
    }));

    setPreviews((prevPreviews) => ({
      ...prevPreviews,
      [`${fileType.toLowerCase()}Preview`]: null,
    }));

    if (inputRef.current) {
      inputRef.current.value = "";
    }

    setShowTags(false);
  };
  // const [status, setStatus] = useState("idle");

  const handleSubmit = async (event) => {
    event.preventDefault();
    let response;
    const company_ID = Cookies.get("company_id");
    const sorce_type = Cookies.get("sorce_type");

    const member_detail = JSON.parse(Cookies.get("userJSON"));

    const formDataToSend = new FormData();
    formDataToSend.append("textarea", formData.textarea);
    formDataToSend.append("company_id", company_ID);
    formDataToSend.append("sorce_type", sorce_type);
    formDataToSend.append("member_id", memberId);
    formDataToSend.append("perweak_post", member_detail.post_per_weak);
    formDataToSend.append("upload_limit", member_detail.feeds_upload);

    if (formData.musicFile)
      formDataToSend.append("musicFile", formData.musicFile);
    if (formData.imageFile)
      formDataToSend.append("imageFile", formData.imageFile);
    if (formData.videoFile)
      formDataToSend.append("videoFile", formData.videoFile);

    if (formData.tags && formData.tags.length > 0) {
      const tagValues = formData.tags.map((tag) => tag.value);

      formDataToSend.append("tags", JSON.stringify(tagValues));
    }

    if (
      formData.musicFile ||
      formData.imageFile ||
      formData.videoFile ||
      formData.textarea !== ""
    ) {
      response = await dispatch(FeedsInsert(formDataToSend));

      if (response.payload.status == "success") {
        handleRemoveFile("Music", musicInputRef);
        handleRemoveFile("Image", musicInputRef);
        handleRemoveFile("Video", musicInputRef);
        setFormData({
          textarea: "",
          tags: [],
        });
        toast.success("post successful!");
        response = await dispatch(
          CompanyFeedsGet({
            member: memberId,
            company: CompanyId,
            lastid: 0,
            pagination: 0,
          })
        );

        if (response.payload.data) {
          setFeedsData(response.payload.data);
        }
      }
      if (response.payload.status == "error") {
        setFormData({
          textarea: "",
          tags: [],
        });
        toast.error(response.payload.msz);
      }
    } else {
      toast.error(`Please select a valid  file.`);
    }
  };

  const [like_set, setlike_set] = useState(null);
  const [post_set, setpost_set] = useState(null);

  const lastlikecount = useRef(null);
  const lastlikestatus = useRef(null);
  const currentlikecount = useRef(null);
  const currentlikestatus = useRef(null);

  const handleLikeClick = async (feedId, likesCount, LikedStatus) => {
    const newLikesCount = LikedStatus
      ? parseInt(likesCount) - 1
      : parseInt(likesCount) + 1;

    if (like_set !== likesCount || post_set !== feedId) {
      dispatch(
        FeedsLikes({
          feedId: feedId,
          like: newLikesCount,
          memberId: memberId,
          LikedStatus: LikedStatus,
        })
      );
      const curlikestatus = LikedStatus ? false : true;

      lastlikecount.current = newLikesCount;
      lastlikestatus.current = curlikestatus;
      var insElement = document.getElementById(feedId);

      if (insElement) {
        insElement.innerHTML = newLikesCount;
        const liElement = insElement.closest("li");

        if (liElement) {
          const buttonElement = liElement.querySelector("button");
          if (LikedStatus) {
            buttonElement.classList.remove("liked");
          } else {
            buttonElement.classList.add("liked");
          }
        }
      }
    } else {
      currentlikestatus.current = lastlikestatus.current ? false : true;
      currentlikecount.current = lastlikestatus.current
        ? parseInt(lastlikecount.current) - 1
        : parseInt(lastlikecount.current) + 1;
      dispatch(
        FeedsLikes({
          feedId: feedId,
          like: currentlikecount.current,
          memberId: memberId,
          LikedStatus: currentlikestatus.current,
        })
      );

      var insElement = document.getElementById(feedId);
      if (insElement) {
        insElement.innerHTML = currentlikecount.current;
        const liElement = insElement.closest("li");

        if (liElement) {
          const buttonElement = liElement.querySelector("button");
          if (lastlikestatus.current) {
            buttonElement.classList.remove("liked");
          } else {
            buttonElement.classList.add("liked");
          }
        }
      }
      lastlikecount.current = currentlikecount.current;
      lastlikestatus.current = currentlikestatus.current;
    }

    setlike_set(likesCount);
    setpost_set(feedId);
  };
  const [modalShow, setModalShow] = React.useState(false);
  const handleEditClick = (post) => {
    setSelectedPost(post);
  };
  const handleMemberDelete = async (postId) => {
    try {
      const result = await dispatch(PostDelete(postId));

      if (result.payload.status === "success") {
        setFeedsData((prevFeeds) =>
          prevFeeds.filter((post) => post.company_post_id !== postId)
        );

        await fetchFeeds(false);
        await fetchprofilelist();

        toast.success("Post deleted successfully!");
      }
    } catch (error) {
      console.error("Error deleting post:", error);
      toast.error("Failed to delete the post.");
    }
  };

  return (
    <>
      <EditFeeds
        showModal={modalShow}
        closeModal={() => setModalShow(false)}
        selectedPost={selectedPost}
        updateFeed={setUpdateFeed}
      />
      <Loading loading={loading} />
      <div className="w-100">
        <div className="w-100">
          {userid.userid == null && (
            <div class="central-meta mt-5">
              <div className="new-postbox ">
                <figure>
                  <img
                    className="Image"
                    // src={`${FILE_URL}${member.company.company_company_logo}`}
                    alt=""
                  />
                </figure>
                <div className="newpst-input">
                  <form onSubmit={handleSubmit}>
                    <div
                      className="textarea-container"
                      style={{ position: "relative" }}
                    >
                      <textarea
                        id="myTextarea1"
                        rows="2"
                        placeholder="Write something"
                        value={formData.textarea}
                        onChange={handleTextareaChange}
                        maxLength="1000"
                        style={{ paddingBottom: "20px" }} // Add space for the remaining characters
                      ></textarea>
                      <div
                        className="remaining-chars"
                        style={{
                          position: "absolute",
                          bottom: "5px",
                          right: "10px",
                          fontSize: "12px", // Small font size for the remaining characters
                          color: "#888", // Lighter color for the text
                        }}
                      >
                        {1000 - formData.textarea.length} characters remaining
                      </div>
                    </div>

                    <div className="attachments">
                      <ul>
                        {!formData.musicFile &&
                          !formData.imageFile &&
                          !formData.videoFile && (
                            <>
                              <div className="d-inline">
                                <li>
                                  <i
                                    className="fa fa-music me-1 ms-1"
                                    onClick={() =>
                                      handleIconClick(musicInputRef, "Music")
                                    }
                                  ></i>
                                  <label className="fileContainer">
                                    <input
                                      type="file"
                                      ref={musicInputRef}
                                      onChange={(event) =>
                                        handleFileChange(event, "Music")
                                      }
                                      style={{ display: "none" }}
                                    />
                                    <span>Music</span>
                                  </label>
                                </li>

                                <li>
                                  <i
                                    className="fa fa-image me-1 ms-1"
                                    onClick={() =>
                                      handleIconClick(imageInputRef, "Image")
                                    }
                                  ></i>
                                  <label className="fileContainer">
                                    <input
                                      type="file"
                                      ref={imageInputRef}
                                      onChange={(event) =>
                                        handleFileChange(event, "Image")
                                      }
                                      style={{ display: "none" }}
                                    />
                                    <span>Photo</span>
                                  </label>
                                </li>

                                <li>
                                  <i
                                    className="fa fa-video-camera me-1 ms-1"
                                    onClick={() =>
                                      handleIconClick(videoInputRef, "Video")
                                    }
                                  ></i>
                                  <label className="fileContainer">
                                    <input
                                      type="file"
                                      ref={videoInputRef}
                                      onChange={(event) =>
                                        handleFileChange(event, "Video")
                                      }
                                      style={{ display: "none" }}
                                    />
                                    <span>Video</span>
                                  </label>
                                </li>
                              </div>
                            </>
                          )}
                        {formData.musicFile && (
                          <li>
                            <div style={{ position: "relative" }}>
                              <audio controls>
                                <source src={previews.musicPreview} />
                                Your browser does not support the audio element.
                              </audio>
                              <button
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  right: 0,
                                  background: "red",
                                  color: "white",
                                  border: "none",
                                  borderRadius: "50%",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  handleRemoveFile("Music", musicInputRef)
                                }
                              >
                                X
                              </button>
                            </div>
                          </li>
                        )}
                        {formData.imageFile && (
                          <li>
                            <div style={{ position: "relative" }}>
                              <img
                                src={previews.imagePreview}
                                alt="Image Preview"
                                style={{ maxWidth: "100%", maxHeight: "100%" }}
                              />
                              <button
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  right: 0,
                                  background: "white",
                                  color: "black",
                                  border: "none",
                                  borderRadius: "50%",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  handleRemoveFile("Image", imageInputRef)
                                }
                              >
                                X
                              </button>
                            </div>
                          </li>
                        )}
                        {formData.videoFile && (
                          <li>
                            <div style={{ position: "relative" }}>
                              <video
                                controls
                                style={{ maxWidth: "100%", maxHeight: "100%" }}
                              >
                                <source src={previews.videoPreview} />
                                Your browser does not support the video element.
                              </video>
                              27%{" "}
                              <button
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  right: 0,
                                  background: "red",
                                  color: "white",
                                  border: "none",
                                  borderRadius: "50%",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  handleRemoveFile("Video", videoInputRef)
                                }
                              >
                                X
                              </button>
                            </div>
                          </li>
                        )}
                        <li>
                          <div className="d-flex align-items-center mt-3">
                            <div className="flex-grow-1">
                              {showTags && (
                                <Tags
                                  onChange={(tags) =>
                                    setFormData({ ...formData, tags })
                                  }
                                />
                              )}
                            </div>
                            <button
                              type="submit"
                              className="btn btn-primary bg-theme ms-3 btn-sm"
                              onClick={handleSubmit}
                            >
                              Post
                            </button>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          )}

          <div className="feed-container">
            {feedsData && feedsData.length > 0 ? (
              feedsData.map((feed) => {
                likeCountsRef.current[feed.company_post_id] =
                  feed.company_post_like;
                const noMedia =
                  !feed.company_post_file_url ||
                  !["image", "video", ".mp3", "audio"].includes(
                    feed.company_post_file_extension
                  );
                return (
                  <div className="central-meta item" key={feed.company_post_id}>
                    <div className="user-post">
                      <div className="friend-info d-flex justify-content-between">
                        <figure>
                          <img
                            className="Image"
                            src={`${feed.member_member_logo}`}
                            onError={(e) => {
                              e.target.src = "/dumy.jpeg";
                            }}
                          />
                        </figure>
                        <div className="friend-name">
                          <ins className="ins d-flex justify-content-between align-items-center">
                            <p className="text-primary">
                              {feed.member_name} || {feed.company_company_name}
                            </p>
                          </ins>
                          <span>
                            {(() => {
                              const rawDate = feed.company_post_created_at;

                              const cleanDateStr = rawDate
                                .replace("Published: ", "")
                                .replace("16:PM", "16:00");

                              const parsedDate = new Date(cleanDateStr);

                              return !isNaN(parsedDate)
                                ? parsedDate.toLocaleString()
                                : " ";
                            })()}
                          </span>
                        </div>

                        {((feed.company_post_company_id == company_idd &&
                          Member_type == 1) ||
                          feed.company_post_member_id == userId) && (
                          <div className="dropdown">
                            <button
                              className="btn btn-light dropdown-toggle"
                              type="button"
                              id={`dropdownMenuButton-${feed.company_post_id}`}
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <i className="fa fa-ellipsis-h"></i>
                            </button>
                            <ul
                              className="dropdown-menu"
                              aria-labelledby={`dropdownMenuButton-${feed.company_post_id}`}
                            >
                              <li>
                                <button
                                  className="dropdown-item bg-"
                                  onClick={() => {
                                    setModalShow(true);
                                    handleEditClick(feed);
                                  }}
                                >
                                  <i className="fa fa-edit me-2"></i>
                                  Edit
                                </button>
                              </li>
                              <li>
                                <button
                                  className="dropdown-item"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleMemberDelete(feed.company_post_id);
                                  }}
                                >
                                  <i className="fa fa-trash me-2"></i>
                                  Delete
                                </button>
                              </li>
                            </ul>
                          </div>
                        )}
                      </div>

                      <div className="post-meta">
                        {noMedia ? (
                          <p>{feed.company_post_description}</p>
                        ) : feed.company_post_file_extension === "video" ? (
                          <video
                            className="videoContainer w-100"
                            controls
                            src={feed.company_post_file_url.replace(
                              "/thumb/",
                              "/"
                            )}
                            alt="Post video"
                          />
                        ) : feed.company_post_file_extension === "audio" ? (
                          <audio
                            className="audioContainer w-100"
                            controls
                            src={feed.company_post_file_url.replace(
                              "/thumb/",
                              "/"
                            )}
                          >
                            Your browser does not support the audio element.
                          </audio>
                        ) : feed.company_post_file_extension === ".mp3" ? (
                          <div>
                            <audio
                              className="audioContainer w-100"
                              controls
                              src={feed.company_post_file_url.replace(
                                "/thumb/",
                                "/"
                              )}
                            >
                              Your browser does not support the audio element.
                            </audio>
                          </div>
                        ) : (
                          <img
                            className="imgContainer w-100"
                            src={feed.company_post_file_url.replace(
                              "/thumb/",
                              "/"
                            )}
                            alt="Post image"
                          />
                        )}

                        {/* Post description */}
                        {!noMedia && (
                          <div className="description">
                            <p>{feed.company_post_description}</p>
                          </div>
                        )}

                        <div className="member-names">
                          {feed?.company_post_tags ? (
                            <>
                              <i className="fa-solid fa-tag me-2"></i>

                              {feed.company_post_tags
                                .split(",")
                                .slice(
                                  0,
                                  showMoreTags[feed.company_post_id]
                                    ? feed.company_post_tags.length
                                    : 3
                                )
                                .map((name, index) => (
                                  <span key={index} className="member-name">
                                    {name.trim()}
                                    {index <
                                      feed.company_post_tags.split(",").length -
                                        1 && ", "}
                                  </span>
                                ))}
                            </>
                          ) : null}
                        </div>

                        {feed?.company_post_tags &&
                          feed.company_post_tags.split(" ||").length > 3 && (
                            <button
                              className="btn btn-link"
                              onClick={() =>
                                handleMoreLessClick(feed.company_post_id)
                              }
                            >
                              {showMoreTags[feed.company_post_id]
                                ? "See Less"
                                : "See More"}
                            </button>
                          )}

                        {/* Like/Comment button */}
                        <div className="we-video-info">
                          <ul>
                            <li>
                              <button
                                type="button"
                                className={`btn btn-Light rounded-circle fa-regular fa-thumbs-up ${
                                  feed.liked ? "liked" : ""
                                }`}
                                onClick={() =>
                                  handleLikeClick(
                                    feed.company_post_id,
                                    feed.company_post_like,
                                    feed.liked
                                  )
                                }
                                aria-label={`Like this post by ${feed.member_name}`}
                              ></button>
                              <ins id={feed.company_post_id}>
                                {likeCountsRef.current[feed.company_post_id]}
                              </ins>
                            </li>
                            <li>
                              <button
                                type="button"
                                className="btn btn-Light rounded-circle fa-regular fa-comment"
                                aria-label={`Comment on post by ${feed.member_name}`}
                                onClick={() => {
                                  const id = feed.company_post_id;
                                  const imageUrl =
                                    feed.company_post_file_extension === "image"
                                      ? feed.company_post_file_url.replace(
                                          "/thumb/",
                                          "/"
                                        )
                                      : null;

                                  const videoUrl =
                                    feed.company_post_file_extension === "video"
                                      ? feed.company_post_file_url.replace(
                                          "/thumb/",
                                          "/"
                                        )
                                      : null;

                                  const audioUrl =
                                    feed.company_post_file_extension === "audio"
                                      ? feed.company_post_file_url.replace(
                                          "/thumb/",
                                          "/"
                                        )
                                      : null;

                                  handleShow(id, imageUrl, videoUrl, audioUrl);
                                }}
                              ></button>
                              <ins></ins>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <h5 className="text-center mt-5 ">Feeds not available !!!</h5>
            )}
            {loadingMore && <Lodder />}
          </div>
        </div>
        <GenericModal
          show={showModal}
          handleClose={handleClose}
          title="Comments"
          postImage={postImage}
          PostVideo={postVideo}
          postAudio={postAudio}
        >
          <CommentArea id={post_id} />
        </GenericModal>
      </div>
    </>
  );
};

export default ProfileTimelines;
