import React from "react";

const Lodder=()=>{
    return(
        <>
  <div className="container loading1"> 
              <div className="loading">
              <div className="loading_letter">L</div>
              <div className="loading_letter">O</div>
              <div className="loading_letter">A</div>
              <div className="loading_letter">D</div>
              <div className="loading_letter">I</div>
              <div className="loading_letter">N</div>
              <div className="loading_letter">G</div>
              <div className="loading_letter">.</div>
              <div className="loading_letter">.</div>
              <div className="loading_letter">.</div>
                </div>
              </div>
        </>
    )
}
export default Lodder;