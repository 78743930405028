import { useState } from 'react';

const useFormValidation = (initialState, validate) => {
  const [formData, setFormData] = useState(initialState);
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    
    if (!name) return;

    const keys = name.includes('[')
      ? name?.split('[').map(k => k.replace(']', ''))
      : [name];

    const updatedFormData = { ...formData };
    let tempData = updatedFormData;

    keys.forEach((key, index) => {
      if (index === keys.length - 1) {
        if (files) {
          tempData[key] = files[0]; // Handle file input
        } else {
          tempData[key] = value;
        }
      } else {
        tempData[key] = tempData[key] || {};
        tempData = tempData[key];
      }
    });

    setFormData(updatedFormData);
  };

  const handleSubmit = (e, callback) => {
    e.preventDefault();
    const validationErrors = validate(formData);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setErrors({});
      callback(formData);
    }
  };

  return {
    formData,
    errors,
    handleChange,
    handleSubmit,
  };
};

export default useFormValidation;
