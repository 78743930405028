import React from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const GenericModal = ({ show, handleClose, title, children, postImage, PostVideo,postAudio }) => {


  return (
    
    <Modal show={show} onHide={handleClose} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="row">
   
        {postImage && (
          <div className="col-lg-6 col-12 d-flex align-items-center justify-content-center mb-3">
            <img
              src={postImage}
              alt="Post"
              className="img-fluid"
              style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
            />
          </div>
        )}

        {PostVideo && (
          <div className="col-lg-6 col-md-6 col-12 d-flex align-items-center justify-content-center mb-3">
            <video
              controls
              className="img-fluid"
              style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
            >
              <source src={PostVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        )}

        {postAudio && (
          <div className="col-lg-6 col-md-6 col-12 d-flex align-items-center justify-content-center mb-3">
            <audio
              controls
              className="w-100"
            >
              <source src={postAudio} type="audio/mpeg" />
              Your browser does not support the audio element.
            </audio>
          </div>
        )}

        <div className={postImage ? "col-lg-6 col-md-6 col-12" : "col-12 col-lg-6 col-md-6"}>
          {children}
        </div>
      </Modal.Body>
      <Modal.Footer>


      </Modal.Footer>
    </Modal>
  );
};

export default GenericModal;
