import { createSlice } from "@reduxjs/toolkit";
import { activeMember ,latestMember ,Magazine,NewsAll,ImportExportAll,updateImportExport,profileAll} from "../api/DashboardApi";


const dashboardSlice = createSlice({

    name: "dashboardSlice",
    initialState: {
        loading: false,
        activemember: '',
        latestmember:'',
        magazine:'',
        news:'',
        profile:'',
        ImportExportAll:'',
        updateImportExport:'',
    },
    reducers:{
      
    },

    extraReducers: (builder) => {

      builder.addCase(activeMember.pending, state => {
        state.loading = true
      })
      builder.addCase(activeMember.fulfilled,  (state, action) => {
       state.activemember=action.payload;
        state.loading = false
      })
      builder.addCase(activeMember.rejected, (state,action) => {
      
        state.loading = false
      })


      // latest member----
      builder.addCase(latestMember.pending, state => {
        state.loading = true
      })
      builder.addCase(latestMember.fulfilled,  (state, action) => {
       state.latestmember=action.payload;
        state.loading = false
      })
      builder.addCase(latestMember.rejected, (state,action) => {
      
        state.loading = false
      })

      // ----ImportExportAll
      builder.addCase(ImportExportAll.pending, state => {
        state.loading = true
      })
      builder.addCase(ImportExportAll.fulfilled,  (state, action) => {
       state.ImportExportAll=action.payload;
        state.loading = false
      })
      builder.addCase(ImportExportAll.rejected, (state,action) => {
      
        state.loading = false
      })

       // ----updateImportExport
       builder.addCase(updateImportExport.pending, state => {
        state.loading = true
      })
      builder.addCase(updateImportExport.fulfilled,  (state, action) => {
       state.updateImportExport=action.payload;
        state.loading = false
      })
      builder.addCase(updateImportExport.rejected, (state,action) => {
      
        state.loading = false
      })
      

      // latest Magazine----
      builder.addCase(Magazine.pending, state => {
        state.loading = true
      })
      builder.addCase(Magazine.fulfilled,  (state, action) => {
       state.magazine=action.payload;
        state.loading = false
      })
      builder.addCase(Magazine.rejected, (state,action) => {
      
        state.loading = false
      })


      // latest News----
      builder.addCase(NewsAll.pending, state => {
        state.loading = true
      })
      builder.addCase(NewsAll.fulfilled,  (state, action) => {
       state.news=action.payload;
        state.loading = false
      })
      builder.addCase(NewsAll.rejected, (state,action) => {
     
        state.loading = false
      })

      // all profile data for search
  

      builder.addCase(profileAll.pending, state => {
        state.loading = true
      })
      builder.addCase(profileAll.fulfilled,  (state, action) => {
       state.profile=action.payload;
        state.loading = false
      })
      builder.addCase(profileAll.rejected, (state,action) => {
      
        state.loading = false
      })


    }
  })
  export default dashboardSlice.reducer;