import React from "react";
import { useLocation,useNavigate } from "react-router-dom";
import { FILE_URL } from "../apis/api/Config";

const News = ({ Route }) => {
  const location = useLocation();
  const navigate=useNavigate();
  const { newsData } = location.state || {};
  const handleBack = () => {
    navigate(-1);
  };
  return (

    <>
  <div className="container-fluid">

  <div className="d-flex justify-content-end">
  <button className="btn btn-secondary bg-theme-color btn-sm" onClick={handleBack}>
    <i className="fas fa-arrow-left me-2"></i>Back
  </button>
</div>

      <div className="central-meta item">
        <div className="user-post">
          <div className="friend-info">
            <div className="friend-name">
              <ins className="ins d-flex justify-content-between align-items-center">
                <h4 className="fw-bold text-color headding text-center">
                  {newsData.title}
                </h4>
              </ins>
              <p className="text-muted">
                {" "}
                Published Date:
                {new Date(newsData.date * 1000).toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })}
              </p>
            </div>
            <div className="nwesimg">
              <img
                className="d-block mx-auto"
                src={`${newsData.image}`.replace("/thumb/", "/")}
                alt=""
              />

              <div className="description  ">
                <p className="font-size-17 mt-4">{newsData.description}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </>
  );
};

export default News;
