import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { NODE_URL,BASE_URL} from "./Config";
// import Cookies from 'js-cookie';



export const galleryInsert = createAsyncThunk(
    "post/galleryInsert",
    async (data) => { 
        let url = NODE_URL+'galleryInsert';
        try{
            const response = (await axios.post(url,data)).data
            if(response.status == "success"){
                return Promise.resolve(response)
            } else if(response.status == "error"){
                return Promise.resolve(response.msz)
            }else{
                return Promise.reject(response)
            }
        }catch (error) {
            return Promise.reject(error)
        }
    }
)

export const galleryEdit = createAsyncThunk(
    "put/galleryEdit",
    async (data) => { 
        let url = NODE_URL+'galleryEdit';
        try{
            const response = (await axios.put(url,data)).data
            if(response.status == "success"){
                return Promise.resolve(response)
            } else if(response.status == "error"){
                return Promise.resolve(response.msz)
            }else{
                return Promise.reject(response)
            }
        }catch (error) {
            return Promise.reject(error)
        }
    }
)

export const galleryDelete = createAsyncThunk(
    "delete/galleryDelete",
    async (data) => { 
        let url = NODE_URL+'galleryDelete';
        try{
            const response = (await axios.delete(url+'/'+data)).data
            if(response.status == "success"){
                return Promise.resolve(response)
            } else if(response.status == "error"){
                return Promise.resolve(response.msz)
            }else{
                return Promise.reject(response)
            }
        }catch (error) {
            return Promise.reject(error)
        }
    }
)

export const PhotoInsert = createAsyncThunk(
    "post/PhotoInsert",
    async (data) => { 
        let url = NODE_URL+'PhotoInsert';
        try{
            const response = (await axios.post(url,data)).data
            if(response.status == "success"){
                return Promise.resolve(response)
            } else if(response.status == "error"){
                return Promise.resolve(response.msz)
            }else{
                return Promise.reject(response)
            }
        }catch (error) {
            return Promise.reject(error)
        }
    }
)

export const PhotoDelete = createAsyncThunk(
    "delete/PhotoDelete",
    async (data) => { 
        let url = NODE_URL+'PhotoDelete';
        try{
            const response = (await axios.delete(url+'/'+data)).data
            if(response.status == "success"){
                return Promise.resolve(response)
            } else if(response.status == "error"){
                return Promise.resolve(response.msz)
            }else{
                return Promise.reject(response)
            }
        }catch (error) {
            return Promise.reject(error)
        }
    }
)

export const VideoInsert = createAsyncThunk(
    "post/VideoInsert",
    async (data) => { 
        let url = NODE_URL+'VideoInsert';
        try{
            const response = (await axios.post(url,data)).data
            if(response.status == "success"){
                return Promise.resolve(response)
            } else if(response.status == "error"){
                return Promise.resolve(response.msz)
            }else{
                return Promise.reject(response)
            }
        }catch (error) {
            return Promise.reject(error)
        }
    }
)



export const VideoDelete = createAsyncThunk(
    "delete/VideoDelete",
    async (data) => { 
        let url = NODE_URL+'VideoDelete';
        try{
            const response = (await axios.delete(url+'/'+data)).data
            if(response.status == "success"){
                return Promise.resolve(response)
            } else if(response.status == "error"){
                return Promise.resolve(response.msz)
            }else{
                return Promise.reject(response)
            }
        }catch (error) {
            return Promise.reject(error)
        }
    }
)

export const galleryGet = createAsyncThunk(
    "post/galleryGet",
    async (data) => { 
        let url = NODE_URL+'galleryGet';
        try{
            const response = (await axios.post(url,data)).data
            if(response.status == "success"){
                return Promise.resolve(response)
            } else {
                return Promise.reject(response)
            }
        }catch (error) {
            return Promise.reject(error)
        }
    }
)

export const MediaGet = createAsyncThunk(
    "post/MediaGet",
    async (data) => { 
        let url = NODE_URL+'MediaGet';
        try{
            const response = (await axios.post(url,data)).data
            if(response.status == "success"){
                return Promise.resolve(response)
            } else {
                return Promise.reject(response)
            }
        }catch (error) {
            return Promise.reject(error)
        }
    }
)





