import React, { useState } from "react";
import { useForm } from "react-hook-form";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../hooks/Loading";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { VideoInsert, MediaGet } from "../../apis/api/GalleryApi"; // Assuming you have this API

const AddVideoAlbum = ({ AddVideo, onClose }) => {
  const dispatch = useDispatch();
  const [isFetched, setIsFetched] = useState(false);
  const [media, setMedia] = useState(null);
  const [errorMessage, setErrorMessage] = useState(""); // State to hold error message
  const { handleSubmit, register, reset, formState: { errors } } = useForm();

  const user_id = Cookies.get("userId");
  const company_id = Cookies.get("company_id");
  const loading = useSelector((state) => state.gallerySlice.loading);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setErrorMessage("");
    if (file) {
      if (file.type.match("video.*")) {
        setMedia(file); 
      } else {
        setErrorMessage("Please select a valid video file (MP4, AVI, etc.)."); 
        setMedia(null); 
      }
    }
  };

  const handleRemoveMedia = () => {
    setMedia(null);
    setErrorMessage(""); 
  };

  const access_data = JSON.parse(Cookies.get("userJSON"));

  const onSubmitHandler = async (data) => {
    const formData = new FormData();
    formData.append("user_id", user_id);
    formData.append("company_id", company_id);
    formData.append("uploade_video", access_data.upload_video);
    formData.append("title", data.title);  // Append the title to FormData

    if (media) {
      formData.append("File", media); 
    }

    const response = await dispatch(VideoInsert(formData));
    if (response?.payload?.status === "success") {
      const response = await dispatch(MediaGet({ company_id: company_id, user_id: user_id }));
      reset();
      onClose();
      toast.success("Video uploaded successfully!");
      setIsFetched(false);
    } else {
      toast.error(response?.payload?.msz);
    }
  };

  const closeModel = () => {
    reset();
    setMedia(null);
    setErrorMessage(""); 
    onClose();
  };

  return (
    <>
      {AddVideo && (
        <>
          <div className="modal-backdrop fade show"></div>
          <div
            className="modal fade show d-block"
            tabIndex="-1"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              minHeight: "100vh",
            }}
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Upload Video</h5>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={closeModel}
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <Loading loading={loading} />
                  <form
                    method="post"
                    onSubmit={handleSubmit(onSubmitHandler)}
                    encType="multipart/form-data"
                  >
                    {/* Video Title Input */}
                    <div className="form-group">
                      <label htmlFor="title">Video Title</label>
                      <input
                        type="text"
                        placeholder="Add video tittle"
                        id="title"
                        className={`form-control ${errors.title ? "is-invalid" : ""}`}
                        {...register("title", { required: "Title is required" })}
                      />
                      {errors.title && (
                        <div className="text-danger mt-2">
                          {errors.title.message}
                        </div>
                      )}
                    </div>

                    {/* Video Upload */}
                    <div className="upload__box mt-3">
                      <div className="upload__btn-box">
                        <label className="upload__btn">
                          <FontAwesomeIcon
                            icon={faUpload}
                            className="upload__icon"
                          />
                          <p>Choose Video</p>
                          <input
                            type="file"
                            className="upload__inputfile"
                            onChange={handleFileChange}
                          />
                        </label>
                      </div>

                      {/* Display error message if file is not a video */}
                      {errorMessage && (
                        <div className="alert alert-danger mt-2">
                          {errorMessage}
                        </div>
                      )}

                      {/* Display video preview */}
                      {media && (
                        <div className="upload__video-wrap d-flex flex-wrap mt-3">
                          <div
                            className="upload__video-box me-2 mb-2"
                            style={{ width: "30%" }}
                          >
                            <div
                              className="position-relative"
                              style={{
                                width: "100%",
                                height: "100px",
                                backgroundColor: "#000",
                              }}
                            >
                              <video
                                width="100%"
                                height="100%"
                                controls
                                className="upload__video"
                              >
                                <source
                                  src={URL.createObjectURL(media)}
                                  type={media.type}
                                />
                                Your browser does not support the video.
                              </video>
                              <button
                                className="upload__img-close btn btn-danger btn-sm position-absolute top-0 end-0"
                                onClick={handleRemoveMedia}
                              >
                                &times;
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="modal-footer">
                      <button type="submit" className="btn btn-primary">
                        Upload Video
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AddVideoAlbum;
